import axios from "axios";
import { paths } from '../../Api/paths';
import { APPROVE_PRODUCTS, APPROVE_PRODUCTS_FAILED, APPROVE_PRODUCTS_SUCCESSFULL, RESET_APPROVE_PRODUCTS } from "../constants/approveProducts.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const handleApproveProducts = (data) => ({
    type: APPROVE_PRODUCTS,
    payload: data
});

const approveProductsSuccess = (data) => ({
    type: APPROVE_PRODUCTS_SUCCESSFULL,
    payload: data
});

const approveProductsFailure = (error) => ({
    type: APPROVE_PRODUCTS_FAILED,
    payload: error
});

export const resetApproveProducts = () => ({
    type: RESET_APPROVE_PRODUCTS
})

export const approveProducts = (token, data, queryString) => async (dispatch) => {
    try {
      dispatch(handleApproveProducts(data));
      const response = await axios.patch(`${process.env.REACT_APP_API_LINK}${paths.qaApprove}${queryString}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(approveProductsSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(approveProductsFailure(error));
          }
    }
};