import axios from "axios";
import { paths } from '../../Api/paths';
import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILED, FORGOT_PASSWORD_SUCCESSFULL, RESET_FORGOT_PASSWORD } from "../constants/forgotPassword.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const forgotPassword = () => ({
    type: FORGOT_PASSWORD
})

const forgotPasswordSuccessfull = (data) => ({
    type: FORGOT_PASSWORD_SUCCESSFULL,
    payload: data
})

const forgotPasswordFailure = (error) => ({
    type: FORGOT_PASSWORD_FAILED,
    payload: error
})

export const resetForgotPassword = () => ({
    type: RESET_FORGOT_PASSWORD
})

export const forgotUserPassword = (data) => async (dispatch) => {
    try{
        dispatch(forgotPassword());
        const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.forgotPassword}${data.username}`,
        {})
        dispatch(forgotPasswordSuccessfull(response.data));
    } catch(error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(forgotPasswordFailure(error));
          }
    }
}
