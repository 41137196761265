import axios from 'axios';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, RESET_AUTH, RESTORE_AUTH, SET_QA_CLIENT, TOGGLE_PRODUCTS } from '../constants/loginUser.constants';
import { paths } from '../../Api/paths';

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const resetAuth = () => ({
  type: RESET_AUTH
});

export const setQaClient = (company) => ({
  type: SET_QA_CLIENT,
  payload: company
})
export const toggleProductsAdded = () => ({
  type: TOGGLE_PRODUCTS,
})

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.login}`, credentials);
    dispatch(loginSuccess(response.data));
    sessionStorage.setItem('userDetails', JSON.stringify(response.data));
  } catch (error) {
    dispatch(loginFailure(error));
  }
};

export const restoreLoginDetails = (savedUserDetails) => (dispatch) => {
  const parsedUserDetails = JSON.parse(savedUserDetails);
  dispatch({
    type: RESTORE_AUTH,
    payload: parsedUserDetails,
  });
};