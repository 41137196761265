import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_USERS, FETCH_USERS_FAILED, FETCH_USERS_SUCCESSFULL } from "../constants/users.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getUsers = () => ({
    type: FETCH_USERS
});

const getUsersSuccess = (data) => ({
    type: FETCH_USERS_SUCCESSFULL,
    payload: data
});

const getUsersFailure = (error) => ({
    type: FETCH_USERS_FAILED,
    payload: error
});

export const fetchUsers = (token) => async (dispatch) => {
    try {
      dispatch(getUsers());
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.users}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getUsersSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getUsersFailure(error));
      }
    }
};