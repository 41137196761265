import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { refreshSingleCompetitor, resetRefreshCompetitor } from '../../redux/actions/refreshCompetitor.actions';
import { fetchProducts } from "../../redux/actions/getProducts.actions";


const RefreshCompetitorListing = ({dialogState, setDialogState, marketPlace, marketPlaces}) => {

    const dispatch = useDispatch()
    const themeMode = useSelector(state => state.theme.mode);
    const user_id = useSelector(state => state.auth.user_id);
    const token = useSelector(state => state.auth.token);
    const company_id = useSelector(state => state.auth.company_id);
    const qa_user = useSelector(state => state.auth.qa_user);
    const refreshCompData = useSelector(state => state.refreshCompetitorDetails);
    const productString = useSelector(state => state.productString);

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const matchedMatchedMarket = marketPlaces?.data?.find((obj)=>obj?.market_place_id === marketPlace)

    const handleClose = () => {
        setDialogState((prevState) => ({
          ...prevState,
          refresh: false,
        }));
        dispatch(resetRefreshCompetitor())
        setSubmitting(false)
        setServerMsg('')
    }

    const handleRefresh = () => {
    let queryString = `?prd_id=${dialogState.data.prd_id}&loc_id=${dialogState.data.locations?.[0]?.loc_id}&marketplace_id=${marketPlace}&cuttoff_score=${100}&user_id=${user_id}`
      if (queryString) {
        setServerMsg('');
        setSubmitting(true);
        dispatch(refreshSingleCompetitor(token, queryString))
      }
    }

    useEffect(() => {
        if (refreshCompData?.data) {
          handleClose();
          setSubmitting(false);
          dispatch(resetRefreshCompetitor());
        let filterString = '';
        let prdBrandInValue = '';
        let prdSearchValueIn = '';
    
        for (let key in productString) {
          if (key === 'page') {
              filterString = '?' + key + '=' + productString[key];
          } else if (productString[key]) {
              filterString = filterString + '&' + key + '=' + productString[key];
              if (key === 'prd_brand__in') {
                  prdBrandInValue = productString[key];
              } else if (key === 'search') {
                  prdSearchValueIn = productString[key];
              }
          }
      }
      const encodedPrdBrandInValue = encodeURIComponent(prdBrandInValue);
      const encodedPrdSearchValue = encodeURIComponent(prdSearchValueIn);
      if (filterString.includes('prd_brand__in')) {
          filterString = filterString.replace(prdBrandInValue, encodedPrdBrandInValue);
      }
      if (filterString.includes('search')) {
          filterString = filterString.replace(prdSearchValueIn, encodedPrdSearchValue);
      }
    
        if (marketPlace) {
          filterString = filterString + `&prd_remarks=${marketPlace}`;
        }
    
        if (qa_user) {
          filterString = filterString + `&company_id=${company_id}`
        }
        dispatch(fetchProducts(token, filterString, qa_user))
        } else if (refreshCompData.error) {
          setServerMsg('Failed to refesh');
          setSubmitting(false);
        }
      }, [refreshCompData]) //eslint-disable-line

      

  return (
    <Dialog
      open={dialogState.refresh}
      onClose={handleClose}
      PaperProps={{
        sx: { backgroundColor: themeMode === "dark" ? "#232D3B" : "white" },
      }}
    >
    {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Refreshing...</Typography> :
        <DialogContent sx={{ width: '230px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            Refresh
        </Typography>
        <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
        </Grid>
        <Grid sx={{ mt: 2 }}>
          <Typography sx={{fontSize: '11px'}} >
            <span style={{fontWeight: 'bold'}} >{dialogState?.data?.prd_name}</span>
            , will be refreshed from <span style={{fontWeight: 'bold'}} >{matchedMatchedMarket?.market_place_name}</span>
            . Click <span style={{fontWeight: 'bold'}}>"Confirm"</span> to continue
          </Typography>
        </Grid>
        <Typography sx={{ fontSize: '11px', color: 'red', mt: 1 }}>{serverMsg}</Typography>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button sx={{ textTransform: 'capitalize' }} size='small' variant='outlined' onClick={handleClose} >
            Cancel
          </Button>
          <Button sx={{ textTransform: 'capitalize' }} size='small' variant='contained' onClick={handleRefresh} >
            Confirm
          </Button>
        </Grid>
      </DialogContent>
      }
    </Dialog>
  );
};

export default RefreshCompetitorListing;
