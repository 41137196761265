import axios from "axios";
import { paths } from '../../Api/paths';
import { EDIT_RULE, EDIT_RULE_FAILED, EDIT_RULE_SUCCESSFULL, RESET_EDIT_RULE } from "../constants/editRule.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const editRule = () => ({
    type: EDIT_RULE
});

const editRuleSuccess = (data) => ({
    type: EDIT_RULE_SUCCESSFULL,
    payload: data
});

const editRuleFailure = (error) => ({
    type: EDIT_RULE_FAILED,
    payload: error
});

export const resetEditRule = () => ({
    type: RESET_EDIT_RULE
})

export const editExistingRule = (token, data, settingsId) => async (dispatch) => {
    try {
      dispatch(editRule());
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.modifyRules}${settingsId}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(editRuleSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
            const errorMessage = error.response?.data?.detail || "Failed to edit rule"; 
            dispatch(editRuleFailure(errorMessage));
          }
    }
};