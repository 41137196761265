import axios from "axios";
import { SIGN_UP, RESET_SIGN_UP, SIGN_UP_FAILED, SIGN_UP_SUCCESSFULL } from "../constants/signUp.constants";
import { paths } from "../../Api/paths";

const handleSignUp = () => ({
    type: SIGN_UP
});

const handleSignUpSuccessful = (data) => ({
    type: SIGN_UP_SUCCESSFULL,
    payload: data
});

const handleSignUpFailed = (error) => ({
    type: SIGN_UP_FAILED,
    payload: error
});

export const resetSignUp = () => ({
    type: RESET_SIGN_UP
});

export const signUpUser = (values) => async (dispatch) => {
    try{
        dispatch(handleSignUp());
        const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.signup}`,
        values)
        dispatch(handleSignUpSuccessful(response.data));
    }catch(error){
        dispatch(handleSignUpFailed(error));
    }
}