import axios from "axios";
import { paths } from '../../Api/paths';
import { UPDATE_USER_STATUS, UPDATE_USER_STATUS_FAILED, UPDATE_USER_STATUS_SUCCESSFULL, RESET_UPDATE_USER_STATUS } from "../constants/updateUserStatus.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const updateUserStatus = () => ({
    type: UPDATE_USER_STATUS,
    // payload: data
});

const updateUserStatusSuccess = (data) => ({
    type: UPDATE_USER_STATUS_SUCCESSFULL,
    payload: data
});

const updateUserStatusFailure = (error) => ({
    type: UPDATE_USER_STATUS_FAILED,
    payload: error
});

export const resetupdateUserStatus = () => ({
    type: RESET_UPDATE_USER_STATUS
})

export const updateExistingUserStatus = (data, token) => async (dispatch) => {
    try {
        dispatch(updateUserStatus());
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.users}${data.id}/`,
            data, { headers: { Authorization: `bearer ${token}` } })
            dispatch(updateUserStatusSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(updateUserStatusFailure(error));
          }
    }
};