import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_PRODUCT_HISTORY, FETCH_PRODUCT_HISTORY_FAILED, FETCH_PRODUCT_HISTORY_SUCCESSFULL} from "../constants/productHistory.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

export const getProductHistory = () => ({
    type: FETCH_PRODUCT_HISTORY
});

export const getProductHistorySuccess = (data) => ({
    type: FETCH_PRODUCT_HISTORY_SUCCESSFULL,
    payload: data
});

export const getProductHistoryFailure = (error) => ({
    type: FETCH_PRODUCT_HISTORY_FAILED,
    payload: error
});

export const fetchProductHistory = (token, product_id, start_date, end_date, comp_prd_id) => async (dispatch) => {
  try {
      dispatch(getProductHistory());

        const requestPayload = {
            start_date,
            end_date,
            comp_prd_id
        };
      
      const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}${paths.productHistory}${product_id}`,
          requestPayload, 
          {
              headers: { Authorization: `bearer ${token}` }
          }
      );

      dispatch(getProductHistorySuccess(response.data));
  } catch (error) {
      if (error.response && error.response.status === 401) {
          dispatch(handleTokenExpiration());
      } else {
          dispatch(getProductHistoryFailure(error));
      }
  }
};