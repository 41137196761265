import axios from "axios";
import { paths } from '../../Api/paths';
import { DELETE_RULE, DELETE_RULE_FAILED, DELETE_RULE_SUCCESSFULL, RESET_DELETE_RULE } from "../constants/deleteRule.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const deleteRule = () => ({
    type: DELETE_RULE
});

const deleteRuleSuccess = (data) => ({
    type: DELETE_RULE_SUCCESSFULL,
    payload: data
});

const deleteRuleFailure = (error) => ({
    type: DELETE_RULE_FAILED,
    payload: error
});

export const resetDeleteRule = () => ({
    type: RESET_DELETE_RULE
})

export const deleteExistingRule = (token, settingsId) => async (dispatch) => {
    try {
      dispatch(deleteRule());
      const response = await axios.delete(`${process.env.REACT_APP_API_LINK}${paths.modifyRules}${settingsId}`,
      { headers: { Authorization: `bearer ${token}` } });
      dispatch(deleteRuleSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(deleteRuleFailure(error));
          }
    }
};