import { Avatar, Dialog, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setQaClient } from '../../redux/actions/loginUser.actions';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { fetchClientsData } from '../../redux/actions/clients.actions';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';

const ChangeClientDialog = ({ dialogState, setDialogState, title }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const clients = useSelector(state => state.clients);
    const companyId = useSelector(state => state.auth.company_id);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = () => {
        if (companyId) {
            setDialogState(false);
        }
    }

    const handleChange = (client) => {
        const payload = {
            company_id: client.company_id,
            currency: client?.users[0]?.user?.currency,
            user_id: client?.users[0]?.user?.user_id
        }
        dispatch(setQaClient(payload));
        dispatch(getAllProductLocations(token, true, client.company_id));
        navigate('/myProducts');
        setDialogState(false);
    }

    useEffect(() => {
        if (!clients.data) {
            dispatch(fetchClientsData(token));
        }
    }, []) //eslint-disable-line

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const errorMsg = { ...fontColor, textAlign: 'center', mt: '40vh', fontSize: '13px' }

    return (
        <Dialog open={dialogState} onClose={handleClose} width='md' PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ ...fontColor, fontSize: '16px', fontWeight: 'bold', p: 2 }} >{title}</Typography>
                {companyId && <IconButton sx={{ ...fontColor, ml: 'auto', mr: 2 }} onClick={handleClose} >
                    <CloseIcon sx={{fontSize: '20px'}} />
                </IconButton>}
            </Grid>
            <Grid sx={{ height: '100vh', overflowY: 'scroll' }} className={`custom-scrollbar-${themeMode}`}>
                {clients.loading ? <Typography sx={errorMsg} >Fetching the client list, please wait...</Typography> :
                    clients.error ? <Typography sx={errorMsg} >Failed to fetch the client list</Typography> :
                        clients.data?.length === 0 ? <Typography sx={errorMsg} >There is no client added</Typography> :
                            clients.data?.map(client => (<Grid key={client.company_id} px={4} py={1} onClick={() => handleChange(client)} sx={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer', '&:hover': { backgroundColor: themeMode === 'light' ? '#f4d35e' : '#3F526B' } }}>
                                {client.company_logo ? <img src={client.company_logo} width='50' height='50' alt='logo' style={{ objectFit: "contain" }} />
                                    : <Avatar sx={{ width: 50, height: 50 }} alt='logo' />}
                                <Typography sx={{ ...fontColor, textTransform: 'capitalize' }}>{client.company_name}</Typography>
                                {Number(companyId) === client.company_id && <Typography sx={{ ...fontColor, fontSize: '12px', ml: 'auto' }}>(Current)</Typography>}
                            </Grid>))}
            </Grid>
        </Dialog>
    )
}

export default ChangeClientDialog