import React, { useState } from 'react'
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewGroupDetails from '../ViewGroupDetails';
import GroupActionDialog from '../GroupActionDialog';
import DeleteDialog from '../DeleteDialog';

const ManageGroups = ({rules}) => {

    const themeMode = useSelector(state => state.theme.mode);
    const groups = useSelector(state => state.groups);

    const [dialogState, setDialogState] = useState({
        addNewGroup: false,
        viewDetails: false,
        editDetails: false,
        deleteGroup: false,
        data: null
    })

    const openDialog = (type, data) => {
        const tempState = { ...dialogState, [type]: true, data: data }
        setDialogState(tempState);
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    return (
        <>
            {dialogState.deleteGroup && <DeleteDialog allRules={rules} dialogState={dialogState} setDialogState={setDialogState} dialogFor='deleteGroup' />}
            {(dialogState.addNewGroup || dialogState.editDetails) && <GroupActionDialog groups={groups} dialogState={dialogState} setDialogState={setDialogState} />}
            {dialogState.viewDetails && <ViewGroupDetails dialogState={dialogState} setDialogState={setDialogState} />}
            <Grid p={2} mt={4} sx={{ backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
                <Grid item container sx={{ display: "flex", justifyContent: "space-between" }} >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', ml: 1 }}>Product Groups</Typography>
                    <Button variant='contained' sx={{ textTransform: 'none', mx: 2, fontSize: '13px' }}
                        onClick={() => openDialog('addNewGroup')}
                    >+ Create New Group</Button>
                </Grid>
                {groups.loading ? <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Fetching the groups, please wait...</Typography> :
                    groups.data ? <TableContainer sx={{ mt: 3 }}>
                        <Table sx={{ '& .MuiTableCell-root': fontColor }}>
                            <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1A222D' : '#FDF8E5' }}>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>No of Products Count</TableCell>
                                    <TableCell>Group Description</TableCell>
                                    <TableCell>Created on</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.data?.map((grp, index) => {
                                    return (
                                        <TableRow key={grp?.group_id}>
                                            <TableCell sx={{fontSize: '13px', p: '7px 0px 7px 16px'}} >{index + 1}</TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize', fontSize: '13px', p: '7px 0px 7px 16px' }} >{grp?.group_name}</TableCell>
                                            <TableCell sx={{ pl: 10, fontSize: '13px' }}>{grp?.product_count}</TableCell>
                                            <TableCell sx={{fontSize: '13px', p: '7px 0px 7px 16px'}} >{grp?.group_desc ? grp?.group_desc?.charAt(0)?.toUpperCase() + grp?.group_desc?.slice(1) : ''}</TableCell>
                                            <TableCell sx={{fontSize: '13px', p: '7px 0px 7px 16px'}} >{grp?.created_date ? new Date(grp?.created_date).toLocaleString() : '--'}</TableCell>
                                            <TableCell sx={{p: '7px 0px 7px 16px'}} >
                                            <IconButton onClick={() => openDialog('deleteGroup', grp)}>
                                                <DeleteOutlineOutlinedIcon style={{...fontColor, fontSize: '20px'}} />
                                            </IconButton>
                                                <IconButton onClick={() => openDialog('editDetails', grp)} >
                                                    <EditNoteIcon style={{...fontColor, fontSize: '20px'}} />
                                                </IconButton>
                                                <IconButton onClick={() => openDialog('viewDetails', grp)} >
                                                    <RemoveRedEyeIcon style={{...fontColor, fontSize: '20px'}} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                        <Typography sx={{ fontSize: '16px', display: 'flex', justifyContent: 'center', my: 10 }}>Failed to fetch the groups data, please contact admin for more support</Typography>}
            </Grid>
        </>
    )
}

export default ManageGroups;