import styled from '@emotion/styled';
import { Autocomplete, Button, FormControl, Grid, InputBase, InputLabel, Paper, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AddCategoryDialog from '../AddCategoryDialog';
import AddDepartmentDialog from '../AddDepartmentDialog';
import AddLocationDialog from '../AddLocationDialog';
import AddSellerDialog from '../AddSellerDialog';
import { fetchCategories } from '../../redux/actions/categories.actions';
import { fetchDepartments } from '../../redux/actions/departments.actions';
import { fetchLocations } from '../../redux/actions/locations.actions';
import { fetchSellers } from '../../redux/actions/sellers.actions';
import { addSingleProduct, resetAddSingleProduct } from '../../redux/actions/addSingleProduct.actions';
import Loading from '../Loading';

const SingleProduct = () => {

  const themeMode = useSelector(state => state.theme.mode);

  const token = useSelector(state => state.auth.token);
  const categories = useSelector(state => state.categories);
  const departments = useSelector(state => state.departments);
  const locations = useSelector(state => state.locations);
  const sellers = useSelector(state => state.sellers);
  const productsAdded = useSelector(state => state.addSingleProduct);

  const dispatch = useDispatch();

  const steps = ['Step 1', 'Step 2'];
  const [activeStep, setActiveStep] = useState(0);
  const [dialogIsOpen, setDialogIsOpen] = useState({ category: false, department: false, location: false, seller: false })
  const [activeSalesId, setActiveSalesId] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [serverMsg, setServerMsg] = useState({ success: '', error: '' });
  const newCategory = { category_id: 'new', category_name: 'Add New Category' };
  const newDepartment = { dept_id: 'new', dept_name: 'Add New Department' };
  const newLocation = { loc_id: 'new', loc_name: 'Add New Location' };
  const newSeller = { seller_id: 'new', seller_name: 'Add New Seller' };

  const initialValues = {
    prd_name: '', category_id: '', prd_desc: '', dept_id: '', prd_small_image: '', prd_medium_image: '',
    prd_variants: '', prd_brand: '', prd_url: '', prd_upc: '', prd_no_of_pieces: 1, keyword: '', prd_asin: '',
    prd_is_active: true, prd_is_crawl_enabled: true, prd_is_match_enabled: "", prd_size: '1', prd_uom: '',
    prd_rating: 4.0, prd_tot_reviews: 0, prd_spec: '', prd_notification_enabled: false, locations: [
      { 'loc_id': '', 'seller_id': '', 'prd_sales_price': 0, 'prd_mrp': 0, 'prd_norm_price': 0, 'prd_offer': '' }]
  }

  const SingleProductSchema = Yup.object().shape({
    prd_name: Yup.string().required('Product Name is required'),
    category_id: Yup.number(),
    prd_desc: Yup.string(),
    dept_id: Yup.number(),
    prd_medium_image: Yup.string(),
    prd_variants: Yup.string(),
    prd_brand: Yup.string(),
    prd_url: Yup.string(),
    prd_upc: Yup.string(),
    prd_no_of_pieces: Yup.number()
      .typeError('Number of pieces must be a valid number')
      .required('Number of pieces is required')
      .positive('Number of pieces must be positive'),
    prd_asin: Yup.string(),
    prd_size: Yup.string().required('Size is required'),
    prd_uom: Yup.string().required('Unit of Measurement is required'),
    locations: Yup.array().of(
      Yup.object().shape({
        loc_id: Yup.number()
          .typeError('Location is required')
          .test('is-new', 'Location is required', (value) => {
            return value === 'new' || !isNaN(value);
          }),
        seller_id: Yup.number().when('loc_id', {
          is: (loc_id) => loc_id,
          then: () => Yup.number().typeError('Seller is required').test('is-new', 'Seller is required', (value) => {
            return value === 'new' || !isNaN(value);
          }),
          otherwise: () => Yup.number().required('Select location before selecting seller'),
        }),
        prd_mrp: Yup.number().typeError('MRP must be a number'),
        prd_sales_price: Yup.number().when(['$prd_url', 'prd_mrp'], {
          is: (prd_url, prd_mrp) => prd_url || prd_mrp,
          then: () => Yup.number().typeError('Sale Price must be a number'),
          otherwise: () => Yup.number().typeError('Sale Price must be a number').required('If you have not provided URL, either MRP or Sales price is required').moreThan(0, 'Sales price must be greater than 0'),
        }),
      })
    ),
  });

  const handleOpenDialog = (field, saleId) => {
    const dialogState = { category: false, department: false, location: false, seller: false, data: saleId };
    dialogState[field] = true;
    setDialogIsOpen(dialogState);
    setActiveSalesId(saleId)
  };


  const handleClose = () => {
    setDialogIsOpen({ category: false, department: false, location: false, seller: false })
    setActiveSalesId(null)
  }

  const addLocation = (locations, fieldValue) => {
    const newLocations = [...locations, { 'loc_id': '', 'seller_id': '', 'prd_sales_price': 0, 'prd_mrp': 0 }];
    fieldValue('locations', newLocations);
  }

  const removeLocation = (locations, index, setFieldValue) => {
    locations.splice(index, 1);
    setFieldValue('locations', locations);
  };

  const handleBack = () => {
    setActiveStep(0);
  }

  const handleNext = () => {
    setActiveStep(1);
  }

  const handleSubmit = (values) => {
    setSubmitting(true);
    setServerMsg({ success: '', error: '' });
    const updatedLocations = values.locations.map(item => {
      if ( item.prd_mrp === '' && item.prd_sales_price === '' ) {
        item.prd_mrp = null;
        item.prd_sales_price = null;
      }else {
        if ( item.prd_mrp === '' || item.prd_mrp === 0 ) {
          item.prd_mrp = item.prd_sales_price;
        } 
        if ( item.prd_sales_price === '' || item.prd_sales_price === 0 ) {
          item.prd_sales_price = item.prd_mrp;
        }
        item.prd_mrp = Number(item.prd_mrp);
        item.prd_sales_price = Number(item.prd_sales_price);
      } 
      return item;
    })
    const categoryId = values.category_id === '' ? null : values.category_id;
    const dept_id = values.dept_id === '' ? null : values.dept_id;
    const payload = { ...values, locations: updatedLocations, category_id: categoryId, dept_id: dept_id }
    dispatch(addSingleProduct(payload, token))
  }

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories(token, 'all'));
      dispatch(fetchDepartments(token, 'all'));
      dispatch(fetchLocations(token));
      dispatch(fetchSellers(token));
    }
  }, [token]) //eslint-disable-line

  useEffect(() => {
    if (productsAdded?.success) {
      setSubmitting(false);
      dispatch(resetAddSingleProduct());
      setServerMsg({ success: 'Product added successfully', error: '' });
    } else if (productsAdded.error) {
      setServerMsg({ success: '', error: 'Failed to add product' });
      setSubmitting(false);
    }
  }, [productsAdded]) //eslint-disable-line

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '9px 12px',
      textTransform: 'capitalize',
      color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      transition: theme.transitions?.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    }
  }));

  const inputBoxStyle = {
    position: 'relative',
    color: themeMode === 'dark' ? '#E4E3E3 !important' : '#5A5A5A !important',
    fontSize: '13px',
    '&.Mui-focused': {
      color: themeMode === 'dark' ? '#E4E3E3 !important' : '#5A5A5A !important',
    },
  }

  const dropDownStyle = {
    '& .MuiAutocomplete-inputRoot': {
      fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3',
      border: `1px solid ${themeMode === 'light' ? '#ced4da' : '#E4E3E3'}`
    }, borderRadius: 1, '& .MuiAutocomplete-popupIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' },
    '& .MuiAutocomplete-clearIndicator': { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  }

  const LocationInputSet = (setFieldValue, values, saleId, errors) => {
    return (
      
      <div>
        {dialogIsOpen.seller && <AddSellerDialog activeSalesId={activeSalesId} setFieldValue={setFieldValue} sellerDialog={dialogIsOpen.seller} loc_id={values.locations[saleId].loc_id} handleClose={handleClose} />}
        {dialogIsOpen.location && <AddLocationDialog activeSalesId={activeSalesId} setFieldValue={setFieldValue} locDialog={dialogIsOpen.location} handleClose={handleClose} dropDownStyle={dropDownStyle} inputBoxStyle={inputBoxStyle} />}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '11px', mt: '14px', mb: '5px' }}>Location</Typography>
            <Autocomplete
              id={`locations[${saleId}].loc_id`}
              size='small'
              ListboxProps={{
                className: `custom-scrollbar-${themeMode}`
              }}
              options={locations?.data ? [newLocation, ...locations.data] : [newLocation]}
              value={locations?.data?.find(location => location?.loc_id === values?.loc_id) || null}
              getOptionLabel={(option) => `${option.loc_name} ${option.loc_zip ? `(${option.loc_zip})` : ''}`}
              onChange={(e, value) => {
                if (value && value.loc_id === 'new') {
                  handleOpenDialog('location', saleId);
                }
                setFieldValue(`locations[${saleId}].loc_id`, value ? value.loc_id : '');
              }}
              PaperComponent={({ children }) => (
                <Paper sx={{
                  fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D', textTransform: 'capitalize',
                  '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                }}>{children}</Paper>
              )}
              sx={dropDownStyle}
              renderInput={(params) =>
                <TextField {...params} variant='outlined' placeholder='Select Location'
                  sx={{
                    '& .MuiInputBase-input': {
                      textTransform: 'capitalize',
                    },
                  }} />}
              isOptionEqualToValue={(option, value) => option.loc_id === value.loc_id}
            />
            <Typography variant='subtitle' sx={{ fontSize: '11px', color: 'red' }}>
              {errors?.locations && errors?.locations[saleId] ? errors?.locations[saleId]?.loc_id : ''}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography sx={{ fontSize: '11px', mt: '14px', mb: '5px' }}>Seller</Typography>
            <Autocomplete
              id={`locations[${saleId}].seller_id`}
              size='small'
              ListboxProps={{
                className: `custom-scrollbar-${themeMode}`
              }}
              options={sellers?.data ? [newSeller, ...sellers.data] : [newSeller]}
              value={sellers?.data?.find(seller => seller.seller_id === values?.locations?.[saleId]?.seller_id) || null}
              getOptionLabel={(option) => option.seller_name}

              onChange={(e, value) => {
                if (value && value.seller_id === 'new') {
                  handleOpenDialog('seller', saleId);
                }
                setFieldValue(`locations[${saleId}].seller_id`, value ? value.seller_id : '');
              }}
              PaperComponent={({ children }) => (
                <Paper sx={{
                  fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D', textTransform: 'capitalize',
                  '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                }}>{children}</Paper>
              )}
              sx={dropDownStyle}
              renderInput={(params) =>
                <TextField {...params} variant='outlined' placeholder='Select Seller'
                  sx={{
                    '& .MuiInputBase-input': {
                      textTransform: 'capitalize',
                    },
                  }}
                />}
              isOptionEqualToValue={(option, value) => option.seller_id === value.seller_id}
              disabled={values.locations[saleId].loc_id === '' || values.locations[saleId].loc_id === 'new'}
            />
            <Typography variant='subtitle' sx={{ fontSize: '11px', color: 'red' }}>
              {errors?.locations && errors?.locations[saleId] ? errors?.locations[saleId]?.seller_id : ''}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabel
                shrink
                htmlFor={`locations[${saleId}].prd_mrp`}
                sx={inputBoxStyle}
              >
                MRP *
              </InputLabel>
              <Field
                component={BootstrapInput}
                id={`locations[${saleId}].prd_mrp`}
                value={values.locations[saleId].prd_mrp}
                onFocus={() => setFieldValue(`locations[${saleId}].prd_mrp`, '')}
                onChange={(e) => setFieldValue(`locations[${saleId}].prd_mrp`, e.target.value)}
                placeholder='Enter MRP'
              />
              <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                {errors?.locations && errors?.locations[saleId] ? errors?.locations[saleId]?.prd_mrp : ''}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={values.locations.length === 1 ? 3 : 2}>
            <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
              <InputLabel
                shrink
                htmlFor={`locations[${saleId}].prd_sales_price`}
                sx={inputBoxStyle}
              >
                Sale Price *
              </InputLabel>
              <Field
                component={BootstrapInput}
                id={`locations[${saleId}].prd_sales_price`}
                value={values.locations[saleId].prd_sales_price}
                onFocus={(e) => setFieldValue(`locations[${saleId}].prd_sales_price`, '')}
                onChange={(e) => setFieldValue(`locations[${saleId}].prd_sales_price`, e.target.value)}
                placeholder='Enter Sale Price' />
              <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                {errors?.locations && errors?.locations[saleId] ? errors?.locations[saleId]?.prd_sales_price : ''}
              </Typography>
            </FormControl>
          </Grid>
          {values.locations.length > 1 && (
            <ClearIcon sx={{ marginTop: '55px', marginLeft: '10px', cursor: 'pointer', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}
              size='small' variant='contained' onClick={() => removeLocation(values.locations, saleId, setFieldValue)} />)}
        </Grid>
      </div>
    )
  }

  return (
      <>
        {(categories?.loading || departments?.loading || locations?.loading || sellers?.loading) ? (
          <Loading height="80px" />
        ) : (
          submitting ? <Loading height='80vh' /> : <Grid item sx={{ mt: 1 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{
                    '& .MuiStepLabel-label':
                      { color: themeMode === 'dark' ? '#E4E3E3 !important' : '#5A5A5A !important' },
                    '& .Mui-active': { fontWeight: 'bold !important' }
                  }}
                  >{label}</StepLabel>
                </Step>))}
            </Stepper>
            <Formik enableReinitialize initialValues={initialValues}
              validateOnChange={false}   validateOnBlur={false}     validateOnSubmit={true}  
              validationSchema={SingleProductSchema} onSubmit={(values) => handleSubmit(values)}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  {dialogIsOpen.category && <AddCategoryDialog setFieldValue={setFieldValue} cateDialog={dialogIsOpen.category} handleClose={handleClose} />}
                  {dialogIsOpen.department && <AddDepartmentDialog setFieldValue={setFieldValue} deptDialog={dialogIsOpen.department} handleClose={handleClose} />}
                  {activeStep === 0 ? <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel shrink htmlFor='prd_name' sx={inputBoxStyle}>
                            Product Name *
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_name'
                            value={values.prd_name}
                            onChange={(e) => setFieldValue('prd_name', e.target.value)}
                            placeholder='Enter Product Name'
                            inputProps={{
                              style: { textTransform: 'capitalize' }
                            }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                            {errors.prd_name}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_desc'
                            sx={inputBoxStyle}
                          >
                            Product Description
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_desc'
                            value={values.prd_desc}
                            onChange={(e) => setFieldValue('prd_desc', e.target.value)}
                            placeholder='Enter Product Description'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography sx={{ fontSize: '11px', mt: '14px', mb: '5px' }}>Category</Typography>
                        <Autocomplete
                          id='category_id'
                          size='small'
                          ListboxProps={{
                            className: `custom-scrollbar-${themeMode}`
                          }}
                          options={categories?.data ? [newCategory, ...categories.data] : [newCategory]}
                          value={categories?.data?.find(category => category?.category_id === values?.category_id) || null}
                          getOptionLabel={(option) => option.category_name}
                          onChange={(e, value) => {
                            if (value && value.category_id === 'new') {
                              handleOpenDialog('category');
                            }
                            setFieldValue('category_id', value ? value.category_id : '');
                          }}
                          PaperComponent={({ children }) => (
                            <Paper sx={{
                              fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D', textTransform: 'capitalize',
                              '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                            }}>{children}</Paper>
                          )}
                          sx={dropDownStyle}
                          renderInput={(params) =>
                            <TextField {...params} variant='outlined' placeholder='Select Category'
                              sx={{
                                '& .MuiInputBase-input': {
                                  textTransform: 'capitalize',
                                },
                              }}
                            />}
                          isOptionEqualToValue={(option, value) => option.category_id === value.category_id}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography sx={{ fontSize: '11px', mt: '14px', mb: '5px' }}>Department</Typography>
                        <Autocomplete
                          id='dept_id'
                          size='small'
                          ListboxProps={{
                            className: `custom-scrollbar-${themeMode}`
                          }}
                          getOptionLabel={(option) => option.dept_name}
                          options={departments?.data ? [newDepartment, ...departments.data] : [newDepartment]}
                          value={departments?.data?.find(dept => dept?.dept_id === values?.dept_id) || null}

                          onChange={(e, value) => {
                            if (value && value.dept_id === 'new') {
                              handleOpenDialog('department');
                            }
                            setFieldValue('dept_id', value ? value.dept_id : '');
                          }}
                          PaperComponent={({ children }) => (
                            <Paper sx={{
                              fontSize: '11px', color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3', backgroundColor: themeMode === 'light' ? '#eaecee' : '#1A222D', textTransform: 'capitalize',
                              '& .MuiAutocomplete-noOptions': { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
                            }}>{children}</Paper>
                          )}
                          sx={dropDownStyle}
                          renderInput={(params) =>
                            <TextField {...params} variant='outlined' placeholder='Select Department'
                              sx={{
                                '& .MuiInputBase-input': {
                                  textTransform: 'capitalize',
                                },
                              }}
                            />}
                          isOptionEqualToValue={(option, value) => option.dept_id === value.dept_id}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_medium_image'
                            sx={inputBoxStyle}
                          >
                            Product Image
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_medium_image'
                            value={values.prd_medium_image}
                            onChange={(e) => setFieldValue('prd_medium_image', e.target.value)}
                            placeholder='Enter Product Image Path'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_variants'
                            sx={inputBoxStyle}
                          >
                            Variant
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_variants'
                            value={values.prd_variants}
                            onChange={(e) => setFieldValue('prd_variants', e.target.value)}
                            placeholder='Enter Variant'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_brand'
                            sx={inputBoxStyle}
                          >
                            Brand
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_brand'
                            value={values.prd_brand}
                            onChange={(e) => setFieldValue('prd_brand', e.target.value)}
                            placeholder='Enter Brand Name'
                            inputProps={{
                              style: { textTransform: 'capitalize' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_url'
                            sx={inputBoxStyle}
                          >
                            URL
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_url'
                            value={values.prd_url}
                            onChange={(e) => setFieldValue('prd_url', e.target.value)}
                            placeholder='Enter URL'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </> : <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_upc'
                            sx={inputBoxStyle}
                          >
                            UPC
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_upc'
                            value={values.prd_upc}
                            onChange={(e) => setFieldValue('prd_upc', e.target.value)}
                            placeholder='Enter UPC'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_no_of_pieces'
                            sx={inputBoxStyle}
                          >
                            Number of Pieces *
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_no_of_pieces'
                            value={values.prd_no_of_pieces}
                            onFocus={() => setFieldValue('prd_no_of_pieces', '')}
                            onChange={(e) => setFieldValue('prd_no_of_pieces', e.target.value)}
                            placeholder='Enter Number of pieces'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                            {errors.prd_no_of_pieces}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_asin'
                            sx={inputBoxStyle}
                          >
                            ASIN
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_asin'
                            value={values.prd_asin}
                            onChange={(e) => setFieldValue('prd_asin', e.target.value)}
                            placeholder='Enter ASIN'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_size'
                            sx={inputBoxStyle}
                          >
                            Size *
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_size'
                            value={values.prd_size}
                            onFocus={() => setFieldValue('prd_size', '')}
                            onChange={(e) => setFieldValue('prd_size', e.target.value)}
                            placeholder='Enter Size'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                            {errors.prd_size}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor='prd_uom'
                            sx={inputBoxStyle}
                          >
                            Unit of Measurement *
                          </InputLabel>
                          <Field
                            component={BootstrapInput}
                            id='prd_uom'
                            value={values.prd_uom}
                            onChange={(e) => setFieldValue('prd_uom', e.target.value)}
                            placeholder='Enter Unit of Measurement'
                            inputProps={{
                              style: { textTransform: 'none' }
                            }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>
                            {errors.prd_uom}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {values.locations.map((item, index) => (
                      <div key={`sales_${index}`}>
                        {LocationInputSet(setFieldValue, values, index, errors)}
                      </div>
                    ))}
                    <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Button
                        sx={{
                          mt: 1.5,  alignSelf: 'center',   backgroundColor: '#4cdca4',
                          borderColor: '#4cdca4', color: 'white',  fontSize: '11px', width:"15%",
                          textTransform: 'capitalize', 
                          '&:hover': { backgroundColor: '#31b582'},
                        }}
                        onClick={() => addLocation(values.locations, setFieldValue)}
                      >
                        Add Location </Button>
                    </Grid>
                  </>}
                  <Grid item xs={12} sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1, textTransform: 'capitalize' }} > Back </Button>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Button 
                        sx={{
                          display: activeStep === 1 ? 'flex' : 'none', alignSelf: 'center', 
                          backgroundColor: '#559EFF', '&:hover': { backgroundColor: '#4287F5'},
                          borderColor: '#1C3B6E', color: themeMode === 'dark' ? '#E4E3E3' : 'white', 
                          fontSize: '11px', textTransform: 'capitalize'
                        }} type='submit'>
                        Add product
                      </Button>
                      <Typography variant='body2' style={{ marginTop: '16px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{serverMsg.success}</Typography>
                      <Typography variant='body2' style={{ marginTop: '16px', color: 'red' }}>{serverMsg.error}</Typography>
                      {Object.keys(errors).length > 0 && (
                        <Typography variant='body2' style={{ marginTop: '16px', color: 'red' }}>
                          Please fix the form errors before adding the product.
                        </Typography>
                      )}
                    </div>
                    <Button disabled={activeStep === 1} onClick={handleNext} sx={{ mr: 1, color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'capitalize' }}> Next </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </>
  )
}

export default SingleProduct;