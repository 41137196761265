import { EDIT_CLIENT, EDIT_CLIENT_FAILED, EDIT_CLIENT_SUCCESSFULL, RESET_EDIT_CLIENT } from "../constants/editClient.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const editClientReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_CLIENT:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case EDIT_CLIENT_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case EDIT_CLIENT_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_EDIT_CLIENT:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default editClientReducer;