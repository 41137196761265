import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_CATEGORIES, FETCH_CATEGORIES_FAILED, FETCH_CATEGORIES_SUCCESSFULL } from "../constants/categories.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getCategories = () => ({
    type: FETCH_CATEGORIES
});

const getCategoriesSuccess = (data) => ({
    type: FETCH_CATEGORIES_SUCCESSFULL,
    payload: data
});

const getCategoriesFailure = (error) => ({
    type: FETCH_CATEGORIES_FAILED,
    payload: error
});

export const fetchCategories = (token, keyword, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getCategories());
      const endpoint = qa_user ? `${paths.qaCategories}?company_id=${company_id}` : `${paths.categories}?type=${keyword}`;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getCategoriesSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getCategoriesFailure(error));
      }
    }
};