import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import moment from "moment";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchProductHistory } from "../../../redux/actions/productHistory.actions";
import Loading from "../../Loading";
import PriceChartFilterDialog from "../../PriceChartFilterDialog";

// Register the components needed for Chart.js
ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = () => {
  const dispatch = useDispatch();
  const { product_id } = useParams();
  const themeMode = useSelector((state) => state.theme.mode);
  const token = useSelector((state) => state.auth.token);
  const pdtChartDetails = useSelector((state) => state.productHistoryDetails);
  const currSymb = useSelector((state) => state.auth?.currency?.length !== 0 ? state.auth?.currency[0]?.currency_symbol?.trim() : "");
  const productDetails = useSelector(state => state.productDetails);
  const competitors = productDetails?.data?.competitors || [];
  const [openDialog, setOpenDialog] = useState(false);

  // Extract product prices data
  const productPrices = pdtChartDetails?.data?.product?.[0]?.prices || [];

  // Extract competitor prices data
  const competitorPrices = pdtChartDetails?.data?.competitor || [];

  const hasData = productPrices?.length > 0 || competitorPrices?.length > 0;

  // Create a set of all unique dates from product and competitor prices
  const allDates = [
    ...new Set([
      ...productPrices.map((p) => moment(p.modified_date).format("DD-MM-YYYY")),
      ...competitorPrices.flatMap((comp) =>
        comp.prices.map((price) =>
          moment(price.modified_date).format("DD-MM-YYYY")
        )
      ),
    ]),
  ].sort();

  // Create datasets for product price
  const productDataset = {
    label: "My Product",
    data: allDates.map((date) => {
      const price = productPrices.find(
        (p) => moment(p.modified_date).format("DD-MM-YYYY") === date
      );
      return price ? price.prd_sales_price : null;
    }),
    borderColor: "#2f4d78",
    backgroundColor: "#2f4d78",
    fill: false,
    // spanGaps: false, // Do not connect lines through gaps
  };

  // Create datasets for each competitor
  const competitorDatasets = competitorPrices.map((competitor, index) => {
    return {
      label: `Competitor Id: ${competitor.comp_prd_id}`,
      data: allDates.map((date) => {
        const price = competitor.prices.find(
          (price) => moment(price.modified_date).format("DD-MM-YYYY") === date
        );
        return price ? price.comp_prd_sales_price : null; // Price for each date
      }),
      borderColor: "#7cacf2",
      backgroundColor: "#7cacf2",
      fill: false,
      // spanGaps: false,
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            // const date = tooltipItem.label;
            const price = tooltipItem.raw;
            return [
              `Competitor Id: ${competitor.comp_prd_id}`,
              `Price: ${price !== null ? `${currSymb}${price}` : "N/A"}`,
            ];
          },
        },
      },
    };
  });

  // Combine product and competitor datasets
  const chartData = {
    labels: allDates,
    datasets: [productDataset, ...competitorDatasets],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // barThickness: 50,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          color: themeMode === "dark" ? "#E4E3E3" : "#5A5A5A",
          font: {
            size: 10,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
        onClick: () => {}, // Disable legend click functionality
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `Price: ${currSymb}${value}`;
          },
        },
      },
      datalabels: {
        display: true,
        color: themeMode === "dark" ? "#E4E3E3" : "#5A5A5A",
        anchor: "end",
        align: "end",
        offset: -4,
        formatter: (value) => {
          return value != null ? `${currSymb}${value}` : "";
        },
        font: {
          size: 10,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: themeMode === "dark" ? "#5c5a5a" : "#e0e0e0",
        },
        ticks: {
          color: themeMode === "dark" ? "#b8b6b6" : "#5A5A5A",
        },
        border: {
          color: themeMode === "dark" ? "#b8b6b6" : "#5A5A5A",
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: themeMode === "dark" ? "#b8b6b6" : "#5A5A5A",
        },
        border: {
          color: themeMode === "dark" ? "#b8b6b6" : "#5A5A5A",
        },
      },
    },
  };

  useEffect(() => {
    const start_date = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const end_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

    dispatch(fetchProductHistory(token, product_id, start_date, end_date, competitors?.[0]?.comp_prd_id));
  }, []); //eslint-disable-line

  const fontColor = { color: themeMode === "dark" ? "#E4E3E3" : "#5A5A5A" };

  return (
    <div
      style={{ padding: "10px 50px 20px 50px", boxSizing: "border-box", width: "100%" }}>
        <PriceChartFilterDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
        <h3 style={{ fontSize: "12px", margin: 0, color: fontColor }}>
          Price Variation Chart
        </h3>

        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button size='small' variant='outlined' startIcon={<FilterListIcon style={{ fontSize: '17' }} />} pr={1} sx={{
                ...fontColor, height: '30px', textTransform: "capitalize", borderColor: '#d0d5dd', fontSize: '11px',
              }} 
              onClick={()=>setOpenDialog(true)}
              >Filters</Button>
        </Box>
      </Box>
      <div
        style={{
          border:
            themeMode === "dark" ? "1px solid #1a2027" : "1px solid #f9f9f9",
            backgroundColor: themeMode === "dark" ? "#1a2027" : "#f9f9f9",
            borderRadius: "8px", padding: "30px", height: "100%",  overflowX: "auto",
        }}
      >
        <Typography sx={{fontSize:"12px"}} mb={2} >
          Competitor Product: <span style={{color: fontColor, fontWeight: 'bold'}} >{pdtChartDetails?.data?.competitor?.[0]?.comp_prd_name ? 
          pdtChartDetails?.data?.competitor?.[0]?.comp_prd_name : competitors?.[0]?.comp_prd_name}</span>
        </Typography>
        {pdtChartDetails.loading ? (
          <Loading height={"80px"} />
        ) : pdtChartDetails.error ? (
          <Typography
            sx={{ color: fontColor, fontSize: "14px", textAlign: "center" }}
          >
            Some error occured...
          </Typography>
        ) : hasData ? (
          // <div style={{height:"60vh", width:"100%"}} >
          // <Bar data={chartData} options={options} />
          // </div>
          <div style={{ height: "60vh", width: "100%", overflowX: "auto" }} className={`custom-scrollbar-${themeMode}`}>
            <div style={{ minWidth: `${chartData.labels.length * 100}px`, height: "100%" }}> {/* Adjust width based on the number of labels */}
              <Bar data={chartData} options={options} />
            </div>
          </div>
        ) : (
          <Typography
            sx={{ color: fontColor, fontSize: "14px", textAlign: "center" }}
          >
            No data available
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LineChart;
