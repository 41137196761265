import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import { useSelector } from 'react-redux';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import BulkUpload from '../../components/BulkUpload';
import SingleProduct from '../../components/SingleProduct';
import Footer from '../../components/Footer';

const AddProducts = () => {

  const themeMode = useSelector(state => state.theme.mode);

  const [selected, setSelected] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelected(newValue);
  }

  const tabColor = {
    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
    '&.Mui-selected': { color: '#1976d2' }, textTransform: 'none', fontWeight: 'bold'
  }

  return (
    <>
      <Navbar selected='addProducts' />
      <Grid px={20} py={3} className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Box>
          <Tabs value={selected} onChange={handleChangeTab}>
            <Tab label='Bulk Products' sx={tabColor} />
            <Tab label='Single Product' sx={tabColor} />
          </Tabs>
        </Box>
        <Grid sx={{ bgcolor: themeMode === 'light' ? '#fff' : '#232D3B', mt: 3, p: 3, borderRadius: 1 }}>
          {selected === 0 ? <BulkUpload /> :
            <SingleProduct />}
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default AddProducts;