import { PRODUCT_LOCATIONS_FAILED, PRODUCT_LOCATIONS_GET, PRODUCT_LOCATIONS_SUCCESSFULL } from "../constants/productLocations.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const productLocationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LOCATIONS_GET:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case PRODUCT_LOCATIONS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case PRODUCT_LOCATIONS_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default productLocationsReducer;