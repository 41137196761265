import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputBase, InputLabel, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/loginUser.actions';
import './login.css'
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import ChangeClientDialog from '../../components/ChangeClientDialog';
import { fetchClientsData } from '../../redux/actions/clients.actions';
import { resetSignUp } from '../../redux/actions/singUp.actions';

const Login = () => {

  const auth = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState(false);
  const [error, setError] = useState(false);

  const SignInSchema = Yup.object().shape({
    username: Yup.string().min(3, 'Too short - should be 6 chars minimum!').required('Required'),
    password: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })

  const handleFormSubmit = (credentials) => {
    setError(false);
    dispatch(login(credentials));
  }

  useEffect(() => {
    if (auth.role === 1) {
      navigate('/clients');
    } else if (auth.role === 2) {
      dispatch(fetchClientsData(auth.token));
      setDialogState(true);
    } else if(auth.admin_approved === false) {
      navigate('/pending-approval')
    }
     else if (!auth.productsCreated && auth.user_id) {
      dispatch(getAllProductLocations(auth.token));
      navigate('/addProducts')
    } else if (auth.user_id) {
      dispatch(getAllProductLocations(auth.token));
      navigate('/dashboard');
    }
  }, [auth]); //eslint-disable-line

  useEffect(() => {
    dispatch(resetSignUp())
  }, []) //eslint-disable-line
  
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      color: '#E4E3E3',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
    },
  }));

  return (
    <>
      {dialogState && <ChangeClientDialog title='Select Client' dialogState={dialogState} setDialogState={setDialogState} />}
      <Grid container className='loginBackground'>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex' }}>
          </Grid>
          <Grid item xs={12} sm={12} md={5}
            sx={{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', minHeight: 'calc(100vh)', margin: '0px auto', padding: '0px 80px', display: 'flex', alignItems: 'center' }}>
            <Box>
              <Paper style={{ background: 'none', boxShadow: 'none' }}>
                <Grid item sx={{ display: 'flex', mb: 1.5, justifyContent: 'center' }}>
                  <img src='/assets/Logo-removebg-preview.png' style={{ width: '100%', mb: 1, marginLeft: '-1em', height: '6em', objectFit: 'contain' }} alt='logo' />
                </Grid>
                <Typography variant='h4' component='h4' sx={{ color: '#E4E3E3', fontSize: '27px' }}> Login</Typography>
                <Typography variant='body2' component='span' sx={{ color: '#E4E3E3', fontSize: '11px' }}> Welcome back! Please enter your details</Typography>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={SignInSchema}>
                    {({ errors, setFieldValue, values }) => (
                      <Form className='loginform'>
                        <FormControl variant='standard' sx={{ mt: 1, width: '100%' }}>
                          <InputLabel shrink htmlFor='username' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                            User Email
                          </InputLabel>
                          <BootstrapInput
                            autoComplete='off'
                            value={values.username}
                            placeholder='Enter your user name'
                            id='username'
                            onChange={e => setFieldValue('username', e.target.value)}
                            onKeyUp={(e) => { if (e.key === 'Enter') handleFormSubmit(values) }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>{errors.username}</Typography>
                        </FormControl>
                        <FormControl variant='standard' sx={{ mt: 2, width: '100%' }} >
                          <InputLabel shrink htmlFor='password' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                            Password
                          </InputLabel>
                          <BootstrapInput
                            type='password'
                            value={values.password}
                            placeholder='Enter your password '
                            id='password'
                            onChange={e => setFieldValue('password', e.target.value)}
                            onKeyUp={(e) => { if (e.key === 'Enter') handleFormSubmit(values) }}
                          />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>{errors.password}</Typography>
                        </FormControl>
                        <Grid item xs={12} style={{}}>
                          <Link style={{ color: '#E4E3E3', fontSize: '13px' }} to='/forgotPassword'>Forgot Password?</Link>
                        </Grid>
                        {(error || auth.error) && <Typography variant='body2' sx={{ color: '#f9a61a', fontSize: '11px' }}>The user name and password can be wrong. Check and try again.</Typography>}
                        <FormControl sx={{ m: 0, mt: 3, mb: 2 }}>
                          <Button sx={{
                            backgroundColor: '#559EFF',
                            borderColor: '#1C3B6E',
                            color: '#000000',
                            '&:hover': {
                              backgroundColor: '#559EFF',
                              borderColor: '#1C3B6E',
                              color: '#000000'
                            }
                          }} onClick={() => handleFormSubmit(values)}>
                            <span style={{ fontSize: '13px', textTransform: 'capitalize' }} >{auth.loading ? 'Logging In...' : 'Login'}</span></Button>
                        </FormControl>
                      </Form>)}
                  </Formik>
                  <Typography variant='body2' component={'p'} sx={{ color: '#E4E3E3', fontSize: '11px', marginRight: '80px' }}>
                    Don't have an account?<span style={{cursor:'pointer'}} onClick={()=>navigate('/sign-up')} > Register Now</span></Typography>
                </Grid>
              </Paper>
            </Box>
            <Grid item xs={12} sx={{ display: 'flex', width: '40%', bottom: 20, pt: 1, pb: 1, position: 'fixed', justifyContent: 'center', ml: 0 }}>
              <img src='/assets/orbioLogo.png' alt='orbio logo' style={{ width: 30, marginRight: 8 }} />
              <Typography sx={{ color: '#E4E3E3', fontSize: '13px' }}> Powered By <a href='https://www.orbiosolutions.com/' target='_blank' rel='noreferrer' style={{ color: 'inherit' }}>Orbio Solutions</a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Login;