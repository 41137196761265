import axios from "axios";
import { paths } from '../../Api/paths';
import { EDIT_CLIENT, EDIT_CLIENT_FAILED, EDIT_CLIENT_SUCCESSFULL, RESET_EDIT_CLIENT } from "../constants/editClient.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const editClient = (data) => ({
    type: EDIT_CLIENT,
    payload: data
});

const editClientSuccess = (data) => ({
    type: EDIT_CLIENT_SUCCESSFULL,
    payload: data
});

const editClientFailure = (error) => ({
    type: EDIT_CLIENT_FAILED,
    payload: error
});

export const resetEditClient = () => ({
    type: RESET_EDIT_CLIENT
})

export const editExistingClient = (data, token) => async (dispatch) => {
    try {
      dispatch(editClient(data));
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.clients}${data.refNo}/`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(editClientSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
        const errorMessage = error.response?.data?.detail || "Failed to edit client data"; 
        dispatch(editClientFailure(errorMessage));
      }
    }
};