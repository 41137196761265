import axios from "axios";
import { paths } from '../../Api/paths';
import { TOGGLE_KEY_VALUE, TOGGLE_KEY_VALUE_FAILED, TOGGLE_KEY_VALUE_SUCCESSFULL } from "../constants/toggleKeyValue.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";


const handleToggleKeyValue = () => ({
    type: TOGGLE_KEY_VALUE
})

const toggleKeyValueSuccessfull = (data) => ({
    type: TOGGLE_KEY_VALUE_SUCCESSFULL,
    payload: data
})

const toggleKeyValueFailure = (error) => ({
    type: TOGGLE_KEY_VALUE_FAILED,
    payload: error
})

export const toggleKeyValue = (token, locId) => async (dispatch) => {
    try{
        dispatch(handleToggleKeyValue());
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.keyValue}${locId}`,
        {}, { headers: { Authorization: `bearer ${token}` } })
        if(response.data?.status === 'success'){
            dispatch(toggleKeyValueSuccessfull(response.data));
        }else{
            dispatch(toggleKeyValueFailure(response.data))
        }
    } catch(error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(toggleKeyValueFailure(error));
          }
    }
}