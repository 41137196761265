import React, { useState } from 'react'
import { Button, Dialog, DialogContent, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const CompetitorDetailsDialog = ({ openCompDetailsDialog, setCompDetailsDialog }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const currSymb = useSelector(state => state.auth.currency[0]?.currency_symbol);

  const defaultMarketPlaceId = openCompDetailsDialog.data.marketPlaceId;
  const allCompetitors = openCompDetailsDialog.data?.prd?.competitors;
  const uniqueMktPlaces = Array.from(new Set(openCompDetailsDialog?.data?.prd?.competitors?.map(obj => JSON.stringify({ id: obj.market_places?.market_place_id, name: obj.market_places?.market_place_name })))).map(str => JSON.parse(str));
  const [selectedPlaceId, setSelectedPlaceId] = useState(defaultMarketPlaceId);

  const handleClose = () => {
    setCompDetailsDialog(prev => ({
      ...prev,
      openDialog: false,
      data: null
    }));
  };

  const handlePlaceButtonClick = (placeId) => {
    setSelectedPlaceId(placeId);
  };

  const handleImageError = (event) => {
    event.target.src = '/assets/not_loaded.png';
  };

  const fontColor = themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'

  return (
    <Dialog
      onClose={handleClose}
      open={openCompDetailsDialog?.openDialog}
      PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', minWidth: '450px' } }}
    >
      <DialogContent sx={{ color: fontColor }} className={`custom-scrollbar-${themeMode}`}>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleClose} sx={{ color: fontColor }}>
            <CloseIcon sx={{ cursor: 'pointer', fontSize: '20px' }} />
          </IconButton>
        </Grid>
        <Grid item container xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid item xs={1} >
            <img src={openCompDetailsDialog?.data?.prd?.prd_medium_image ? openCompDetailsDialog?.data?.prd?.prd_medium_image : '/assets/no_image.png'}
              alt={openCompDetailsDialog?.data?.prd?.prd_name} style={{ width: '100%', maxWidth: 55, maxHeight: 70, height: 'auto', left: 0, top: 0, transform: 'none', fontSize: '8px' }} />
          </Grid>
          <Grid item xs={6} >
            <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: '11px', textAlign: 'left' }}>{openCompDetailsDialog?.data?.prd?.prd_name ? openCompDetailsDialog?.data?.prd?.prd_name : '-'}</Typography>
          </Grid>
          <Grid item xs={2} >
            <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: '11px', }}>{currSymb}{openCompDetailsDialog?.data?.prd?.locations?.[0]?.prd_sales_price ? openCompDetailsDialog?.data?.prd?.locations?.[0]?.prd_mrp : '-'}</Typography>
          </Grid>
          <Grid item xs={2} >
            <Typography variant='body2' sx={{ fontSize: '11px', }}>{openCompDetailsDialog?.data?.prd?.locations?.[0]?.prd_norm_price ? (`${currSymb}${(openCompDetailsDialog?.data?.prd?.locations?.[0]?.prd_norm_price)?.toFixed(2)} / ${openCompDetailsDialog.data?.prd?.prd_uom}`) : ('-')}</Typography>
          </Grid>
          <Grid item xs={1} >
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2} sx={{ display: 'flex', gap: '6px' }} >
          {uniqueMktPlaces?.map((place, index) => (
            <Button
              key={index}
              onClick={() => handlePlaceButtonClick(place.id)}
              sx={{
                color: fontColor,
                textTransform: 'capitalize',
                border: 'none',
                fontSize: '11px',
                borderBottom: selectedPlaceId === place.id ? (themeMode === 'dark' ? '2px solid #E4E3E3' : '2px solid #5A5A5A') : 'none',
                borderRadius: '0px',
                backgroundColor: selectedPlaceId === place.id ? themeMode === 'dark' ? '#232D3B' : 'white' : 'transparent',
                '&:hover': {
                  backgroundColor: themeMode === 'dark' ? '#2c384a' : '#f4f4f4',
                }
              }}
            >
              {place.name}
            </Button>
          ))}
        </Grid>
        <Divider sx={{ backgroundColor: fontColor }} />
        <Grid item container xs={12} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {allCompetitors &&
            allCompetitors?.filter(competitor => competitor?.market_places?.market_place_id === selectedPlaceId)?.sort((a, b) => b.comp_prd_score - a.comp_prd_score || a.comp_prd_sales_price - b.comp_prd_sales_price)?.map((competitor, index) => (
              <Grid key={index} container item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                <Grid xs={1} item >
                  <img src={competitor?.comp_prd_medium_image ? competitor?.comp_prd_medium_image : '/assets/no_image.png'}
                    alt={competitor?.comp_prd_name} onError={handleImageError}
                    style={{ width: '100%', maxWidth: 55, maxHeight: 70, height: 'auto', left: 0, top: 0, transform: 'none', fontSize: '8px' }} />
                </Grid>
                <Grid item xs={6} >
                  <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: '11px', textAlign: 'left' }}>{competitor?.comp_prd_name ? competitor?.comp_prd_name : '-'}</Typography>
                </Grid>
                <Grid item xs={2} >
                  <Typography variant='h5' component={'h5'} sx={{ ml: 1, fontSize: '11px' }}>{currSymb}{competitor?.comp_prd_sales_price?.toFixed(2) ? competitor?.comp_prd_sales_price?.toFixed(2) : '-'}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='body2' sx={{ fontSize: '11px' }}>{competitor.comp_prd_norm_price ? (`${currSymb}${competitor.comp_prd_norm_price} / ${competitor.comp_prd_norm_price_uom ? competitor.comp_prd_norm_price_uom : competitor.comp_prd_uom}`) : ('-')}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='body2' sx={{ fontSize: '11px' }}>{competitor?.comp_prd_score ? `${Math.round(competitor?.comp_prd_score)} %` : 'N/A'}</Typography>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CompetitorDetailsDialog;