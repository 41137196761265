import React, { useEffect, useState } from 'react'
import './forgotPassword.css'
import { Box, Button, FormControl, Grid, InputBase, InputLabel, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotUserPassword, resetForgotPassword } from '../../redux/actions/forgotPassword.actions';

const ForgotPassword = () => {

  const dispatch = useDispatch();

  const forgotPasswordData = useSelector(state => state.forgotPasswordDetails);

  const [error, setError] = useState(false);

  const SignInSchema = Yup.object().shape({
    username: Yup.string().min(3, 'Too short - should be 6 chars minimum!').required('Required'),
  })

  const handleFormSubmit = (email, resetForm) => {
    setError(false);
    dispatch(forgotUserPassword(email))
    resetForm()
  }

  useEffect(() => {
    if(forgotPasswordData.data?.message === 'Mail sent successfully'){
      setTimeout(() => {
        dispatch(resetForgotPassword())
      }, 2000);
    }
    if(forgotPasswordData.error?.message){
      setTimeout(() => {
        dispatch(resetForgotPassword())
      }, 2000);
    }
    if(forgotPasswordData.data?.detail === 'User not found'){
      setError(true);
      setTimeout(() => {
        setError(false)
        dispatch(resetForgotPassword())
      }, 2000);
    }
  }, [forgotPasswordData]) //eslint-disable-line
  

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      color: '#E4E3E3',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
    },
  }));

  return (
    <>
      <Grid container className='loginBackground'>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex' }}>
          </Grid>
          <Grid item xs={12} sm={12} md={5}
            sx={{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', minHeight: 'calc(100vh)', margin: '0px auto', padding: '0px 80px', display: 'flex', alignItems: 'center' }}>
            <Box>
              <Paper style={{ background: 'none', boxShadow: 'none' }}>
                <Grid item sx={{ display: 'flex', mb: 1.5, justifyContent: 'center' }}>
                  <img src='/assets/Logo-removebg-preview.png' style={{ width: '100%', mb: 1, marginLeft: '-1em', height: '6em', objectFit: 'contain' }} alt='logo' />
                </Grid>
                <Typography variant='h4' component='h4' sx={{ color: '#E4E3E3', fontSize: '27px' }}> Reset Password</Typography>
                <Typography variant='body2' component='span' sx={{ color: '#E4E3E3', fontSize: '11px' }}> Please enter your email to receive a password reset link.</Typography>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Formik
                    initialValues={{ username: '' }}
                    validationSchema={SignInSchema}>
                    {({ errors, isValid, setFieldValue, values, resetForm }) => (
                      <Form className='loginform'>
                        <FormControl variant='standard' sx={{ mt: 1, width: '100%' }}>
                          <InputLabel shrink htmlFor='username' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                            User Email
                          </InputLabel>
                          <BootstrapInput autoComplete='off' value={values.username}
                            placeholder='Enter your user name' id='username'
                            onChange={e => setFieldValue('username', e.target.value)}
                            onKeyUp={(e) => { if (e.key === 'Enter') handleFormSubmit(values, resetForm) }} />
                          <Typography color='red' variant='subtitle' sx={{ fontSize: '11px' }}>{errors.username}</Typography>
                        </FormControl>

                        <Grid item xs={12}>
                          <Link style={{ color: '#E4E3E3', fontSize: '12px' }} to='/login'>Already have an Account?</Link>
                        </Grid>
                        {(error && forgotPasswordData?.data?.detail === 'User not found') && <Typography variant='body2' sx={{ color: '#f9a61a', fontSize: '11px', mt: 1 }}>Password reset link failed. Check username and try again.</Typography>}
                        {forgotPasswordData.loading && <Typography variant='body2' sx={{ color: '#f9a61a', fontSize: '11px', mt: 1 }}>Sending reset password link.</Typography>}
                        {forgotPasswordData.data?.message === 'Mail sent successfully' && <Typography variant='body2' sx={{ color: '#f9a61a', fontSize: '11px', mt: 1 }}>Check your inbox Your reset link is ready.</Typography>}
                        {forgotPasswordData.error?.message && <Typography variant='body2' sx={{ color: '#f9a61a', fontSize: '11px', mt: 1 }}>Some error occured. Please try again later.</Typography>}
                        <FormControl sx={{ m: 0, mt: 3, mb: 2 }}>
                          <Button disabled={ !values.username || !isValid} sx={{
                            backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000',
                            '&:hover': {
                              backgroundColor: '#559EFF',
                              borderColor: '#1C3B6E',
                              color: '#000000'
                            }
                          }} onClick={() => handleFormSubmit(values, resetForm) }>
                            <span style={{ fontSize: '13px', textTransform: 'capitalize' }} >{'Reset Password'}</span></Button>
                        </FormControl>
                      </Form>)}
                  </Formik>
                  <Typography variant='body2' component={'p'} sx={{ color: '#E4E3E3', fontSize: '11px', marginRight: '80px' }}>
                    Don't have an account? Contact
                    <a style={{ color: '#E4E3E3' }} rel='noreferrer' href={'https://www.orbiosolutions.com/contact-us/'} target='_blank'> Orbio Solutions</a></Typography>
                </Grid>
              </Paper>
            </Box>
            <Grid item xs={12} sx={{ display: 'flex', width: '40%', bottom: 20, pt: 1, pb: 1, position: 'fixed', justifyContent: 'center', ml: 0 }}>
              <img src='/assets/orbioLogo.png' alt='orbio logo' style={{ width: 30, marginRight: 8 }} />
              <Typography sx={{ color: '#E4E3E3', fontSize: '13px' }}> Powered By <a href='https://www.orbiosolutions.com/' target='_blank' rel='noreferrer' style={{ color: 'inherit' }}>Orbio Solutions</a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPassword;