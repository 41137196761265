import axios from "axios";
import { paths } from '../../Api/paths';
import { EDIT_COMPETITOR, EDIT_COMPETITOR_FAILED, EDIT_COMPETITOR_SUCCESSFULL, RESET_EDIT_COMPETITOR } from "../constants/editCompetitor.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const editCompetitor = (data) => ({
    type: EDIT_COMPETITOR,
    payload: data
});

const editCompetitorSuccess = (data) => ({
    type: EDIT_COMPETITOR_SUCCESSFULL,
    payload: data
});

const editCompetitorFailure = (error) => ({
    type: EDIT_COMPETITOR_FAILED,
    payload: error
});

export const resetEditCompetitor = () => ({
    type: RESET_EDIT_COMPETITOR
})

export const handleEditCompetitor = (data, token, queryString) => async (dispatch) => {
    try {
      dispatch(editCompetitor(data));
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.editCompetitor}${queryString}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(editCompetitorSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(editCompetitorFailure(error));
      }
    }
};