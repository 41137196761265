import { Box, Button, Checkbox, Dialog, DialogContent, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';

const EditNotificationDialog = ({ dialogState, setDialogState }) => {

    const themeMode = useSelector(state => state.theme.mode);

    const initialValues = {
        adding_new_users: false,
        adding_products: false,
        deleting_all_products: false,
        engine_completion: false,
        adding_individual_products_and_refresh: false,
        application_updates_or_issues: false,
        tech_support_replies: false,
        price_change_notifications_beyond_a_threshold: false,
        others: false
    }

    const EditEmailSchema = Yup.object().shape({
        adding_new_users: Yup.boolean(),
        adding_products: Yup.boolean(),
        deleting_all_products: Yup.boolean(),
        engine_completion: Yup.boolean(),
        adding_individual_products_and_refresh: Yup.boolean(),
        application_updates_or_issues: Yup.boolean(),
        tech_support_replies: Yup.boolean(),
        price_change_notifications_beyond_a_threshold: Yup.boolean(),
        others: Yup.boolean(),
    });

    const handleClose = () => {
        setDialogState({ ...dialogState, editNotification: false, data: null })
    };

    const handleSubmit = (values) => {
        console.log(values);
    }

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    return (
        <Dialog
            open={dialogState.editNotification}
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}
        >
            <DialogContent sx={fontColor}  className={`custom-scrollbar-${themeMode}`}>
                <Formik enableReinitialize initialValues={initialValues}
                    validationSchema={EditEmailSchema} onSubmit={(values) => handleSubmit(values)}>
                    {({ setFieldValue, values }) => (
                        <Form>
                            <Grid pb={2} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
                                <Box>
                                    <Typography sx={{ fontSize: '11px' }}>Email</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{dialogState?.data.email}</Typography>
                                </Box>
                                <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
                            </Grid>
                            <Grid my={1} py={1} >
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', mb: 2 }} >Get notified for:</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='adding_new_users'
                                        value={values.adding_new_users}
                                        onChange={(e) => setFieldValue('adding_new_users', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding new users</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='adding_products'
                                        value={values.adding_products}
                                        onChange={(e) => setFieldValue('adding_products', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding products</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='deleting_all_products'
                                        value={values.deleting_all_products}
                                        onChange={(e) => setFieldValue('deleting_all_products', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Deleting all products</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='engine_completion'
                                        value={values.engine_completion}
                                        onChange={(e) => setFieldValue('engine_completion', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Engine completion (Running/updating competitors)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='adding_individual_products_and_refresh'
                                        value={values.adding_individual_products_and_refresh}
                                        onChange={(e) => setFieldValue('adding_individual_products_and_refresh', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Adding individual products and refresh</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='application_updates_or_issues'
                                        value={values.application_updates_or_issues}
                                        onChange={(e) => setFieldValue('application_updates_or_issues', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Application updates or issues</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='tech_support_replies'
                                        value={values.tech_support_replies}
                                        onChange={(e) => setFieldValue('tech_support_replies', e.target.checked)}
                                        size='small' sx={fontColor} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Tech support replies</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='price_change_notifications_beyond_a_threshold'
                                        value={values.price_change_notifications_beyond_a_threshold}
                                        onChange={(e) => setFieldValue('price_change_notifications_beyond_a_threshold', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Price change notifications beyond a threshold</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        id='others'
                                        value={values.others}
                                        onChange={(e) => setFieldValue('others', e.target.checked)}
                                        size='small' sx={{...fontColor,
                                            '& .MuiSvgIcon-root': { 
                                                fontSize: '18px'
                                            },
                                        }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} >Others</Typography>
                                </Box>
                            </Grid>
                            <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '', mb: 1 }} />
                            <Grid sx={{display: 'flex', gap: '5px', justifyContent: 'flex-end'}} >
                                <Button onClick={() => handleClose()} variant='outlined' sx={{ textTransform: 'none', ...fontColor }}>Cancel</Button>
                                <Button type='submit' variant='contained' sx={{ textTransform: 'none', mr: 1 }}>Update</Button>
                            </Grid>
                        </Form>)}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default EditNotificationDialog;