import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_DEPARTMENTS, FETCH_DEPARTMENTS_FAILED, FETCH_DEPARTMENTS_SUCCESSFULL } from "../constants/departments.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getDepartments = () => ({
    type: FETCH_DEPARTMENTS
});

const getDepartmentsSuccess = (data) => ({
    type: FETCH_DEPARTMENTS_SUCCESSFULL,
    payload: data
});

const getDepartmentsFailure = (error) => ({
    type: FETCH_DEPARTMENTS_FAILED,
    payload: error
});

export const fetchDepartments = (token, keyword, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getDepartments());
      const endpoint = qa_user ? `${paths.qaDepartments}?company_id=${company_id}` : `${paths.departments}?type=${keyword}`;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getDepartmentsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getDepartmentsFailure(error));
      }
    }
};