import { APPLY_FILTER_OPTIONS, CHANGE_LOCATION, CHANGE_MARKET_PLACE, CHANGE_PAGE_NUMBER, CHANGE_PAGE_SIZE, CHANGE_SEARCH_KEYWORD, CLEAR_SPECIFIC_FILTER, RESET_FILTER, RESET_STRING, CHANGE_PRD_REMARKS } from "../constants/productString.constants";

export const setLocation = (location) => ({
    type: CHANGE_LOCATION,
    payload: location
})

export const applyFilterOptions = (data) => ({
    type: APPLY_FILTER_OPTIONS,
    payload: data
});

export const changePageNumber = (pageNumber) => ({
    type: CHANGE_PAGE_NUMBER,
    payload: pageNumber
})

export const changePageSize = (pageSize) => ({
    type: CHANGE_PAGE_SIZE,
    payload: pageSize
})

export const changeSearchInput = (keyword) => ({
    type: CHANGE_SEARCH_KEYWORD,
    payload: keyword
})

export const clearSpecificFilter = (filter) => ({
    type: CLEAR_SPECIFIC_FILTER,
    payload: filter
})
export const resetFilter = (defaultLocation,prd_remarks_value) => {
    return {
        type: RESET_FILTER,
        payload: {
            defaultLocation: defaultLocation,
            prdRemarksValue: prd_remarks_value
        }
    };
};


export const changeMarketPlace = (data) => ({
    type: CHANGE_MARKET_PLACE,
    payload: data
})
export const changePrdRemarks = (id) => ({
    type: CHANGE_PRD_REMARKS,
    payload: id
})

export const resetString = () => ({
    type: RESET_STRING
})