import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import './marketplace.css'
import DonutChart from '../Charts/DonutChart'
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';
import moment from 'moment';

const MarketPlace = ({ marketPlaceData }) => {

    const themeMode = useSelector(state => state.theme.mode);

    const handleOpenUrl = (url) => {
        url = url.indexOf('http') !== -1 ? url : `https://${url}`
        window.open(url, '_blank')
    }

    return (
        <Paper sx={{
            height: '210px', width: '100%', background: themeMode === 'dark' ? '#232D3B' : 'white',
            color: themeMode === 'dark' ? 'white' : 'black', boxShadow: 'none', display: 'flex',
            justifyContent: 'center', alignItems: 'center', marginBottom: 3
        }} >
            <Grid container item xs={12} >
                <Grid item container xs={4} >
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <div style={{ marginTop: '2.2em' }}>
                            <img src={marketPlaceData.logo} alt='logo' className='market-logo' />
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <div>
                            <Typography
                                onClick={marketPlaceData.url ? e => { handleOpenUrl(marketPlaceData.url) } : null}
                                className='productDetails hand-symbol'
                                sx={{ textAlign: 'left' }}>{marketPlaceData.name}</Typography>
                            <div style={{ marginTop: '1.2em' }}>
                                <Typography
                                    className='typo fontweight-500'
                                    sx={{ textAlign: 'left !important' }}>
                                    Matched Products: {marketPlaceData.Less + marketPlaceData.more + marketPlaceData.Equal}
                                </Typography>
                                <Typography
                                    onClick={marketPlaceData.url ? e => { handleOpenUrl(marketPlaceData.url) } : null}
                                    className='typo anchor hand-symbol'
                                    sx={{ textAlign: 'left !important', textDecoration: 'underline' }}>Visit Website
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', }} >
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Table style={{ width: '300px' }} >
                            <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1a222d' : '#eaecee' }}>
                                <TableRow>
                                    <TableCell sx={{ padding: '8px 16px' }}></TableCell>
                                    <TableCell align='center' sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText'>{'Increased'}</TableCell>
                                    <TableCell align='center' sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText'>{'Decreased'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' component='th' scope='row'>Today</TableCell>
                                    <TableCell sx={{ padding: '8px 16px', textAlign: 'center', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' >{marketPlaceData.increased_today}</TableCell>
                                    <TableCell sx={{ padding: '8px 16px', textAlign: 'center', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' >{marketPlaceData.decreased_today}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' component='th' scope='row'>This Week</TableCell>
                                    <TableCell sx={{ padding: '8px 16px', textAlign: 'center', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' >{marketPlaceData.increased_week}</TableCell>
                                    <TableCell sx={{ padding: '8px 16px', textAlign: 'center', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText' >{marketPlaceData.decreased_week}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid item container xs={4} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }} >
                    <DonutChart less={marketPlaceData.Less} more={marketPlaceData.more} equal={marketPlaceData.Equal} />
                    <Table style={{ width: '220px' }} >
                        <TableHead sx={{ backgroundColor: themeMode === 'light' ? '#eaecee' : '#1a222d' }}>
                            <TableRow>
                                <TableCell sx={{ padding: '8px' }} className='tableText smallText'></TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText smallText'>{'Total Products'}</TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText normalText'>{marketPlaceData.Less + marketPlaceData.more + marketPlaceData.Equal}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ padding: '8px' }} component='th' scope='row'><CircleIcon sx={{ fontSize: '1.5em', color: '#53D28C' }} /></TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText smallText' >Lower Price</TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText normalText' >{marketPlaceData.Less}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '8px' }} component='th' scope='row'><CircleIcon sx={{ fontSize: '1.5em', color: '#FF6767' }} /></TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText smallText' >Higher Price</TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText normalText' >{marketPlaceData.more}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '8px' }} component='th' scope='row'><CircleIcon sx={{ fontSize: '1.5em', color: '#dac934' }} /></TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText smallText' >Equal Price</TableCell>
                                <TableCell sx={{ padding: '8px 16px', color: themeMode === 'dark' ? 'white' : 'black' }} className='tableText normalText' >{marketPlaceData.Equal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Typography variant='p' component={'p'} className={'footer-text'} ml={4} >
                    <span style={{ opacity: 0.7, fontStyle: 'italic' }}>Last Updated on </span>
                    {moment(marketPlaceData.LastUpdated).format('MM DD YYYY, h:mm:ss a')}
                </Typography>
            </Grid>
        </Paper>
    )
}

export default MarketPlace;