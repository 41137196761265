import axios from "axios";
import { paths } from '../../Api/paths';
import { UPDATE_PRODUCT,RESET_UPDATE_PRODUCT,UPDATE_PRODUCT_FAILED,UPDATE_PRODUCT_SUCCESSFULL } from "../constants/updateProduct.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const handleUpdateProduct = () => ({
    type: UPDATE_PRODUCT
});

const updateProductSuccess = (data) => ({
    type: UPDATE_PRODUCT_SUCCESSFULL,
    payload: data
});

const updateProductFailure = (error) => ({
    type: UPDATE_PRODUCT_FAILED,
    payload: error
});

export const resetUpdateProduct = () => ({
    type: RESET_UPDATE_PRODUCT
})

export const updateProduct = (token, data) => async (dispatch) => {
    try {
      dispatch(handleUpdateProduct());
      const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.myProducts}${data.prd_id}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(updateProductSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
            dispatch(updateProductFailure(error));
          }
    }
};