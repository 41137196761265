import { Button, Dialog, DialogContent, FormControl, Grid, InputBase, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { editProductDetails, resetEditProduct } from '../../redux/actions/editProduct.actions';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import { useParams } from 'react-router-dom';

const ModifyKeywordDialog = ({ dialogState, setDialogState, productData, keywordArray }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const company_id = useSelector(state => state.auth.company_id);
    const qa_user = useSelector(state => state.auth.qa_user);
    const token = useSelector(state => state.auth.token);
    const editProduct = useSelector(state => state.editProduct);

    const { product_id } = useParams();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false)
    const [serverMsg, setServerMsg] = useState('');

    const handleClose = () => {
        const tempState = { ...dialogState, modifyKeyword: false }
        setDialogState(tempState);
    }

    const initialValues = keywordArray.reduce((acc, curr) => {
        const key = Object.keys(curr)[0];
        acc[key] = curr[key];
        return acc;
    }, {});

    const validationSchema = Yup.object().shape(
        keywordArray.reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = Yup.string().required('Keyword is required');
            return acc;
        }, {})
    );

    const handleSubmit = (values) => {
        setServerMsg('');
        setSubmitting(true);
        const formattedKeywords = Object.entries(values).map(([key, value]) => ({ [key]: value }));
        const payload = { ...productData, ...productData.locations[0], keyword: JSON.stringify(formattedKeywords) };
        dispatch(editProductDetails(productData?.prd_id, payload, token, qa_user, company_id))
    }

    useEffect(() => {
        if (editProduct.success) {
            handleClose();
            setSubmitting(false);
            dispatch(resetEditProduct());
        } else if (editProduct.error) {
            setServerMsg('Failed to edit keywords');
            setSubmitting(false);
        }
    }, [editProduct]) //eslint-disable-line

    useEffect(() => {
        if (product_id && editProduct.success) {
            dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
        }
    }, [editProduct]) //eslint-disable-line

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            width: '100%',
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '10px 12px',
            ...fontColor,
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        },
    }));

    return (
        <Dialog open={dialogState.modifyKeyword} PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }}>
            <DialogContent>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ ...fontColor, fontWeight: 'bold' }}>Edit Keyword</Typography>
                    <CloseIcon onClick={handleClose} sx={{ ...fontColor, cursor: 'pointer', fontSize: '20px' }} />
                </Grid>
                {submitting ? <Typography sx={{ ...fontColor, my: 4, textAlign: 'center' }}>Submitting...</Typography> :
                    <Grid mt={1} >
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => handleSubmit(values)}>
                            {({ errors, handleChange, values }) => (
                                <Form>
                                    {Object.entries(values).map(([key, value]) => (
                                        <FormControl key={key} variant='standard' style={{ width: '100%' }}>
                                            <Typography sx={{ ...fontColor, fontSize: '13px', mt: 2, textTransform: 'capitalize' }}>{key}</Typography>
                                            <BootstrapInput
                                                value={value}
                                                placeholder='Enter keyword'
                                                id={key}
                                                onChange={handleChange}
                                            />
                                            {errors[key] && <Typography color='red' variant='subtitle' sx={{ width: '70%', fontSize: '12px' }}>{errors[key]}</Typography>}
                                        </FormControl>))}
                                    <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                                    <Grid sx={{ textAlign: 'end', mt: 2 }}>
                                        <Button type='submit' size='small' variant='contained' sx={{ textTransform: 'none' }}>Update</Button>
                                    </Grid>
                                </Form>)}
                        </Formik>
                    </Grid>}
            </DialogContent>
        </Dialog>
    )
}

export default ModifyKeywordDialog;