import { Box, Chip, Grid, IconButton, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddNewEmail from '../AddNewEmail';
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from '../InfoDialog';
import EditNotificationDialog from '../EditNotificationDialog';
import DeleteDialog from '../DeleteDialog';

const NotificationSettings = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const userRole = useSelector(state => state.auth.role);
  const users = useSelector(state => state.users);

  let dummyData = users.data?.filter((obj) => obj.roles?.[0]?.role.role_id === 3 ? obj : null);

  const [selected, setSelected] = useState(0);
  const [dialogState, setDialogState] = useState({ notificationInfo: false, editNotification: false, deleteNotification: false, data: null });

  const handleChangeTab = (event, newValue) => {
    setSelected(newValue);
  }

  const openDialog = (type, user) => {
    const tempState = { ...dialogState, [type]: true, data: user }
    setDialogState(tempState);
  }

  const tabColor = {
    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
    '&.Mui-selected': { color: '#1976d2' }, textTransform: 'none', fontWeight: 'bold'
  }

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

  return (
    <>
      {dialogState.notificationInfo && <InfoDialog
        dialogState={dialogState} setDialogState={setDialogState} dialogFor='notificationInfo' />}
      {dialogState.editNotification && <EditNotificationDialog
        dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.deleteNotification && <DeleteDialog
        dialogState={dialogState} setDialogState={setDialogState} dialogFor='deleteNotification' />}
      <Grid p={2} mt={4} sx={{ backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', borderRadius: '10px' }}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid ml={1}>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Notifications</Typography>
            <Typography sx={{ fontSize: '12px' }}>Turn on notifications to get the latest updates on the products</Typography>
          </Grid>
          <Switch id='notification' sx={{ marginRight: 3 }} />
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: themeMode === 'light' ? 'divider' : 'gray' }}>
          <Tabs value={selected} onChange={handleChangeTab}>
            <Tab label='Enabled Emails' sx={tabColor} />
            <Tab label='Add New Emails' sx={tabColor} />
          </Tabs>
        </Box>
        {selected === 0 ? <TableContainer sx={{ mt: 2 }}>
          <Table sx={{ '& .MuiTableCell-root': fontColor }}>
            <TableHead sx={{ backgroundColor: themeMode === 'dark' ? '#1A222D' : '#FDF8E5' }}>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Notifications</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userRole === 3 && dummyData?.map((user,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell sx={{p: '7px 0px 7px 16px', fontSize: '13px'}} >{index+1}</TableCell>
                    <TableCell sx={{p: '7px 0px 7px 16px', fontSize: '13px'}} >{user.email}</TableCell>
                    <TableCell sx={{p: '7px 0px 7px 16px'}} >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {user.roles?.[0]?.role?.role_id === 3 ? <Chip label='All Notifications Enabled' color='primary' style={{fontSize: '11px'}} /> :
                          user.enabled === 'few' ? <Chip label='Few Notifications Enabled' color='warning' style={{fontSize: '11px'}} /> :
                            <Chip label='No Notifications Enabled' color='error' style={{ fontSize: '11px' }} />}
                        <InfoIcon style={{ cursor: 'pointer', color: '#1976d2', marginLeft: '5px', fontSize: '20px' }}
                          onClick={() => openDialog('notificationInfo', user)} />
                      </Box>
                    </TableCell>
                    <TableCell sx={{p: '7px 0px 7px 16px'}} >
                      <IconButton onClick={() => openDialog('deleteNotification', user)} >
                        <DeleteOutlineOutlinedIcon style={{...fontColor, fontSize: '20px'}} />
                      </IconButton>
                      <IconButton onClick={() => openDialog('editNotification', user)} >
                        <EditNoteIcon style={{...fontColor, fontSize: '20px'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer> :
          <AddNewEmail />}
      </Grid>
    </>
  )
};

export default NotificationSettings;