import axios from "axios";
import { paths } from "../../Api/paths";
import { EDIT_PRODUCT, EDIT_PRODUCT_FAILED, EDIT_PRODUCT_SUCCESSFULL, RESET_EDIT_PRODUCT } from "../constants/editProduct.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const handleEditProduct = () => ({
    type: EDIT_PRODUCT
});

const editProductSuccessful = (data) => ({
    type: EDIT_PRODUCT_SUCCESSFULL,
    payload: data
});

const editProductFailed = (error) => ({
    type: EDIT_PRODUCT_FAILED,
    payload: error
});

export const resetEditProduct = () => ({
    type: RESET_EDIT_PRODUCT
});

export const editProductDetails = (prdId, data, token, qa_user, company_id) => async (dispatch) => {
    try{
        dispatch(handleEditProduct());
        const endpoint = qa_user ? `${paths.qaMyProducts}${prdId}?company_id=${company_id}` : `${paths.myProducts}${prdId}`;
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${endpoint}`,
        data, { headers: { Authorization: `bearer ${token}` } })
        dispatch(editProductSuccessful(response.data));
    }catch(error){
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(editProductFailed(error));
          }
    }
}