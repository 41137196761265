import { Button, Dialog, DialogContent, Grid, InputBase, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { handleEditCompetitor, resetEditCompetitor } from '../../redux/actions/editCompetitor.actions';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import { useParams } from 'react-router-dom';

const EditCompetitorDialog = ({ dialogState, setDialogState, data }) => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const company_id = useSelector(state => state.auth.company_id);
    const qa_user = useSelector(state => state.auth.qa_user);
    const editCompetitor = useSelector(state => state.editCompetitor);

    const { product_id } = useParams();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const initialValues = {
        comp_prd_name: data.comp_prd_name || '',
        comp_prd_desc: data.comp_prd_desc || '',
        comp_category_name: data.comp_category_name || '',
        comp_dept_name: data.comp_dept_name || '',
        comp_prd_medium_image: data.comp_prd_medium_image || '',
        comp_prd_brand: data.comp_prd_brand || '',
        comp_prd_asin: data.comp_prd_asin || '',
        comp_prd_upc: data.comp_prd_upc || '',
        comp_prd_no_of_pieces: data.comp_prd_no_of_pieces || '',
        comp_prd_size: data.comp_prd_size || '',
        comp_prd_url: data.comp_prd_url || '',
        comp_prd_uom: data.comp_prd_uom || '',
        comp_prd_spec: data.comp_prd_spec || '',
        comp_prd_mrp: data.comp_prd_mrp || '',
        comp_prd_sales_price: data.comp_prd_sales_price || '',
        comp_prd_score: data.comp_prd_score || ''
    }

    const EditCompSchema = Yup.object().shape({
        comp_prd_name: Yup.string(),
        comp_prd_desc: Yup.string(),
        comp_category_name: Yup.string(),
        comp_dept_name: Yup.string(),
        comp_prd_medium_image: Yup.string(),
        comp_prd_brand: Yup.string(),
        comp_prd_asin: Yup.string(),
        comp_prd_upc: Yup.string(),
        comp_prd_no_of_pieces: Yup.number()
            .typeError('Number of pieces must be a valid number')
            .required('Number of pieces is required')
            .positive('Number of pieces must be positive'),
        comp_prd_size: Yup.string(),
        comp_prd_url: Yup.string().required('URL is required'),
        comp_prd_uom: Yup.string(),
        comp_prd_spec: Yup.string(),
        comp_prd_mrp: Yup.number().typeError('MRP must be a number'),
        comp_prd_sales_price: Yup.number().typeError('Sale Price must be a number'),
        comp_prd_score: Yup.number().typeError('Match rate must be a number')
            .min(1, 'Cutoff Score must be at least 1').max(100, 'Cutoff Score must be at most 100')
    })

    const handleClose = () => {
        const tempState = { ...dialogState, editComp: false };
        setDialogState(tempState);
        setServerMsg('');
        setSubmitting(false);
        dispatch(resetEditCompetitor());
    }

    const handleSubmit = (values) => {
        const queryString = `${data.comp_prd_id}?company_id=${company_id}`
        dispatch(handleEditCompetitor(values, token, queryString))
    }

    useEffect(() => {
        if (editCompetitor.success) {
            handleClose();
            setSubmitting(false);
            dispatch(resetEditCompetitor());
            dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
        } else if (editCompetitor.error) {
            setServerMsg('Failed to edit competitor');
            setSubmitting(false);
        }
    }, [editCompetitor]) //eslint-disable-line

    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: 'transparent',
            border: '1px solid #ced4da',
            fontSize: 11,
            width: '100%',
            padding: '9px 12px',
            textTransform: 'capitalize',
            ...fontColor,
            transition: theme.transitions?.create([
                'border-color',
                'background-color',
            ]),
            fontFamily: 'Inter',
            '&:focus': {
                borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
            },
        }
    }));

    return (
        <Dialog
            open={dialogState.editComp}
            onClose={handleClose}
            maxWidth='md'
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%' } }} >
            <DialogContent className={`custom-scrollbar-${themeMode}`} sx={fontColor} >
                {submitting ? <Typography sx={{ m: 5, fontSize: '16px', fontWeight: 'bold' }}>Submitting...</Typography> :
                    <Formik enableReinitialize initialValues={initialValues}
                        validationSchema={EditCompSchema} onSubmit={(values) => handleSubmit(values)}>
                        {({ errors, setFieldValue, values }) => (
                            <Form>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Edit Competitor</Typography>
                                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Product name</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_name'
                                            value={values.comp_prd_name}
                                            onChange={(e) => setFieldValue('comp_prd_name', e.target.value)}
                                            placeholder='Enter Product Name'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Product description</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_desc'
                                            value={values.comp_prd_desc}
                                            onChange={(e) => setFieldValue('comp_prd_desc', e.target.value)}
                                            placeholder='Enter Product Description'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Category</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_category_name'
                                            value={values.comp_category_name}
                                            onChange={(e) => setFieldValue('comp_category_name', e.target.value)}
                                            placeholder='Enter Category'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Department</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_dept_name'
                                            value={values.comp_dept_name}
                                            onChange={(e) => setFieldValue('comp_dept_name', e.target.value)}
                                            placeholder='Enter Department'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Product Image</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_medium_image'
                                            value={values.comp_prd_medium_image}
                                            onChange={(e) => setFieldValue('comp_prd_medium_image', e.target.value)}
                                            placeholder='Enter Product Image'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Brand</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_brand'
                                            value={values.comp_prd_brand}
                                            onChange={(e) => setFieldValue('comp_prd_brand', e.target.value)}
                                            placeholder='Enter Brand'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>ASIN</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_asin'
                                            value={values.comp_prd_asin}
                                            onChange={(e) => setFieldValue('comp_prd_asin', e.target.value)}
                                            placeholder='Enter ASIN'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>UPC</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_upc'
                                            value={values.comp_prd_upc}
                                            onChange={(e) => setFieldValue('comp_prd_upc', e.target.value)}
                                            placeholder='Enter UPC'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Number of pieces</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_no_of_pieces'
                                            value={values.comp_prd_no_of_pieces}
                                            onChange={(e) => setFieldValue('comp_prd_no_of_pieces', e.target.value)}
                                            placeholder='Enter Number of pieces'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography color='red' variant='subtitle' sx={{ fontSize: '11px', fontWeight: themeMode === 'dark' ? 'bold' : 'normal' }}>
                                            {errors.comp_prd_no_of_pieces}</Typography>
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Size</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_size'
                                            value={values.comp_prd_size}
                                            onChange={(e) => setFieldValue('comp_prd_size', e.target.value)}
                                            placeholder='Enter Size'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>URL *</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_url'
                                            value={values.comp_prd_url}
                                            onChange={(e) => setFieldValue('comp_prd_url', e.target.value)}
                                            placeholder='Enter URL'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography color='red' variant='subtitle' sx={{ fontSize: '11px', fontWeight: themeMode === 'dark' ? 'bold' : 'normal' }}>
                                            {errors.comp_prd_url}</Typography>
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Unit of Measurement</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_uom'
                                            value={values.comp_prd_uom}
                                            onChange={(e) => setFieldValue('comp_prd_uom', e.target.value)}
                                            placeholder='Enter Unit of measurement'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>SPEC</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_spec'
                                            value={values.comp_prd_spec}
                                            onChange={(e) => setFieldValue('comp_prd_spec', e.target.value)}
                                            placeholder='Enter SPEC'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>MRP</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_mrp'
                                            value={values.comp_prd_mrp}
                                            onChange={(e) => setFieldValue('comp_prd_mrp', e.target.value)}
                                            placeholder='Enter MRP'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography color='red' variant='subtitle' sx={{ fontSize: '11px', fontWeight: themeMode === 'dark' ? 'bold' : 'normal' }}>
                                            {errors.prd_mrp}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex' }}>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Sale Price</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_sales_price'
                                            value={values.comp_prd_sales_price}
                                            onChange={(e) => setFieldValue('comp_prd_sales_price', e.target.value)}
                                            placeholder='Enter Sale Price'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography color='red' variant='subtitle' sx={{ fontSize: '11px', fontWeight: themeMode === 'dark' ? 'bold' : 'normal' }}>
                                            {errors.comp_prd_sales_price}</Typography>
                                    </Grid>
                                    <Grid sx={{ width: '50%', p: 1 }}>
                                        <Typography sx={{ fontSize: '12px' }}>Match Rate</Typography>
                                        <Field
                                            component={BootstrapInput}
                                            sx={{ width: '100%', mt: 1 }}
                                            id='comp_prd_score'
                                            value={values.comp_prd_score}
                                            onChange={(e) => setFieldValue('comp_prd_score', e.target.value)}
                                            placeholder='Enter Match rate'
                                            inputProps={{
                                                style: { textTransform: 'none' }
                                            }}
                                        />
                                        <Typography color='red' variant='subtitle' sx={{ fontSize: '11px', fontWeight: themeMode === 'dark' ? 'bold' : 'normal' }}>
                                            {errors.comp_prd_score}</Typography>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ ml: 'auto', fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                                <Button type='submit' variant='contained' sx={{ width: '50%', ml: '25%', mt: 1, textTransform: 'none' }}
                                    disabled={Boolean(Object.values(errors).find((value) => value !== '' && value !== null))} >Update</Button>
                            </Form>)}
                    </Formik>}
            </DialogContent>
        </Dialog>
    )
}

export default EditCompetitorDialog;