import { FETCH_GROUPS, FETCH_GROUPS_FAILED, FETCH_GROUPS_SUCCESSFULL } from "../constants/groups.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";


const initialState = {
    loading: false,
    data: null,
    error: null,
};

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUPS:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_GROUPS_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case FETCH_GROUPS_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default groupsReducer;