import axios from 'axios';
import { paths } from '../../Api/paths';
import { handleTokenExpiration } from './handleTokenExpiration.actions';
import { FETCH_COMP_PRD_SETTINGS_LOC,FETCH_COMP_PRD_SETTINGS_LOC_FAILED,FETCH_COMP_PRD_SETTINGS_LOC_SUCCESSFULL } from '../constants/compPrdLocSettings.constants';

const getCompPrdSettingsLocations = () => ({
    type: FETCH_COMP_PRD_SETTINGS_LOC,
});

const getCompPrdSettingsLocationsSuccessfull = (data) => ({
    type: FETCH_COMP_PRD_SETTINGS_LOC_SUCCESSFULL,
    payload: data
});

const getCompPrdSettingsLocationsFailed = (error) => ({
    type: FETCH_COMP_PRD_SETTINGS_LOC_FAILED,
    payload: error
});

export const fetchCompPrdSettingsLocations = (token, qa_user, company_id, prd_id) => async (dispatch) => {
    try {
        dispatch(getCompPrdSettingsLocations());
        const endpoint = qa_user ? `${paths.qaCompPrdLocSettings}?company_id=${company_id}&prd_id=${prd_id}` : `${paths.compPrdLocSettings}?prd_id=${prd_id}`;
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
        { headers: { Authorization: `bearer ${token}` } })
        dispatch(getCompPrdSettingsLocationsSuccessfull(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getCompPrdSettingsLocationsFailed(error));
          }
    }
};