import axios from "axios";
import { paths } from "../../Api/paths";
import { FETCH_CLIENTS_DATA, FETCH_CLIENTS_DATA_FAILED, FETCH_CLIENTS_DATA_SUCCESSFULL } from "../constants/clients.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getClientsData = () => ({
    type: FETCH_CLIENTS_DATA
});

const getClientsDataSuccessfull = (data) => ({
    type: FETCH_CLIENTS_DATA_SUCCESSFULL,
    payload: data
});

const getClientsDataFailed = (error) => ({
    type: FETCH_CLIENTS_DATA_FAILED,
    payload: error
});

export const fetchClientsData = (token) => async (dispatch) => {
    try{
        dispatch(getClientsData());
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${paths.clients}`,
        { headers: { Authorization: `bearer ${token}` } });
        dispatch(getClientsDataSuccessfull(response.data));
    }catch (error){
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getClientsDataFailed(error));
          }
    }
}