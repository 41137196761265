import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import moment from 'moment';

const InfoDialog = ({ dialogState, setDialogState, dialogFor }) => {

    const themeMode = useSelector(state => state.theme.mode);

    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const handleClose = () => {
        setDialogState({ ...dialogState, [dialogFor]: false, data: null })
    };

    const utcToLocalTime = (times) => {
        const utcTimeInArray = times.slice(1, -1).split(', ');
        return utcTimeInArray.map(time => moment.utc(time, 'HH:mm:ss').local().format('h:mm A'))
    }

    const binaryStringToWeekdays = (binaryString) => {
        const selectedDays = [];
        for (let i = 0; i < binaryString.length; i++) {
            if (binaryString[i] === '1') {
                selectedDays.push(weekDays[i]);
            }
        }
        return selectedDays;
    };

    const dummyData = ['Adding Products', 'Deleting all products', 'Adding Individual Products and Refresh', 'Tech Support Replies']
    const adminPermissions = ['Manage Users'];
    const userPermissions = ['View Products', 'Edit Products', 'Schedule Products']
    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
    const dialogInfo = { fontSize: '12px', fontWeight: 'bold', mb: 1 }

    return (
        <Dialog onClose={handleClose}
            open={dialogState.notificationInfo || dialogState.userRoleInfo || dialogState.groupRuleInfo}
            PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '100%'}}}
        >
            <DialogContent sx={fontColor}>
                <Grid pb={2} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: themeMode === 'light' ? 'divider' : 'gray' }}>
                    {dialogFor === 'notificationInfo' && <Box>
                        <Typography sx={{ fontSize: '11px' }}>Email</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{dialogState?.data?.email}</Typography>
                    </Box>}
                    {dialogFor === 'userRoleInfo' && <Typography sx={{ textTransform: 'capitalize' }} >{dialogState?.data?.first_name} {dialogState?.data?.last_name}</Typography>}
                    {dialogFor === 'groupRuleInfo' && <Box>
                        <Typography sx={{ fontSize: '11px' }}>Rule name</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{dialogState.data?.settings[0]?.rule_name}</Typography></Box>}
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
                </Grid>
                <Grid mt={2}>
                    {dialogFor === 'notificationInfo' && <Typography sx={dialogInfo}>This mail id will get notified for:</Typography>}
                    {dialogFor === 'userRoleInfo' && <Typography sx={dialogInfo}><span style={{ textTransform: 'capitalize' }} >{`${dialogState?.data?.roles[0]?.role?.role_name}`}</span> have following permissions</Typography>}
                    {dialogFor === 'notificationInfo' && dummyData.map((item, index) => {
                        return (
                            <Box key={index} sx={{ display: 'flex', mt: '6px', alignItems: 'center' }}>
                                <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
                                <Typography sx={{ fontSize: '14px' }}>{item}</Typography>
                            </Box>
                        )
                    })}
                    {dialogFor === 'groupRuleInfo' && <Grid sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold' }}>Group name</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                <Typography sx={{ fontSize: '11px' }}>{dialogState.data?.group_name}</Typography>
                            </Box>
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold', mt: 2 }}>Cut off score</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                <Typography sx={{ fontSize: '11px' }}>{dialogState.data?.settings[0]?.cuttoff_score}</Typography>
                            </Box>
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold', mt: 2 }}>Time</Typography>
                            {utcToLocalTime(dialogState.data?.settings[0]?.crawl_time).map((time, index) =>
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                    <Typography sx={{ fontSize: '11px' }}>{time}</Typography>
                                </Box>)}
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold', mt: 2 }}>Repeated on</Typography>
                            {binaryStringToWeekdays(dialogState.data?.settings[0]?.weekday).map((day, index) =>
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                    <Typography sx={{ fontSize: '11px' }}>{day}</Typography>
                                </Box>)}
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold' }}>Market places</Typography>
                            {dialogState.data?.settings[0]?.market_places?.map(mkt => <Box key={mkt.market_places?.market_place_id}
                                sx={{ display: 'flex', alignItems: 'center' }}><KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                <Typography sx={{ fontSize: '11px' }}>{mkt.market_places?.market_place_name}</Typography>
                            </Box>)}
                            <Typography sx={{ fontSize: '11px', fontWeight: 'bold', mt: 2 }}>Locations</Typography>
                            {dialogState.data?.settings[0]?.locations.map(loc => <Box key={loc.loc_id}
                                sx={{ display: 'flex', alignItems: 'center' }}><KeyboardDoubleArrowRightOutlinedIcon color='primary' />
                                <Typography sx={{ fontSize: '11px' }}>{loc?.loc?.loc_name}</Typography>
                            </Box>)}
                        </Box>
                    </Grid>}
                    {dialogFor === 'userRoleInfo' && dialogState?.data?.roles[0]?.role?.role_name === 'user' ?
                        <Box>
                            {userPermissions.map((perm, index) => <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
                                <Typography sx={{ fontSize: '11px' }}>{perm}</Typography>
                            </Box>)}
                        </Box> : dialogFor === 'userRoleInfo' && <Box>
                            {adminPermissions.map((perm, index) => <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
                                <Typography sx={{ fontSize: '11px' }}>{perm}</Typography>
                            </Box>)}
                        </Box>}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default InfoDialog;