import { REFRESH_BTN, REFRESH_BTN_FAILED, REFRESH_BTN_SUCCESSFULL, RESET_REFRESH_BTN } from "../constants/refreshBtnStatus.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const refreshBtnStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case REFRESH_BTN:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case REFRESH_BTN_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case REFRESH_BTN_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_REFRESH_BTN:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default refreshBtnStatusReducer;