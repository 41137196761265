import { Box, Card, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './locationDetails.css'
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { fetchDashboardLocation } from '../../redux/actions/dashboardLocation.actions';
import SpinLoader from '../SpinLoader';

const LocationDetails = () => {

  const dispatch = useDispatch()
  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const qa_user = useSelector(state => state.auth.qa_user);
  const company_id = useSelector(state => state.auth.company_id);
  const allLocations = useSelector(state => state.productLocations.data);
  const dashboardLocations = useSelector(state => state.dashboardlocationDetails);

  const [location, setLocation] = useState({ loc_id: 'all', loc_name: 'All' });

  const handleChange = (event) => {
    const selectedLocation = event.target.value;
    setLocation(selectedLocation);
    if(selectedLocation){
      dispatch(fetchDashboardLocation(token, qa_user, company_id, selectedLocation.loc_id))
    }
  };

  useEffect(() => {
    dispatch(getAllProductLocations(token, qa_user, company_id))
    dispatch(fetchDashboardLocation(token, qa_user, company_id, 'all'))
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' };

  return (
    <Card sx={{
      boxShadow: 'none', height: '180px', background: themeMode === 'dark' ? '#232D3B' : 'white',
      color: themeMode === 'dark' ? 'white' : 'black', position: 'relative'
    }}>

        {dashboardLocations?.loading && (
          <Box sx={{
            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
            display: 'flex',justifyContent: 'center',alignItems: 'center',
          }}>
            <SpinLoader />
          </Box>
        )}
        <Grid container direction='column' justifyContent='space-between' style={{ height: '100%' }}>
        <Grid item container style={{ height: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={6} sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}} >
          <p style={{ fontSize: '12px', textAlign: 'center' }}>Locations</p>
          </Grid>
          <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}} >
          <Select
            inputProps={{ name: 'limitSelect' }}  id='limitSelect'  size='small'
            MenuProps={{
              PaperProps: {
                className: `custom-scrollbar-${themeMode}`,
              },
              MenuListProps: {
                sx: {
                  backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                  color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                  pt: '0px',
                  pb: '0px',
                },
              },
            }}
            value={location} 
            onChange={handleChange}
            displayEmpty
            renderValue={(selected) => selected ? selected.loc_name : 'All'}
            sx={{
              ...fontColor, 
              border: '1px solid #ced4da', 
              fontSize: 12,
              '& .MuiSelect-icon': { fill: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' },
              height: 22
            }}>
            <MenuItem sx={{ fontSize: 13 }} value={{ loc_id: 'all', loc_name: 'All' }}>All</MenuItem>
            {allLocations && allLocations?.length > 0 ? (
              allLocations.map((loc) => (
                <MenuItem key={loc.loc_id} sx={{ fontSize: '13px' }} value={loc}>
                  {`${loc.loc_name} (${loc.loc_zip})`}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled sx={{ fontSize: 13, color: fontColor }}>
                No locations available
              </MenuItem>
            )}
          </Select>
          
          </Grid>
        </Grid>
        <Grid item container style={{ height: '70%' }}>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Products</p>
              <p>{dashboardLocations?.data?.product_count || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Categories</p>
              <p>{dashboardLocations?.data?.category_count || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation' >
            <div className='fontlocation'>
              <p>Total Number of</p>
              <p>Departments</p>
              <p>{dashboardLocations?.data?.dept_count || 0}</p>
            </div>
          </Grid>
          <Grid item xs={3} className='containerLocation'>
            <div className='fontlocation'>
              <p>Total Brands</p>
              <p>{dashboardLocations?.data?.brand_count || 0}</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LocationDetails;