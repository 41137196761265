import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useSelector } from "react-redux";

const PrivacyDialog = ({ open, setOpen }) => {
  const themeMode = useSelector((state) => state.theme.mode);

  const fontStyle = { fontSize: "11px", color: themeMode === "light" ? "#5A5A5A" : "#E4E3E3",}

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            color: themeMode === "light" ? "#5A5A5A" : "#E4E3E3",
            backgroundColor: themeMode === "light" ? "white" : "#232D3B",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              flex: 1,
              textAlign: "center",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          >
            PRIVACY POLICY
          </Typography>
          <IconButton
            onClick={handleClose}
            edge="end"
            aria-label="close"
            style={{ color: themeMode === "light" ? "#5A5A5A" : "#E4E3E3" }}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <DialogContentText>
            <Typography variant="subtitle2" sx={fontStyle} >
            This Privacy Policy is a legally binding document between You and
              Orbio solutions Pvt. Ltd. wherein “You” shall mean and include any
              client registered with Us or visitor visiting, using or
              registering with Us at <a style={{color: '#559EFF'}} href="https://www.orbiosolutions.com/contact-us" target="_blank" rel="noreferrer" >https://www.orbiosolutions.com/contact-us</a> “the Web Portal”
              or any other medium in which We may provide services and is owned
              and managed by Orbio Solutions Pvt Ltd, a company duly
              incorporated under the Companies Act, 2013 and having its
              Registered Office at 1st Floor, SPD Plaza Koramangala Industrial Layout, opposite Jyothi Nivas College, 5th Block, Koramangala, Bengaluru, Karnataka 560034, India- hereinafter referred to
              as, “Orbio Solutions”/ “We”/ “Us”. The terms “You” / “Your” /
              “Yourself” refer to the registered user (the “Client”) and any
              visitor visiting the Web Portal. PLEASE READ THIS PRIVACY POLICY
              CAREFULLY BEFORE USING OUR WEBSITES AND/OR SERVICES.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            BY ACCESSING
              OR USING OUR WEBSITE AND/OR OUR SERVICES (OTHER THAN TO READ THIS
              PRIVACY POLICY FOR THE FIRST TIME), YOU AGREE TO THIS PRIVACY
              POLICY. Orbio solutions is one of the leading technology company
              that redefines Your business with technology, design, and data.
              Our integrity has been established through the many years that We
              have provided a world class confidential service to our clients.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            Orbio Solutions has offered to provide a platform
            <a style={{color: '#559EFF'}} href="https://www.orbiosolutions.com" target="_blank" rel="noreferrer" > https://www.orbiosolutions.com</a> (“the Web Portal”) for retail
              analytics solution that enables its clients to utilize current
              trends, pricing models, and insights from client’s competitor’s
              marketing strategies to maximize sales and profitability of its
              Clients in the ever-evolving global market (“OttixHow”).
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            This
              privacy policy sets out how the Web Portal uses and protects any
              information that You provide on the Web Portal when You use the
              Web Portal or communicate with Us, Orbio Solutions is committed to
              ensuring that Your privacy is protected. We have implemented
              technology along with management procedures and policies to ensure
              client confidentiality. Orbio Solutions may update or change this
              policy from time to time updating this page. You should check this
              page from time to time to ensure that You are aware of any
              changes.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            WE ARE COMMITTED TO PROTECTING YOUR PRIVACY. We collect
              the minimum amount of information about You that is commensurate
              with providing You with a satisfactory service. This Privacy
              Policy indicates the type of processes that may result in data
              being collected about You. Your use of this Web Portal gives Us
              the right to collect that information.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            INFORMATION WE COLLECT: We
              may collect and store two kinds of information about You: Personal
              Identifiable Information: Information that provided by You that
              personally identifies You such as Your name, age, address, e-mail
              ID or phone number and such other information that identifies You
              as a person. We collect this information when You choose to
              register with Us or when You choose to participate in an
              interactive feature of Our Web Portal such as Our blogs, surveys,
              reviews, promotions, newsletters, panels, communities, free prize
              draws and competitions. You may: (a) provide Your name,
              mail/shipping address, email address, credit card number and phone
              number when registering with Our Web Portal or in connection with
              a contest entry; (b) provide certain demographic information about
              You (e.g., age, gender, shopping interests and preferences, etc.)
              when participating in a survey or poll; or (c) post a general
              comment and/or recommendation on Our Web Portal. Information that
              does not personally identify: This information is collected from
              You when You access the Web Portal, such as: IP address; the web
              browser type; Operating system; Address of the reference website
              or the website to which You exit to; date and time You access Our
              Web Portal or OttixHow; Items You click on; pages You visit; and
              time spent on each page. Such other information that may be needed
              from time to time to process a request may also be collected as
              indicated on the Web Portal. We may also collect the type of
              device You have used or are using, or the version of the operating
              system running on Your computer or device. Payment Information.
              While making payment on the Web Portal, We might collect Your
              payment information such as a. Your billing name; b. payment
              method; c. Your credit card number or debit card number or their
              expiry date or bank account details; and d. other details
              pertaining to Your payment method accepted by You on Our payment
              gateway at Our Web Portal. Cookies: A cookie is a small file which
              asks permission to be placed on Your computer’s hard drive. Once
              You agree, the file is added and the cookie helps analyses web
              traffic or lets You know when You visit a particular site. Cookies
              allow web applications to respond to You as an individual. The web
              application can tailor its operations to Your needs, likes and
              dislikes by gathering and remembering information about Your
              preferences. Usage of a cookie is not linked to any personally
              identifiable information. We do not seek to associate cookies with
              the completion of online forms. We use cookies to track how
              visitors move through Our sites and how often they visit. On most
              browsers, You can elect to decline to accept cookies. Should You
              decline to accept cookies Your browsing experience will not be
              degraded. We use IP addresses to analyse trends, administer Our
              Web Portal, track user’s movement, and gather broad demographic
              information for aggregate use. IP addresses are not linked to
              personally identifiable information. An IP address is an
              identifier for a computer or device on a Transmission Control
              Protocol/Internet Protocol (TCP/IP) network, such as the World
              Wide Web. Networks use the TCP/IP protocol to route information
              based on the IP address of the destination. In other words, an IP
              address is a number that is automatically assigned to Your
              computer whenever You are surfing the Web, allowing Web servers to
              locate and identify Your computer. Computers require IP addresses
              in order for users to communicate on the Internet, browse and
              utilise online forms and processes. Some information is also
              automatically collected through the use of cookies, web beacons,
              analytics/ webmaster tools and/ or similar tracking technologies.
              We may also use third parties to display and target ads, provide
              certain functionality (such as maps) or to place their own cookies
              and other tracking technologies to collect, track and analyse
              usage and statistical information from users. We are not
              responsible for the information collection practices of any third
              parties. If You, as a casual visitor, have inadvertently browsed
              any page of this Web Portal prior to reading the Privacy Policy
              set out herein, and You do not agree with the manner in which such
              information is obtained, collected, processed, stored, used,
              disclosed or retained, merely quitting this browser application
              should ordinarily clear all temporary cookies installed by the Web
              Portal. All visitors, however, are encouraged to use the “clear
              cookies” functionality of their browsers to ensure such clearing /
              deletion, as the Orbio Solutions cannot guarantee, predict or
              provide for the behaviour of the equipment of all the visitors of
              the Web Portal. Publicly Posted Information Personal Information
              that You post in public areas of Web Portal or via Your profile
              may become publicly available and is not considered Personal
              Information as defined under this Policy. For example, information
              You post in Your profile, peer endorsements, Q&A, or any other
              interactive areas may be made publicly available and can
              potentially be viewed by anyone. Your posting to such public areas
              of the Web Portal constitutes consent to share such information
              publicly, and You should assume that such information will be
              widely disseminated, including via search engines and other tools
              to locate information online such as internet archive. You can
              choose not to provide certain information, but then You might not
              be able to take advantage of some of Our features. We use the
              information that You provide for such purposes as responding to
              Your requests, customizing future services for You, improving Our
              platform, and communicating with You.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            BY USING OTTIXHOW OR BY
              OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE
              READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED
              IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY
              POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING,
              DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY
              POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE
              PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE
              DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY
              POLICY AT ANY TIME, DO NOT USE OTTIXHOW AND/OR ANY OF THE SERVICES
              OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE OTTIXHOW ON BEHALF
              OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR
              EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH
              INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
              INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF
              SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF
              SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS
              PRIVACY POLICY.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            No sensitive personal data or information is
              automatically collected by the Web Portal from any visitors to
              this Web Portal, who are merely perusing the Web Portal/OttixHow.
              Nevertheless, certain provisions of this Privacy Policy are
              applicable to even such casual visitors, and such casual visitors
              are also required to read and understand the privacy statements
              set out herein, failing which they are required to leave this Web
              Portal immediately. HOWEVER, IF YOU ARE NOT AN USER REGISTERED
              WITH THE WEB PORTAL AND IF YOU HAVE WILLINGLY SUBMITTED ANY
              PERSONAL DATA OR INFORMATION TO OUR WEB PORTAL THROUGH ANY MEANS,
              INCLUDING E-MAIL, POST OR THROUGH THE REGISTRATION PROCESS ON THE
              WEB PORTAL, YOU WILL BE DEEMED TO BE, AND WILL BE TREATED AS A
              USER FOR THE PURPOSES OF THIS PRIVACY POLICY, AND IN WHICH CASE,
              ALL THE STATEMENTS IN THIS PRIVACY POLICY APPLY TO SUCH PERSONS.
              Any personal information provided to or gathered by the Web Portal
              under this Privacy Policy will be stored and controlled by Orbio
              Solutions (the data controller) on <a style={{color: '#559EFF'}} href="https://www.orbiosolutions.com" target="_blank" rel="noreferrer" >https://www.orbiosolutions.com</a>.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            HOW WE USE YOUR INFORMATION We use the information collected
              primarily to process the task for which You visited the Web Portal
              and provide services on the Web Portal. All reasonable precautions
              are taken to prevent unauthorised access to this information. This
              safeguard may require You to provide additional forms of identity
              should You wish to obtain information about Your account details.
              We may disclose this information to authorized third parties
              including Our merchants, advertising and promotional partners and
              third party service providers for these purposes. Personal
              Identifiable Information:. The Personal Identifiable Information
              is used by Us to facilitate Your use of the Web Portal, to process
              Your requests or transactions, to provide You with information,
              products and services You request, to administer and assist Us
              with the operation of Our Web Portal, and for the purpose for
              which the information was provided. This inofrmation may also be
              used to send news, relevant to You or in accordance with Your
              preferences, Our offers or products, or third-party offers,
              advertisement or products we think You might find interesting in.
              We will not disclose, rent, sell or otherwise make available Your
              Personal Information to any third parties for direct marketing
              purpose. Information that does not personally identify We may use
              the information that is not Personal Identifiable Information,
              including aggregated or anonymous information, for ad targeting,
              to analyze trends, administer Our services, improve customer
              service, diagnose problems with Our servers, track user movement,
              and gather demographic information for aggregate use.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            We may
              publish, disclose and use information other than Personal
              Identifiable Information, including aggregated or anonymous
              information, to or with third parties. Third Party Service
              Providers. In the event that We engage with any third party
              service providers in connection with OttixHow or any other
              services provided on the Web Portal, We may share Your Personal
              Information with such third party service providers who need
              access to such information to carry out their work for Us. For
              example, We may use credit card processing or verification
              companies to verify credit card information or to verify Your
              identity. Other than such service providers, to whom such
              disclosures are important for functioning of the Web Portal,
              OttixHow and/or any other services on the Web Portal, We do not
              share Personal Information with third parties unless (1) You
              otherwise give Us Your consent to do so; or (2) in response to a
              request for information or a subpoena. Transactional
              Communications & Responding to requests or questions The
              information provided by You will be used to contact You for
              confirmation of payment related to Our Web Portal. We might send
              You emails or SMS about Your payment made, payment dues and
              referrals, if any. The information may be used to confirm any
              queries, complaints, suggestions or demands made by You on Our Web
              Portal. We might send You information about special promotions or
              offers or referral policies. We might also tell You about new
              features or services. These might be Our own offers or services,
              or third-party offers or services We think You might find
              interesting. General.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            We may use information to protect Orbio
              Soltuions, OttixHow, Our Web Portal, affiliates, partners,
              employees, Clients/users/visitors. We may share information with
              any successor to all or part of Our business. We may disclose
              Personal Information when: We are required to or We believe it is
              appropriate to comply with any applicable law (e.g., a lawful
              subpoena, warrant or court order); to enforce or apply this
              Privacy Policy or Our other policies, terms and conditions or
              agreements; to initiate, render, bill, and collect for amounts
              owed to Us; to protect Our or Our Clients’/users’/visitors’
              rights, property or safety; to protect Orbio Solutions, Clients,
              visitors from fraudulent, abusive, or unlawful use of the Web
              Portal; if We believe that an emergency involving the danger of
              death or serious physical injury to any person requires or
              justifies disclosure of Personal Information; or We may share it
              if a Government Agency or Investigatory body wants Us to do so, or
              anytime when We are investigating potential fraud. The information
              may be used to protect Orbio Solutions, OttixHow, Web Portal,
              Clients, visitors, users. We use information as otherwise
              permitted by law or if We may share Your information for reasons
              not described in this Privacy Policy, We shall inform You before
              We do so. Clients should also aware of their obligation towards
              Confidential Information more particularly defined and stated in
              the Client Agreement. PROTECTION OF INFORMATION Every effort will
              be made to keep the information provided by the Client in a safe
              manner, the information displayed on the Web Portal will be done
              so only after obtaining consent from the Client and/or visitors.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            Any person browsing the Web Portal generally is not required to
              disclose his identity or provide any information about him/her, it
              is only at the time of registration You will be required to
              furnish the details in the registration form. THIRD-PARTY SITES If
              You click on one of the links to third party websites mentione on
              the Web Portal, You may be taken to websites We do not control.
              This policy does not apply to the privacy practices of those
              websites. Read the privacy policy of other websites carefully. We
              are not responsible for these third-party sites. DELETING DATA. If
              Your access to the Web Portal or Your zaccount has been blocked or
              Your data is no longer required for the purposes mentioned or if
              their storage is inadmissible for other legal reasons, We will
              delete them. However, the exception to this would be any lawfully
              collected data which We are obliged to keep for legal reasons.
              Also please note that for every deletion the data are initially
              only blocked and finally deleted with a time delay in order to
              prevent accidental deletions or possibly intentional damage. For
              technical reasons, data may be duplicated in backup files and
              mirroring services. Copies of the data will also be deleted,
              although there might be a technical delay.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            GRIEVANCE REDRESSAL
              MECHANISM If You have any questions about Our Privacy Policy, or
              if You want to know what information We have collected about You,
              You can contact Us at the information given below: Place: Banglore
              Email: <a style={{color: '#559EFF'}} href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a> Contact: +91 98867 67400 Working hour/days:
              __________________ CONTACTING US: If You have any questions about
              Our Privacy Policy, or if You want to know what information We
              have collected about You, please email Us at <a style={{color: '#559EFF'}} href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a>.
              You can also correct any factual errors in that information or
              require Us to remove Your details from any list under Our control.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            DISCLAIMER The Clients are solely responsible for maintaining the
              confidentiality of the user name and the password and all
              activities and transmission performed by the registered users
              through his/her user identification and shall be solely
              responsible for carrying out any online or off-line transaction
              with Orbio Solutions. No administrator at Orbio Solutions will
              have knowledge of Your password. It is important for You to
              protect against unauthorized access to Your password, Your
              computer and Your mobile phone. Be sure to log off from the Web
              Portal when finished.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            ORBIO SOLUTIONS SHALL HAVE NO RESPONSIBILITY
              OR LIABILITY FOR ANY UNAUTHORISED OR IMPROPER USE OF YOUR ACCOUNT,
              PASSWORD, INFORMATION RELATING TO SUCH USAGE OF CREDIT CARDS /
              DEBIT CARDS USED BY THE MAPPER ONLINE / OFF-LINE. ALL THE
              DISCLAIMER MENTIONED IN THE CLIENT AGREEMENT AND ON THE WEB PORTAL
              SHALL BE APPLICABLE. If You suspect any unauthorized use of Your
              account, You must immediately notify Orbio Solutions by sending an
              email to <a style={{color: '#559EFF'}} href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a>. YOU SHALL BE LIABLE TO INDEMNIFY ORBIO
              SOLUTIONS, ANY LOSS SUFFERED BY IT DUE TO SUCH UNAUTHORIZED USE OF
              YOUR ACCOUNT AND PASSWORD.
            </Typography>
            <Typography variant="subtitle2" sx={fontStyle} mt={1} >
            We update and change the Privacy Policy
              from time to time and as required under applicable laws. The
              updated Privacy Policy is posted on the Web Portal. It is the
              responsibility of the Client and visitors to periodically check
              for updates. CHILDREN We do not knowingly contact or collect
              personal information from children under 14. If You believe We
              have inadvertently collected such information, please contact Us
              so We can promptly obtain parental consent or remove the
              information. JURISDICTION If You choose to visit Our Web Portal,
              Your visit and any dispute over privacy is subject to this Privacy
              Policy. In addition to the foregoing, any disputes arising under
              this Privacy Policy shall be governed by the laws of India.
              APPLICABLE LAW This Privacy Policy is an electronic contract
              formed under The Information Technology Act, 2000 and the rules
              made thereunder and the amended provisions pertaining to
              electronic documents / records in various statutes as amended by
              The Information Technology Act, 2000. This Privacy Policy does not
              require any physical, electronic or digital signature.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PrivacyDialog;
