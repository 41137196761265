import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_CLIENT, ADD_CLIENT_FAILED, ADD_CLIENT_SUCCESSFULL, RESET_ADD_CLIENT } from "../constants/addClient.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addClient = (data) => ({
    type: ADD_CLIENT,
    payload: data
});

const addClientSuccess = (data) => ({
    type: ADD_CLIENT_SUCCESSFULL,
    payload: data
});

const addClientFailure = (error) => ({
    type: ADD_CLIENT_FAILED,
    payload: error
});

export const resetAddClient = () => ({
    type: RESET_ADD_CLIENT
})

export const addNewClient = (data, token) => async (dispatch) => {
    try {
      dispatch(addClient(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.clients}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addClientSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
        const errorMessage = error.response?.data?.detail || "Failed to edit client data"; 
        dispatch(addClientFailure(errorMessage));
      }
    }
};