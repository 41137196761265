import { FETCH_CURRENCIES, FETCH_CURRENCIES_FAILED, FETCH_CURRENCIES_SUCCESSFULL } from "../constants/currencies.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const currenciesReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_CURRENCIES:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_CURRENCIES_SUCCESSFULL:
            const transformedData = Object.entries(action.payload).map(([symbol, name]) => ({
                currency_symbol: symbol,
                currency_name: name
              }));
            return {
                ...state,
                loading: false,
                data: transformedData,
                error: null
            };

        case FETCH_CURRENCIES_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default currenciesReducer;