import axios from 'axios';
import { paths } from '../../Api/paths';
import { COMPETITORS_LOCATIONS_FAILED, COMPETITORS_LOCATIONS_SUCCESSFULL, FETCH_COMPETITORS_LOCATIONS } from '../constants/compLocations.constants';
import { handleTokenExpiration } from './handleTokenExpiration.actions';

const getCompetitorsLocations = () => ({
    type: FETCH_COMPETITORS_LOCATIONS,
});

const getCompetitorsLocationsSuccessfull = (data) => ({
    type: COMPETITORS_LOCATIONS_SUCCESSFULL,
    payload: data
});

const getCompetitorsLocationsFailed = (error) => ({
    type: COMPETITORS_LOCATIONS_FAILED,
    payload: error
});

export const fetchCompetitorsLocations = (token, qa_user, company_id) => async (dispatch) => {
    try {
        dispatch(getCompetitorsLocations());
        const endpoint = qa_user ? `${paths.qaCompetitorsLocations}?company_id=${company_id}` : paths.competitorsLocations;
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
        { headers: { Authorization: `bearer ${token}` } })
        dispatch(getCompetitorsLocationsSuccessfull(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getCompetitorsLocationsFailed(error));
          }
    }
};