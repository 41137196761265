import axios from "axios";
import { FETCH_DASHBOARD_DATA, FETCH_DASHBOARD_DATA_FAILED, FETCH_DASHBOARD_DATA_SUCCESSFULL } from "../constants/dashboard.constants";
import { paths } from "../../Api/paths";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getDashboardData = () => ({
    type: FETCH_DASHBOARD_DATA
});

const getDashboardDataSuccessfull = (data) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESSFULL,
    payload: data
});

const getDashboardDataFailed = (error) => ({
    type: FETCH_DASHBOARD_DATA_FAILED,
    payload: error
});

export const fetchDashboardData = ( token, qa_user, company_id ) => async (dispatch) => {
    try{
        dispatch(getDashboardData());
        const endpoint = qa_user ? `${paths.qaDashboard}?company_id=${company_id}` : paths.dashboard;
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
        { headers: { Authorization: `bearer ${token}` }});
        dispatch(getDashboardDataSuccessfull(response.data));
    }catch (error){
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getDashboardDataFailed(error));
          }
    }
}