import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuth } from '../../redux/actions/loginUser.actions';

const PendingApproval = () => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const handleLogout = () => {
    if(auth.admin_approved === false || auth.admin_approved === null){
      dispatch(resetAuth())
      navigate('/login');
    }
  };

  return (
    <Box sx={{ backgroundColor: '#eaecee', height: '100vh', p: 0, display: 'flex', flexDirection: 'column', gap: '10px' }} >

      <Box sx={{ backgroundColor: '#ffffff', p: 1, display: 'flex', justifyContent: 'space-between', height: '10vh' }} >
        <Button sx={{ width: '25%' }} >
          <img src='/assets/logo.png' alt='img' className='logo' />
        </Button>
        <Button onClick={handleLogout} className='navLink' sx={{ fontSize: '11px', color: '#5A5A5A' }}>Logout</Button>
      </Box>

      <Box sx={{ backgroundColor: '#ffffff', height: '90vh' }} >
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" textAlign="center">
          <Typography variant="h4" gutterBottom>
            Registration Pending Approval
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you for registering! Your account is currently pending approval.
            We will notify you once your registration has been approved.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We will notify you shortly through email.
          </Typography>
          <Button variant="contained" sx={{ textTransform: 'lowercase' }} color="primary" href="https://www.ottixhow.com">
            Visit www.ottixhow.com
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PendingApproval;
