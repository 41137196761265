import { Typography } from '@mui/material';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

const MatchRateBar = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const matchRateData = useSelector(state => state.dashboard.data?.matchRate);

  const marketplaces = Object.keys(matchRateData);
  const orderedData = Object.values(matchRateData).map(entries => entries.map(entry => Object.values(entry)[0]));
  const chartColors = ['#A8CBFF', '#4F8EEC', '#186cea'];

  const barData = {
    labels: [['100%'], ['90-100%'], ['80-90%'], ['70-80%'], ['60-70%'], ['<60%']],
    datasets: orderedData.map((barItem, index) => ({
      label: marketplaces[index],
      data: barItem.flat(),
      backgroundColor: chartColors[index],
      stack: String(index),
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 15,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        border: {
          display: true,
          color: '#515151',
        },
      },
      y: {
        stacked: true,
        labelAngle: 0,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        border: {
          display: true,
          color: '#515151',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        onClick: () => { } // Disable legend click functionality
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div style={{ width: '95%', height: '80%', padding: '10px' }}>
      <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>Match Rate</Typography>
      {orderedData.length ? <Bar options={options} data={barData} /> :
        <div>
          <Typography sx={{ ml: '42%', mt: 15, fontSize: '11px' }}>There is no data</Typography>
        </div>}
    </div>
  );
};

export default MatchRateBar;