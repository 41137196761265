import axios from "axios";
import { paths } from '../../Api/paths';
import { ADD_SELLER, ADD_SELLER_FAILED, ADD_SELLER_SUCCESSFULL, RESET_ADD_SELLER } from "../constants/addSeller.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addSeller = (data) => ({
    type: ADD_SELLER,
    payload: data
});

const addSellerSuccess = (data) => ({
    type: ADD_SELLER_SUCCESSFULL,
    payload: data
});

const addSellerFailure = (error) => ({
    type: ADD_SELLER_FAILED,
    payload: error
});

export const resetAddSeller = () => ({
    type: RESET_ADD_SELLER
})

export const addNewSeller = (data, token) => async (dispatch) => {
    try {
      dispatch(addSeller(data));
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.sellers}`,
      data, { headers: { Authorization: `bearer ${token}`}})
      dispatch(addSellerSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(addSellerFailure(error));
          }
    }
};