import { RESET_ALL_DATA } from "../constants/resetAllData.constants";
import { RESET_SEND_PASSWORD_LINK, SEND_PASSWORD_LINK, SEND_PASSWORD_LINK_FAILED, SEND_PASSWORD_LINK_SUCCESSFULL } from "../constants/sendPasswordLink.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const sendPasswordLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_PASSWORD_LINK:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case SEND_PASSWORD_LINK_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case SEND_PASSWORD_LINK_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_SEND_PASSWORD_LINK:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default sendPasswordLinkReducer;