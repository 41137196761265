import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/Navbar';
import { useSelector } from 'react-redux';
import { Button, Grid, Box, Paper  } from '@mui/material';
import Footer from '../../components/Footer';
import PrivateNationalCount from '../../components/Charts/PrivateNationalCount';
import AveragePricingPvtLabel from '../../components/AveragePricingPvtLabel';
import PrivateNationalRetailer from '../../components/Charts/PrivateNationalRetailer';
import PrivateLabelFilterDialog from '../../components/PrivateLabelFilterDialog';

const Report = () => {

  const themeMode = useSelector(state => state.theme.mode);

  const tableauVizRef = useRef(null);

  const [tableauUrl, setTableauUrl] = useState('https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link');
  const [selectedButton, setSelectedButton] = useState('assortment');

  const [openFilterDialog, setOpenFilterDialog] = useState({ 
    openPrivateDialog: false, 
   });

  const handleAssortment = () => {
    setTableauUrl('https://public.tableau.com/views/Assortmentandoffers/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link');
    setSelectedButton('assortment');
  };

  const handlePrivateLabel = () => {
    setSelectedButton('privateLabel');
  };

  useEffect(() => {
    if (selectedButton === 'assortment') {
    const url = tableauUrl;
    const options = {
      hideTabs: true,
    };
    let viz;
    const initTableau = () => {
      if (window.tableau.Viz) {
        viz = new window.tableau.Viz(tableauVizRef.current, url, options);
      } else {
        setTimeout(initTableau, 50);
      }
    };

    initTableau();

    return () => {
      if (viz) {
        viz.dispose();
      }
    };
  }
  }, [selectedButton]); //eslint-disable-line


  return (
    <>
      <Navbar selected='report' />
      <Grid container className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
      { openFilterDialog.openPrivateDialog && <PrivateLabelFilterDialog openFilterDialog={openFilterDialog} setOpenFilterDialog={setOpenFilterDialog} /> }
        <Paper sx={{width: '100%', mt:1, background: themeMode === 'dark' ? '#232d3b' : 'white'}}>
        <Grid p={4} pb={2} item container xs={12}>
          <Grid item xs={6} >
          <Button onClick={handleAssortment}
            sx={{ fontSize: '12px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'capitalize',
              '&::first-letter': { textTransform: 'uppercase' },
              border: selectedButton === 'assortment' ? `1px solid ${themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}` : 'none',
              padding: '2px', marginRight: '2px'
            }}>Assortment</Button>
          <span style={{ fontSize: '12px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>|</span>
          <Button onClick={handlePrivateLabel} sx={{ fontSize: '12px',
            color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'capitalize',
            '&::first-letter': { textTransform: 'uppercase' },
            border: selectedButton === 'privateLabel' ? `1px solid ${themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}` : 'none',
            padding: '2px', marginLeft: '2px'
          }}>Private Label</Button>
          </Grid>
          <Grid item xs={6} sx={{display: selectedButton === 'privateLabel' ? 'flex' : 'none', justifyContent: 'flex-end'}}>
            <Button
            onClick={()=>setOpenFilterDialog(prev => ({ ...prev, openPrivateDialog: true }))}
             sx={{ fontSize: '12px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'capitalize',
             '&::first-letter': { textTransform: 'uppercase' },
             border:`1px solid ${themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'}`,
             padding: '2px', marginRight: '2px' ,
           }} >Filters</Button>
          </Grid>
        </Grid>
          {
            selectedButton === 'assortment' ? 
        <Grid px={4} className='' sx={{ display: 'flex' }} item xs={12} >
            <Box ref={tableauVizRef} sx={{ width: '100%', height: '100%', overflowY: 'auto', overflowX: 'auto', p: 'auto'}}></Box>
        </Grid>
              :
        <Grid className='navPage' item xs={12} >
          <Grid container item xs={12} spacing={6} pl={4} pr={4} pt={1} pb={3} >
          <Grid item xs={12} >
            <PrivateNationalCount />
          </Grid>
          <Grid item xs={12} >
            <AveragePricingPvtLabel />
          </Grid>
          <Grid item xs={12} >
            <PrivateNationalRetailer />
          </Grid>
          </Grid>
        </Grid> 
          }
          </Paper>
      </Grid>
      <Footer />
    </>
  );
};

export default Report;