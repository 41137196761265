import { RESET_ALL_DATA } from "../constants/resetAllData.constants";
import { RESET_PASSWORD,RESET_PASSWORD_FAILED,RESET_PASSWORD_SUCCESSFULL,RESET_RESET_PASSWORD } from "../constants/resetPasswordLink.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const sendResetPasswordLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case RESET_PASSWORD_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_RESET_PASSWORD:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default sendResetPasswordLinkReducer;