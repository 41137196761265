import axios from "axios";
import { paths } from "../../Api/paths";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";
import { RESET_TOGGLE_APPROVAL, TOGGLE_APPROVAL, TOGGLE_APPROVAL_FAILED, TOGGLE_APPROVAL_SUCCESSFULL } from "../constants/toggleApproval.constants";

const toggleApproval = () => ({
  type: TOGGLE_APPROVAL,
});

const toggleApprovalSuccess = (data) => ({
  type: TOGGLE_APPROVAL_SUCCESSFULL,
  payload: data,
});

const toggleApprovalFailure = (error) => ({
  type: TOGGLE_APPROVAL_FAILED,
  payload: error,
});

export const resetToggleApproval = () => ({
  type: RESET_TOGGLE_APPROVAL,
});

export const handleToggleApproval = (token, queryString) => async (dispatch) => {
  try {
    dispatch(toggleApproval());
    const response = await axios.patch(`${process.env.REACT_APP_API_LINK}${paths.qaToggleApproval}${queryString}`,
      {}, { headers: { Authorization: `bearer ${token}` } });
    dispatch(toggleApprovalSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(handleTokenExpiration());
    } else {
    dispatch(toggleApprovalFailure(error));
    }
  }
};
