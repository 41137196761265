import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Stack, IconButton } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CircularProgress from '@mui/material/CircularProgress';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import './bulkUpload.css'
import { addBulkProducts, resetAddBulkProducts } from '../../redux/actions/addBulkProducts.actions';
import { useNavigate } from 'react-router';
import { toggleProductsAdded } from '../../redux/actions/loginUser.actions';
import * as XLSX from "xlsx";

const BulkUpload = () => {

    const themeMode = useSelector(state => state.theme.mode);
    const token = useSelector(state => state.auth.token);
    const productsAdded = useSelector(state => state.addBulkProducts)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [serverError, setServerError] = useState('');
    const fileTypes = ['XLSX', 'XLS'];

    const [isValid, setIsValid] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isValidating, setIsValidating] = useState({
        validating:false,
        completed:false
    });

    const handleChange = (file) => {
        setFile(file);
        validateFile(file[0]);
        setServerError('');
    };

    const validateFile = (file) => {
        setIsValidating({ validating: true, completed: false });
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
    
            // Check if the sheet exists and has data
            if (!sheet) {
              setIsValid(false);
              setErrorMessages(["The selected file is empty or not properly formatted."]);
              setIsValidating(false);
              setIsValidating({ validating: false, completed: false });
              return;
            }
    
            // Extract headers and rows
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const headers = jsonData[0];
            const rows = jsonData.slice(1);
    
            // Check if headers are properly defined
            if (!Array.isArray(headers)) {
              setIsValid(false);
              setErrorMessages(["The selected file does not contain valid headers."]);
              setIsValidating({ validating: false, completed: false });
              return;
            }
    
            const requiredHeaders = [
              "prd_name",
              "prd_desc",
              "prd_url",
              "prd_no_of_pieces",
              "prd_size",
              "prd_uom",
              "prd_upc",
              "prd_asin",
              "prd_brand",
              "prd_small_image",
              "prd_medium_image",
              "prd_variants",
              "keyword",
              "prd_is_active",
              "prd_is_crawl_enabled",
              "prd_is_match_enabled",
              "dept_is_active",
              "category_is_active",
              "prd_rating",
              "prd_tot_reviews",
              "prd_spec",
              "prd_offer",
              "prd_mrp",
              "prd_sales_price",
              "category_name",
              "category_desc",
              "dept_name",
              "dept_desc",
              "loc_name",
              "loc_zip",
              "country_name",
              "country_short_name",
              "state_name",
              "state_short_name",
              "seller_name",
              "seller_rating",
              "seller_tot_reviews",
              "prd_notification_enabled",
            ];
    
            // Check if all required headers are present
            const missingHeaders = requiredHeaders.filter(
              (header) => !headers.includes(header)
            );
    
            if (missingHeaders.length > 0) {
              setIsValid(false);
              setErrorMessages([`Missing headers: ${missingHeaders.join(", ")}`]);
              setIsValidating({ validating: false, completed: false });
              return;
            }
    
            // Check if required fields contain values and are not empty
            const requiredFieldIndices = {
              "prd_name": headers.indexOf("prd_name"),
              "loc_name": headers.indexOf("loc_name"),
              "loc_zip": headers.indexOf("loc_zip"),
              "country_name": headers.indexOf("country_name"),
              "country_short_name": headers.indexOf("country_short_name"),
              "state_name": headers.indexOf("state_name"),
              "state_short_name": headers.indexOf("state_short_name"),
            };
    
            let errorMsgs = [];
            let emptyFields = [];
    
            for (const [fieldName, index] of Object.entries(requiredFieldIndices)) {
              const emptyRows = rows.filter((row) => !row[index]);
              if (emptyRows.length > 0) {
                emptyFields.push(fieldName);
              }
            }
    
            if (emptyFields.length > 0) {
              errorMsgs.push(
                `The following columns contain empty values: ${emptyFields.join(", ")}.`
              );
            }
    
            // Check if "prd_url" value is empty, then "prd_mrp" or "prd_sales_price" must have a value
            const prdUrlIndex = headers.indexOf("prd_url");
            const prdMrpIndex = headers.indexOf("prd_mrp");
            const prdSalesPriceIndex = headers.indexOf("prd_sales_price");
            const invalidRows = rows.filter(
              (row) =>
                !row[prdUrlIndex] &&
                (!row[prdMrpIndex] && !row[prdSalesPriceIndex])
            );
    
            if (invalidRows.length > 0) {
              errorMsgs.push(
                "For rows with an empty 'prd_url', 'prd_mrp' or 'prd_sales_price' must have a value."
              );
            }
    
            // Check if "prd_url" value is a valid URL
            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            const invalidUrlRows = rows.filter(
              (row) => row[prdUrlIndex] && !urlRegex.test(row[prdUrlIndex])
            );
    
            if (invalidUrlRows.length > 0) {
              errorMsgs.push("The 'prd_url' column contains invalid URLs.");
            }
    
            if (errorMsgs.length > 0) {
              setIsValid(false);
              setErrorMessages(errorMsgs);
            } else {
              setIsValid(true);
              setErrorMessages([]);
            }
          } catch (error) {
            setIsValid(false);
            setErrorMessages(["An error occurred while processing the file."]);
          } finally {
            setIsValidating({ validating: false, completed: true });
          }
        };
        reader.readAsArrayBuffer(file);
      };


    const handleSubmit = (values) => {
        if(values && isValid){
          dispatch(addBulkProducts(values, token));
        }
    }

    useEffect(() => {
        if (productsAdded.data) {
            dispatch(resetAddBulkProducts());
            if (productsAdded.data.default_location){
                navigate('/myproducts');
                dispatch(toggleProductsAdded());
            } 
            else navigate('/settings');
        } else if(productsAdded.error) {
          const errorMessage = productsAdded.error?.response?.data?.detail;
          if (errorMessage?.startsWith("Product count limit")) {
            setServerError(errorMessage);
          } else {
            setServerError('Failed to add products, please check the file before you add the products again');
          }
        }
    }, [productsAdded]) //eslint-disable-line

    useEffect(() => {
      setServerError('')
    }, []) //eslint-disable-line
    

    const btnStyle = { fontSize: 12, textDecoration: 'underline', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', textTransform: 'inherit', }

    return (
        <Grid sx={{ mt: 1 }}>
            <Grid item xs={12} sx={{ display: 'flex', pt: 5, flexFlow: 'column', alignItems: 'center', }}>
                {productsAdded?.loading ? <CircularProgress /> : <>
                    <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name='file'
                        types={fileTypes}
                    />
                    <Typography variant='body2' component={'p'} sx={{ mt: 2, fontSize: 13, color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{file ? `File name: ${file[0].name}` : 'No files uploaded yet'}</Typography>
                    {isValidating.validating && <Typography variant='body2' component={'p'} sx={{ fontSize: 13, color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >Validating Please Wait...</Typography>}
                    {(isValidating.completed && isValid && !productsAdded?.loading) && <Typography variant='body2' component={'p'} sx={{ fontSize: 13, color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >Your file has been verified and is ready for upload.</Typography>}
                    {!isValid && !isValidating.validating && (
                    <div style={{ color: "red", marginTop: "10px" }}>
                      {errorMessages?.map((message, index) => (
                        <Typography sx={{fontSize: 13, color: 'red'}} key={index}>{message}</Typography>
                      ))}
                    </div>)}
                    <Button sx={{ width: '40%', mt: 3, backgroundColor: '#559EFF', borderColor: '#1C3B6E', color: '#000000', fontSize: '13px', textTransform: 'capitalize' }}
                        disabled={!file || isValidating.validating || !isValid} onClick={e => handleSubmit(file)}
                    >Add products</Button>
                    <Typography sx={{ mt: 2, fontSize: 13, color: 'red' }}>{serverError}</Typography></>}
            </Grid>
            <Grid item xs={12} sx={{ mt: 0 }}>
                <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }}>Need Help?</Typography>
                <Stack spacing={2} sx={{ mt: 3 }}>
                    <Grid xs={12} item container className='bulkuploadContainer' >
                        <Grid item xs={2} sx={{ textAlign: 'center' }} >
                            <IconButton >
                                <PlayCircleFilledIcon sx={{ color: '#1C3B6E', fontSize: 50 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left' }} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }} > How to prepare your import file.</Typography>
                            <Typography variant='body2' sx={{ fontSize: 11 }} > This video guide will show you how to prepare your import file.</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button variant='text' sx={{ ...btnStyle, fontSize: 15 }} > View import guide</Button>
                        </Grid>
                    </Grid>

                    <Grid xs={12} item container className='bulkuploadContainer' >
                        <Grid item xs={2} sx={{ textAlign: 'center' }} >
                            <IconButton >
                                <BrowserUpdatedIcon sx={{ color: '#1C3B6E', fontSize: 50 }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'left' }} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize: 16, fontWeight: 500 }} > Download sample import file.</Typography>
                            <Typography variant='body2' sx={{ fontSize: 11 }}  > Would you like to see how import file looks like? Download sample file and test import process..</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button variant='text' sx={{ ...btnStyle, fontSize: 15 }}>
                                <a href='/assets/Bulk_upload_field.xlsx' style={{ textDecoration: 'none', color: 'inherit' }} target='_blank'>
                                    Download sample file
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default BulkUpload;