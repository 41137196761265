import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import { Divider, FormControl, FormControlLabel, Grid, Card, Button, IconButton, Paper, Popover, Radio, RadioGroup, Typography, AccordionSummary, Accordion, AccordionDetails, Select, MenuItem, Box, Switch, Tooltip } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import WestIcon from '@mui/icons-material/West';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceComparisonDialog from '../../components/PriceComparisonDialog';
import ProductDetailsFilterDialog from '../../components/ProductDetailsFilterDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import Footer from '../../components/Footer';
import { resetUpdateScore, updateScore } from '../../redux/actions/updateScore.actions';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';
import { clearFilters } from '../../redux/actions/detailsPageFilters.actions';
import EditProductDialog from '../../components/EditProductDialog';
import AddCompetitorDialog from '../../components/AddCompetitorDialog';
import RefreshCompetitorDialog from '../../components/RefreshCompetitorDialog';
import EnableMatchDialog from '../../components/EnableMatchDialog';
import { resetUpdateProduct, updateProduct } from '../../redux/actions/updateProduct.actions';
import { handleEnableNotification, resetEnableNotification } from '../../redux/actions/enableNotification.actions';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ModifyKeywordDialog from '../../components/ModifyKeywordDialog';
import { handleToggleApproval, resetToggleApproval } from '../../redux/actions/toggleApproval.actions';
import EditCompetitorDialog from '../../components/EditCompetitorDialog';
import { fetchProducts } from '../../redux/actions/getProducts.actions';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { fetchCompPrdSettingsLocations } from '../../redux/actions/compPrdLocSettings.actions';
import OttixInsightsDialog from '../../components/OttixInsightsDialog';
import DeleteDialog from '../../components/DeleteDialog';

const ProductDetails = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const company_id = useSelector(state => state.auth.company_id);
  const qa_user = useSelector(state => state.auth.qa_user);
  const currSymb = useSelector(state => state.auth?.currency?.length !== 0 ? state.auth?.currency[0]?.currency_symbol : '');
  const productDetails = useSelector(state => state.productDetails);
  const compScoreUpdate = useSelector(state => state.updateScore);
  const filters = useSelector(state => state.detailsPageFilters);
  const prdLocations = useSelector(state => state.productLocations);
  const updateProductDetails = useSelector(state => state.updateProductDetails);
  const enableNotificationDetails = useSelector(state => state.enableNotificationDetails);
  const toggleApproval = useSelector(state => state.toggleApproval);
  const compPrdSettingsLoc = useSelector(state => state.compPrdLocSettingsDetails);
  const marketPlaces = useSelector(state => state.marketPlaces);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product_id, location_id, comp_loc_id } = useParams();

  const [removedComp, setRemovedComp] = useState([]);
  const [selComp, setSelComp] = useState(null);
  const [scrollPos, setScrollPos] = useState(0);
  const [popUp, setPopup] = useState({ openLocationPopup: null, openSettingsPopup: null, openMatchIdPopup: null, helpPopup: null })
  const [activeTab, setActiveTab] = useState('marketdata');
  const [selectedMatchRate, setSelectedMatchRate] = useState('');
  const [compPrdId, setCompPrdId] = useState('');
  const [switchStatus, setSwitchStatus] = useState({
    monitor: { status: false, error: false },
    notification: { status: false, error: false },
    approved: { status: false, error: false }
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [accordionsExpanded, setAccordionsExpanded] = useState({
    qaKeyword: false,
    rating: false,
    description: false,
    promotion: false,
    product: false,
  });
  const [rating, setRating] = useState(false);
  const [descSeeMore, setDescSeeMore] = useState(false);
  const [promotionSeeMore, setPromotionSeeMore] = useState(false);
  const [productSeeMore, setProductSeeMore] = useState(false);
  const [dialogState, setDialogState] = useState({
    openGrpah: false,
    openPdtFilter: { show: false, data: null },
    editProduct: false, data: null,
    addComp: false,
    refresh: false,
    enableMatch: false,
    modifyKeyword: false,
    editComp: false,
    openGraphInfo: false,
    deleteCompPdt: {show: false, data: null, onConfirm: null, }
  })
  const products = useSelector(state => state.products);
  const productString = useSelector(state => state.productString);
  const allPdtIds = products?.data?.results?.map(product => product.prd_id);
  const currentDataIndex = allPdtIds?.indexOf(Number(product_id));
  const cardsPerPage = 3;
  const competitorsData = productDetails.data?.competitors?.filter(comp => comp.loc.loc_id === Number(comp_loc_id) && !removedComp.includes(comp.comp_prd_id));
  const priceDetails = productDetails.data?.locations[0];
  const percentageValues = Array.from({ length: 100 }, (_, i) => i + 1);
  const keywordArray = productDetails?.data?.keyword ? JSON.parse(productDetails?.data?.keyword) : [];
  const locPopup = Boolean(popUp.openLocationPopup);
  const settingPopup = Boolean(popUp.openSettingsPopup);
  const matchIdPopup = Boolean(popUp.openMatchIdPopup);
  const helpIdPopup = Boolean(popUp.helpPopup);
  const LocId = locPopup ? 'location-popover' : undefined;
  const settingsId = settingPopup ? 'settings-popover' : undefined;
  const helpId = helpIdPopup ? 'help-popover' : undefined;
  const matchId = matchIdPopup ? 'matchId-popover' : undefined;

  const handlePrdLocation = (event) => {
    const tempLoc = event.target.value;
    dispatch(clearFilters());
    sessionStorage.removeItem('detailPageFilters');
    setPopup({ openLocationPopup: null, openSettingsPopup: null, openMatchIdPopup: null, helpPopup: null });
    navigate(`/myProduct/${product_id}/${tempLoc}/${comp_loc_id}`)
  }

  const handleCompLocation = (event) => {
    const tempLoc = event.target.value;
    dispatch(clearFilters());
    sessionStorage.removeItem('detailPageFilters');
    setPopup({ openLocationPopup: null, openSettingsPopup: null, openMatchIdPopup: null, helpPopup: null });
    navigate(`/myProduct/${product_id}/${location_id}/${tempLoc}`)
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlePopupOpen = (popupType, event, comp) => {
    setPopup({ ...popUp, [popupType]: event.currentTarget });
    setCompPrdId(comp)
  };

  const handlePopupClose = () => {
    setPopup({
      openLocationPopup: null,
      openSettingsPopup: null,
      openMatchIdPopup: null,
      helpPopup: null,
    });
    setSwitchStatus({
      monitor: {
        status: false,
        error: false
      },
      notification: {
        status: false,
        error: false
      },
      approved: {
        status: false,
        error: false
      }
    });
    dispatch(resetUpdateProduct());
  };


  const chunkArray = (arr, chunkSize) => {
    const result = [];
    let filteredArr = [];
    if (filters.applied) {
      filteredArr = arr?.filter(comp => filters.competitors.includes(comp.comp_prd_id))
    } else {
      filteredArr = arr;
    }
    if (filteredArr) {
      for (let i = 0; i < filteredArr.length; i += chunkSize) {
        result.push(filteredArr.slice(i, i + chunkSize));
      }
    }
    return result;
  }

  const carasoulArray = chunkArray(competitorsData, cardsPerPage);
  const totalPages = carasoulArray?.length;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const updateCompScore = () => {
    const queryString = `?comp_prd_id=${compPrdId}&comp_prd_score=${selectedMatchRate}`
    dispatch(updateScore(token, queryString));
    handlePopupClose();
  }

  const handleAccordionClick = (accordionKey) => {
    setAccordionsExpanded((prevExpanded) => ({
      ...prevExpanded,
      [accordionKey]: !prevExpanded[accordionKey],
    }));
  };

  const handleSeeMoreRating = (event) => {
    event.stopPropagation();
    setRating(!rating);
  };

  const handleSeeMoreDesc = (event) => {
    event.stopPropagation();
    setDescSeeMore(!descSeeMore);
  };

  const handleSeeMorePromotion = (event) => {
    event.stopPropagation();
    setPromotionSeeMore(!promotionSeeMore);
  };

  const handleSeeMoreProduct = (event) => {
    event.stopPropagation();
    setProductSeeMore(!productSeeMore);
  };

  const handleChangeMatchrate = (event) => {
    setSelectedMatchRate(event.target.value);
  }

  const openDialog = (type) => {
    const tempState = { ...dialogState, [type]: true }
    setDialogState(tempState)
    handlePopupClose();
  }

  const handleChangeProduct = (type) => {
    let newProductId;

    if (type === 'previousPdt') {
      newProductId = currentDataIndex > 0 ? allPdtIds[currentDataIndex - 1] : null;
    } else if (type === 'nextPdt') {
      newProductId = currentDataIndex < (allPdtIds?.length ?? 0) - 1 ? allPdtIds[currentDataIndex + 1] : null;
    }

    if (newProductId !== null) {
      dispatch(fetchProductDetails(token, newProductId, qa_user, company_id));
      navigate(`/myProduct/${newProductId}/${productString.prd_loc_id}/${productString.comp_loc_id}`);
    }
  };

  const handleMonitorChange = (event) => {
    const newCheckedValue = event.target.checked;
    setSwitchStatus(prevState => ({
      ...prevState,
      monitor: {
        status: true,
        error: false
      }
    }));
    let newValues = {
      prd_id: productDetails?.data?.prd_id,
      modified_date: productDetails?.data?.modified_date || '',
      loc_id: productDetails?.data?.locations?.[0].loc_id,
      prd_sales_price: productDetails?.data?.locations?.[0].prd_sales_price,
      prd_mrp: productDetails?.data?.locations?.[0].prd_mrp,
      prd_no_of_pieces: productDetails?.data?.prd_no_of_pieces,
      prd_offer: productDetails?.data?.locations?.[0]?.prd_offer || '',
      seller_id: productDetails?.data?.locations?.[0]?.seller_id,
      dept_id: productDetails?.data?.dept.dept_id,
      category_id: productDetails?.data?.category_id,
      prd_name: productDetails?.data?.prd_name || '',
      prd_desc: productDetails?.data?.prd_desc || '',
      prd_size: productDetails?.data?.prd_size,
      prd_uom: productDetails?.data?.prd_uom || '',
      prd_url: productDetails?.data?.prd_url || '',
      prd_upc: productDetails?.data?.prd_upc || '',
      prd_asin: productDetails?.data?.prd_asin || '',
      prd_brand: productDetails?.data?.prd_brand || '',
      prd_medium_image: productDetails?.data?.prd_medium_image || '',
      prd_small_image: productDetails?.data?.prd_small_image || '',
      prd_variants: productDetails?.data?.prd_variants || '',
      keyword: productDetails?.data?.keyword || '',
      prd_is_active: productDetails?.data?.prd_is_active,
      prd_spec: productDetails?.data?.prd_spec || '',
      prd_is_crawl_enabled: newCheckedValue,
      prd_rating: productDetails?.data?.prd_rating || 0,
      prd_tot_reviews: productDetails?.data?.prd_tot_reviews,
      prd_is_match_enabled: productDetails?.data?.prd_is_match_enabled,

    }
    dispatch(updateProduct(token, newValues));
  };

  const handleNotificationChange = () => {
    setSwitchStatus(prevState => ({
      ...prevState,
      notification: {
        status: true,
        error: false
      }
    }));
    dispatch(handleEnableNotification(token, { prd_id: product_id }))
  }

  const handleApprovalChange = () => {
    setSwitchStatus(prevState => ({
      ...prevState,
      approved: {
        status: true,
        error: false
      }
    }));
    const queryString = `?prd_id=${product_id}&company_id=${company_id}`
    dispatch(handleToggleApproval(token, queryString));
  }

  const removeCompFromFilter = (comp) => {
    const tempComp = [...removedComp, comp.comp_prd_id];
    setRemovedComp(tempComp);
  }

  const getScrollPos = () => {
    setScrollPos(window.scrollY);
  };

  const handleImageError = (event) => {
    event.target.src = '/assets/not_loaded.png';
  };

  window.addEventListener('scroll', getScrollPos);

  const handleMyproducts = ()=>{
    navigate('/myProducts')
  }

  const handleDeleteClick = (competitor) => {
    setDialogState({
      ...dialogState,
      deleteCompPdt: {
        show: true,
        data: competitor,
        onConfirm: () => removeCompFromFilter(competitor),
      },
    });
  };
  
  useEffect(() => {
    if (compScoreUpdate.data) {
      dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
    }
  }, [compScoreUpdate]) //eslint-disable-line

  useEffect(() => {
    if (!marketPlaces.data) {
      dispatch(fetchMarketplaces(token, qa_user, company_id));
    }
    if (!prdLocations.data) {
      dispatch(getAllProductLocations(token, qa_user, company_id));
    }
    dispatch(fetchCompPrdSettingsLocations(token, qa_user, company_id, product_id))
    dispatch(resetUpdateScore());
    dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
    if (!products?.data?.results && qa_user) {
      let filterString = '';
      let prdBrandInValue = '';
      let prdSearchValueIn = '';

      for (let key in productString) {
        if (key === 'page') {
          filterString = '?' + key + '=' + productString[key];
        } else if (productString[key]) {
          filterString = filterString + '&' + key + '=' + productString[key];
          if (key === 'prd_brand__in') {
            prdBrandInValue = productString[key];
          } else if (key === 'search') {
            prdSearchValueIn = productString[key];
          }
        }
      }
      const encodedPrdBrandInValue = encodeURIComponent(prdBrandInValue);
      const encodedPrdSearchValue = encodeURIComponent(prdSearchValueIn);

      if (filterString.includes('prd_brand__in')) {
        filterString = filterString.replace(prdBrandInValue, encodedPrdBrandInValue);
      }
      if (filterString.includes('search')) {
        filterString = filterString.replace(prdSearchValueIn, encodedPrdSearchValue);
      }

      if (qa_user) {
        filterString = filterString + `&company_id=${company_id}`
      }
      dispatch(fetchProducts(token, filterString, qa_user))
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (updateProductDetails.data) {
      dispatch(resetUpdateProduct());
      dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
      setSwitchStatus(prevState => ({
        ...prevState,
        monitor: {
          status: false,
          error: false
        }
      }));
    } else if (updateProductDetails.error) {
      setSwitchStatus(prevState => ({
        ...prevState,
        monitor: {
          status: false,
          error: true
        }
      }));
    }
  }, [updateProductDetails]) //eslint-disable-line

  useEffect(() => {
    if (enableNotificationDetails.data) {
      dispatch(resetEnableNotification());
      dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
      setSwitchStatus(prevState => ({
        ...prevState,
        notification: {
          status: false,
          error: false
        }
      }));
    } else if (enableNotificationDetails.error) {
      setSwitchStatus(prevState => ({
        ...prevState,
        notification: {
          status: false,
          error: true
        }
      }));
    }
  }, [enableNotificationDetails]) //eslint-disable-line

  useEffect(() => {
    if (toggleApproval.success) {
      dispatch(resetToggleApproval());
      dispatch(fetchProductDetails(token, product_id, qa_user, company_id));
      setSwitchStatus(prevState => ({
        ...prevState,
        approved: {
          status: false,
          error: false
        }
      }));
    } else if (toggleApproval.error) {
      setSwitchStatus(prevState => ({
        ...prevState,
        approved: {
          status: false,
          error: true
        }
      }));
    }
  }, [toggleApproval]) //eslint-disable-line

  const settingsOptionStyle = { display: 'flex', gap: '5px', p: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease', '&:hover': { backgroundColor: themeMode === 'light' ? '#f4d35e' : '#3f526b' } }
  const settingsSwitchStyle = { display: 'flex', gap: '5px', p: '5px' }
  const iconStyle = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1', padding: '4px' }
  const accStyle = { border: themeMode === 'light' ? '0.5px solid #e5e1e1' : '0.5px solid #3f526b', boxShadow: 'none', borderRadius: '11px 11px 0px 0px', }
  const accSummaryStyle = { backgroundColor: themeMode === 'light' ? '#f8f8f8' : '#3f526b', color: themeMode === 'light' ? 'black' : '#E4E3E3', minHeight: '40px !important', height: '40px', borderRadius: themeMode === 'light' ? '11px 11px 0px 0px' : '8px 8px 0px 0px' }
  const expandIconStyle = { color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3' }
  const accMainGrid = { display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }
  const accPdtSubGrid = { width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }
  const accCompSubGrid = { width: '25%', verticalAlign: 'top', p: 1, pl: '12px' }
  const qaBtn = {
    textTransform: 'capitalize', border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
    '&:hover': {
      border: themeMode === 'dark' ? '1px solid #5A5A5A' : '1px solid #E4E3E3',
    }
  }

  return (
    <Grid id='parent_grid'>
      <Navbar selected='myProducts' />
      {dialogState.deleteCompPdt.show && <DeleteDialog dialogState={dialogState} setDialogState={setDialogState} dialogFor={'deleteCompPdt'} />}
      {dialogState.openGrpah && <PriceComparisonDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.openGraphInfo && <OttixInsightsDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.openPdtFilter.show && <ProductDetailsFilterDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.editProduct && <EditProductDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.addComp && <AddCompetitorDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.refresh && <RefreshCompetitorDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.enableMatch && <EnableMatchDialog dialogState={dialogState} setDialogState={setDialogState} productData={productDetails?.data} setSwitchStatus={setSwitchStatus} />}
      {dialogState.modifyKeyword && <ModifyKeywordDialog dialogState={dialogState} setDialogState={setDialogState} productData={productDetails?.data} keywordArray={keywordArray} />}
      {dialogState.editComp && <EditCompetitorDialog dialogState={dialogState} setDialogState={setDialogState} data={selComp} />}
      <Grid container className={`${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Paper sx={{ my: 1, width: '100%', boxShadow: 'none', borderRadius: '0px', background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} >
          {
            qa_user && <Grid pt={1} item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', pr: 5 }} >
              <Button variant='outlined' size='small' sx={qaBtn}
                onClick={() => handleChangeProduct('previousPdt')}
                disabled={(allPdtIds && allPdtIds?.length === 0) || currentDataIndex === 0}
              >Previous</Button>
              <Button variant='outlined' size='small' sx={qaBtn}
                onClick={() => handleChangeProduct('nextPdt')}
                disabled={(allPdtIds && allPdtIds?.length === 0) || currentDataIndex === (allPdtIds?.length - 1)}
              >Next</Button>
            </Grid>
          }
          <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '65px' }} >
            <Grid sx={{ pl: 5 }} item xs={8} >
              <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                <WestIcon onClick={handleMyproducts} sx={{ fontSize: 20, cursor: 'pointer' }} />
                <Typography sx={{ fontSize: '12px', whiteSpace: 'nowrap' }} >My Products</Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
                <Typography sx={{ fontSize: '12px' }} >{productDetails.data ? productDetails.data.prd_name : 'Loading, please wait'}</Typography>
              </Box>
            </Grid>
            <Grid sx={{ pr: 5, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }} item xs={4} >
            <Tooltip title="Click to change" placement="top" >
              <Box onClick={(event) => handlePopupOpen('openLocationPopup', event)}
                sx={{
                  background: locPopup ?  themeMode === 'dark' ? '#f4d35e' : '#f4d35e' : themeMode === 'dark' ? '#f4d35e' : '#f4d35e', cursor: 'pointer', display: 'flex',
                  gap: '3px', alignItems: 'center', borderRadius: '38px', p: '3px', width: 'auto', pr: '12px',
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #e5e1e1', height: '22px', overflow: 'hidden',
                  '&:hover': {
                    background: 'transparent',
                    '& > :first-child': {
                      color: themeMode === 'dark' && '#E4E3E3',
                    },
                    '& > :last-child': { 
                      color: themeMode === 'dark' && '#E4E3E3',
                    },
                  },
                  '&:active': {
                    background: 'transparent',
                  },
                }} >
                <FmdGoodOutlinedIcon sx={{ fontSize: '20px', color: themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' }} />
                <Typography sx={{ fontWeight: '500', fontSize: '11px', color: locPopup ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' }} >
                  {prdLocations?.data?.find(loc => loc.loc_id === Number(location_id))?.loc_name || ''}
                </Typography>
              </Box>
              </Tooltip>
              <Popover
                sx={{
                  mt: '15px', ml: '-50px',
                  '& .MuiPaper-root': {
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '12px', boxShadow: 'none',
                    backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
                    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
                  },
                }}
                id={LocId} open={locPopup} anchorEl={popUp.openLocationPopup}
                onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Grid xs={12} item container >
                  <Grid item xs={6} >
                    <Typography sx={{ pt: '5px', pl: '14px', pb: '2px', fontSize: '14px' }} >Product Location</Typography>
                    <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                    <FormControl sx={{ minWidth: '210px', pt: '3px', pl: '14px', pb: '3px' }}>
                      <RadioGroup value={location_id} onChange={handlePrdLocation} id='location' >
                        {
                          prdLocations.loading ? <Typography sx={{ pt: 1, fontSize: '12px' }} >
                            Loading please wait.
                          </Typography> : prdLocations.error ? <Typography sx={{ pt: 1, fontSize: '12px' }} >
                            Failed to load locations.
                          </Typography> :
                            prdLocations.data?.length === 0 ?
                              <Typography sx={{ pt: 1, fontSize: '12px' }} >
                                No Locations Available.
                              </Typography>
                              : (
                                prdLocations?.data?.map(loc => <FormControlLabel sx={{height: '32px'}} key={loc.loc_id} value={loc.loc_id}
                                  control={<Radio size='small' sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                                  '& .MuiSvgIcon-root': { 
                                    fontSize: '18px'
                                   },
                                }} />} label={<Typography sx={{ fontSize: '13px' }}>{loc.loc_name} {loc.loc_zip && ` (${loc.loc_zip})`}</Typography>} />
                                ))
                        }
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography sx={{ pt: '5px', pb: '2px', fontSize: '14px' }} >Competitors Location</Typography>
                    <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                    <FormControl sx={{ minWidth: '180px', pt: '3px', pb: '3px' }}>
                      <RadioGroup value={comp_loc_id} onChange={handleCompLocation} id='complocation' >
                        {
                          compPrdSettingsLoc.loading ? <Typography sx={{ pt: 1, fontSize: '12px' }} >
                            Loading please wait.
                          </Typography> : compPrdSettingsLoc.error ? <Typography sx={{ pt: 1, fontSize: '12px' }} >
                            Failed to load locations.
                          </Typography> :
                            compPrdSettingsLoc.data?.length === 0 ?
                              <Typography sx={{ pt: 1, fontSize: '12px' }} >
                                No Locations Available.
                              </Typography>
                              : (
                                compPrdSettingsLoc?.data?.map(loc => <FormControlLabel sx={{height: '32px'}} key={loc.loc_id} value={loc.loc_id}
                                  control={<Radio size='small' sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
                                  '& .MuiSvgIcon-root': { 
                                    fontSize: '18px'
                                   },
                                }} />} label={<Typography sx={{ fontSize: '13px' }}>{loc.loc_name} {loc.loc_zip && ` (${loc.loc_zip})`}</Typography>} />)
                              )
                        }
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Popover>
              <Tooltip title="Help" placement="top" >
              <IconButton sx={{...iconStyle, background: helpIdPopup ? '#f4d35e' : 'transparent'}} onClick={(event) => handlePopupOpen('helpPopup', event)} >
                <HelpOutlineOutlinedIcon sx={{ fontSize: '20px', color: helpIdPopup ? '#5A5A5A' : (themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A') }} />
              </IconButton>
              </Tooltip>
              <Popover
                sx={{
                  mt: '15px', ml: '-50px',
                  '& .MuiPaper-root': {
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '10px', boxShadow: 'none', width: '220px',
                    backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
                    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
                  },
                }}
                id={helpId} open={helpIdPopup} anchorEl={popUp.helpPopup}
                onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                  <Grid item xs={12} sx={{ p:1, textAlign: 'center' }} >
                    <Typography sx={{fontSize: '11px'}} >Please email at: <a style={{color: '#559EFF'}} href="mailto:info@ottixhow.com">info@ottixhow.com</a> </Typography>
                  </Grid>
                </Popover>
              <Tooltip title="Product Settings" placement="top" >
              <IconButton sx={{
                ...iconStyle,
                background: settingPopup ? '#f4d35e' : 'transparent'
              }}
                onClick={(event) => handlePopupOpen('openSettingsPopup', event)} >
                <SettingsOutlinedIcon sx={{ fontSize: '20px', color: settingPopup ? themeMode === 'dark' ? '#5A5A5A' : '#5A5A5A' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
              </IconButton>
              </Tooltip>
              <Popover
                sx={{
                  mt: '15px', ml: '-50px',
                  '& .MuiPaper-root': {
                    border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '10px', boxShadow: 'none', width: '220px',
                    backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
                    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
                  },
                }}
                id={settingsId} open={settingPopup} anchorEl={popUp.openSettingsPopup}
                onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Grid item xs={12}>
                  <Grid
                    onClick={() => {
                      setDialogState(prevState => ({
                        ...prevState,
                        editProduct: true, data: productDetails?.data
                      }))
                      handlePopupClose();
                    }}
                    item xs={12} sx={settingsOptionStyle}>
                    <ModeOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Edit</Typography>
                  </Grid>
                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('addComp')}>
                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Add Competitor Product</Typography>
                  </Grid>
                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('refresh')}>
                    <RefreshOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Refresh</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '3px', mb: '3px' }}>
                    <Divider sx={{ width: '80%' }} />
                  </Grid>
                  <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('enableMatch')}>
                    <BeenhereOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }} >Match</Typography>
                  </Grid>
                  {qa_user && <Grid item xs={12} sx={settingsOptionStyle} onClick={() => openDialog('modifyKeyword')}>
                    <KeyIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }} >Modify Keywords</Typography>
                  </Grid>}
                  <Grid item xs={12} sx={{ ...settingsSwitchStyle, alignItems: 'center' }}>
                    <ComputerOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Monitor</Typography>
                    <div style={{ textAlign: 'end', width: '100%' }} >
                      <Switch
                        size='small'
                        defaultChecked={productDetails?.data?.prd_is_crawl_enabled}
                        onChange={handleMonitorChange}
                        disabled={switchStatus.monitor.status || qa_user}
                        sx={{
                          transform: 'scale(0.9)',
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid sx={{ width: '100%' }} >
                    {switchStatus.monitor.error && <Typography sx={{ fontSize: '11px', color: 'red', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Failed to update</Typography>}
                    {switchStatus.monitor.status && <Typography sx={{ fontSize: '11px', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Updating...</Typography>}
                  </Grid>
                  <Grid item xs={12} sx={{ ...settingsSwitchStyle, display: 'flex', alignItems: 'center' }}>
                    <NotificationsNoneOutlinedIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Notification</Typography>
                    <div style={{ textAlign: 'end', width: '100%' }} >
                      <Switch
                        disabled={qa_user}
                        size='small'
                        defaultChecked={productDetails?.data?.prd_notification_enabled}
                        onChange={handleNotificationChange}
                        sx={{
                          transform: 'scale(0.9)',
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid sx={{ width: '100%' }} >
                    {switchStatus.notification.error && <Typography sx={{ fontSize: '11px', color: 'red', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Failed to update</Typography>}
                    {switchStatus.notification.status && <Typography sx={{ fontSize: '11px', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Updating...</Typography>}
                  </Grid>
                  {qa_user && <><Grid item xs={12} sx={{ ...settingsSwitchStyle, display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '11px' }}>Approved</Typography>
                    <div style={{ textAlign: 'end', width: '100%' }} >
                      <Switch
                        size='small'
                        defaultChecked={productDetails?.data?.prd_qa_approved}
                        onChange={handleApprovalChange}
                        sx={{
                          transform: 'scale(0.9)',
                        }}
                      />
                    </div>
                  </Grid>
                    <Grid sx={{ width: '100%' }} >
                      {switchStatus.approved.error && <Typography sx={{ fontSize: '11px', color: 'red', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Failed to update</Typography>}
                      {switchStatus.approved.status && <Typography sx={{ fontSize: '11px', textAlign: 'end', mt: -1, paddingRight: '10px' }} >Updating...</Typography>}
                    </Grid>
                  </>}
                </Grid>
              </Popover>
              <Tooltip title="Ottix Insights" placement="top" >
              <IconButton sx={iconStyle} onClick={() => openDialog('openGraphInfo')}>
                <EqualizerOutlinedIcon sx={{ fontSize: '20px', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
              </IconButton>
              </Tooltip>
              <Tooltip title="Filters" placement="top" >
              <IconButton sx={{ ...iconStyle, background: filters.applied ? '#f4d35e' : 'transparent' }}
                onClick={() => {
                  setDialogState(prevState => ({
                    ...prevState,
                    openPdtFilter: { show: true, data: competitorsData }
                  }));
                  handlePopupClose();
                }}
              >
                <TuneOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid pt={2} item xs={12} container >
            <Grid container item xs={12} sx={{ position: 'sticky', top: 1, zIndex: 9, my: 0, background: themeMode === 'dark' ? '#232d3b' : 'white', height: scrollPos > 0 ? '150px' : '282px', transition: 'height 0.3s ease' }} >
              <Grid item style={{ height: '100%' }} xs={3} sx={{ pl: 5 }} >
                <Card sx={{ backgroundColor: '#fdf8e5', borderRadius: '11px 11px 0 0', boxShadow: 'none', height: scrollPos > 0 ? '150px' : '282px', position: 'relative' }}>
                  {productDetails.loading ?
                    <Grid sx={{ display: 'flex', height: '100%' }}><Typography sx={{ m: 'auto', fontSize: '13px' }}>Loading... Please wait</Typography></Grid> :
                    productDetails.error || !productDetails.data ? <Grid sx={{ display: 'flex', height: '100%' }}>
                      <Typography sx={{ m: 'auto',  }}>Failed to fetch products details</Typography></Grid> :
                      <Grid item container xs={12} sx={{ p: '15px' }} >
                        <Grid item xs={3} >
                          <img alt='img' src={productDetails.data?.prd_medium_image ? productDetails.data?.prd_medium_image : '/assets/no_image.png'} onError={handleImageError}
                            style={{ maxWidth: '70px', maxHeight: '70px', height: 'fit-content', width: '100%', objectFit: 'contain', borderRadius: '11px 11px 0px 0px' }} />
                        </Grid>
                        <Grid item xs={9} sx={{ pl: '20px', textAlign: 'center' }} >
                          <Typography sx={{ fontWeight: 'bold', mb: 1 }} >{currSymb}{priceDetails?.prd_sales_price?.toFixed(2)}</Typography>
                          <Box style={{ p: 1, background: '#ebebeb', color: 'black', width: 'auto', borderRadius: '3px', display: 'inline-block', padding: '5px 8px 5px 13px' }}>
                            <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >MY PRODUCT</h6>
                          </Box>
                        </Grid>
                        <Grid item xs={12} >
                          {productDetails?.data?.prd_name?.length > 85 ? (
                            <Tooltip title={productDetails?.data?.prd_name} placement='top'>
                              <Typography sx={{ fontWeight: '500', fontSize: '12px', mt: '15px' }}>
                                {productDetails?.data?.prd_name.slice(0, 85) + '...'}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', mt: '15px' }}>
                              {productDetails?.data?.prd_name}
                            </Typography>
                          )}
                        </Grid>
                        {scrollPos === 0 && <>
                          <Grid sx={{ pt: '30px', display: 'flex', gap: '5px' }} >
                            <Box style={{ p: 1, background: '#e9e5d3', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                              <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >Quantity: <span style={{ fontWeight: 'bold' }} >{`${productDetails.data?.prd_no_of_pieces ? productDetails.data?.prd_no_of_pieces : '-'}`}</span></h6>
                            </Box>
                            <Box style={{ p: 1, background: '#e9e5d3', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                              <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >Size: <span style={{ fontWeight: 'bold' }} >{`${productDetails.data?.prd_size ? productDetails.data?.prd_size : '-'}`}</span></h6>
                            </Box>
                            <Box style={{ p: 1, background: '#e9e5d3', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                              <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >PPU: <span style={{ fontWeight: 'bold' }} >{`${priceDetails?.prd_norm_price?.toFixed(2)} / ${productDetails?.data?.prd_uom}`}</span></h6>
                            </Box>
                          </Grid>
                        </>}
                      </Grid>}
                  {(scrollPos === 0 && !productDetails?.loading) && (
                    <Grid container justifyContent='flex-start' sx={{ position: 'absolute', bottom: 0, left: 0 }}>
                      {productDetails.data?.prd_qa_approved ? (
                        <VerifiedIcon sx={{ color: themeMode === 'light' ? 'green' : '#1ac93a', fontSize: '20px', pl: 1, pb: 1 }} />
                      ) : (
                        <NewReleasesIcon sx={{ color: 'red', fontSize: '20px', pl: 1, pb: 1 }} />
                      )}
                    </Grid>
                  )}
                </Card>
              </Grid>
              <Grid item container xs={9} pr={5} style={{ height: '100%' }} >
                <Grid sx={{ width: '100%' }} style={{ height: '100%', marginLeft: '19px' }} >{productDetails.loading ?
                  <Grid sx={{ display: 'flex', height: '100%' }}><Typography sx={{ m: 'auto', fontSize: '13px' }}>Loading... Please wait</Typography>
                  </Grid> : competitorsData?.length === 0 ? <Grid sx={{ display: 'flex', height: scrollPos > 0 ? '150px' : '280px' }}>
                    <Typography sx={{ m: 'auto', fontSize: '13px' }}>There are no competitors for this product</Typography></Grid>
                    : <Carousel autoPlay={false} animation='slide' indicators={false} index={currentIndex}
                      onChange={(index) => setCurrentIndex(index)}
                      navButtonsProps={{ style: { display: 'none' } }}  >
                      {carasoulArray?.map((compArrs, index) => (
                        <Grid key={index} item xs={12} sx={{ display: 'flex', gap: 2 }} style={{ height: '100%' }} >
                          {compArrs?.map(competitor => (
                            <Grid key={competitor.comp_prd_id} item xs={4}>
                              <Card sx={{
                                background: themeMode === 'light' ? 'white' : '#3f526b', border: themeMode === 'light' ? '0.5px solid #e5e1e1' : '0.5px solid #3f526b', borderRadius: '11px 11px 0 0', boxShadow: 'none',
                                height: scrollPos > 0 ? '148px' : '280px',
                                filter: !competitor?.comp_prd_is_active && 'blur(1.5px)',
                                pointerEvents: !competitor?.comp_prd_is_active ? 'none' : 'auto',
                              }} >
                                <Grid item container xs={12} p={2} sx={{ display: 'flex' }} >
                                  <Grid item xs={4} style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <img alt='img' src={competitor.comp_prd_medium_image ? competitor.comp_prd_medium_image : '/assets/no_image.png'} onError={handleImageError}
                                      style={{ maxWidth: '70px', maxHeight: '70px', height: 'fit-content', width: '100%', objectFit: 'contain', borderRadius: '11px 11px 0px 0px' }} />
                                  </Grid>
                                  <Grid item xs={6} pl={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                    <Typography sx={{ fontWeight: 'bold', color: themeMode === 'light' ? 'black' : '#E4E3E3' }} >{currSymb}{competitor.comp_prd_sales_price?.toFixed(2)}</Typography>
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 13px' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >{competitor.market_places?.market_place_name ? competitor?.market_places?.market_place_name : '-'}</h6>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {qa_user && <IconButton onClick={() => { setSelComp(competitor); openDialog('editComp') }} sx={{ position: 'relative', top: '-13px', width: '25px', height: '25px' }}>
                                      <ModeEditOutlineIcon sx={{ color: themeMode === 'light' ? '#868685' : '#E4E3E3', fontSize: '15px' }} />
                                    </IconButton>}
                                    <IconButton onClick={() => handleDeleteClick(competitor)} style={{
                                      position: 'relative', top: '-13px', right: '-3px', width: '25px', height: '25px'
                                    }}>
                                      <CloseIcon sx={{ color: themeMode === 'light' ? '#868685' : '#E4E3E3', fontSize: '15px' }} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ height: '60px', overflowY: 'hidden' }} >
                                  {competitor?.comp_prd_name?.length > 85 ? (
                                    <Tooltip title={competitor?.comp_prd_name} placement='top'>
                                      <Typography
                                        sx={{ color: themeMode === 'light' ? 'black' : 'white', fontWeight: '500', fontSize: '12px', mx: 1, mt: '15px', cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => {
                                          window.open(competitor?.comp_prd_url, '_blank');
                                        }}>
                                        {competitor?.comp_prd_name?.slice(0, 80) + '...'}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      sx={{
                                        color: themeMode === 'light' ? 'black' : 'white', fontWeight: '500', fontSize: '12px',
                                        mx: 1, mt: '15px', cursor: 'pointer', textDecoration: 'underline'
                                      }}
                                      onClick={() => {
                                        window.open(competitor?.comp_prd_url, '_blank');
                                      }}
                                    >{competitor?.comp_prd_name} </Typography>)}
                                </Grid>
                                {scrollPos === 0 && <>
                                  <Grid item xs={12} sx={{ pl: 1, display: 'flex', gap: '10px' }} >
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >Quantity: <span style={{ fontWeight: 'bold' }} >{`${competitor?.comp_prd_no_of_pieces ? competitor?.comp_prd_no_of_pieces : '-'}`}</span></h6>
                                    </Box>
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >Size: <span style={{ fontWeight: 'bold' }} >{`${competitor?.comp_prd_size ? competitor?.comp_prd_size : '-'}`}</span></h6>
                                    </Box>
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 8px' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >PPU: <span style={{ fontWeight: 'bold' }} >{`${Number(competitor.comp_prd_norm_price)?.toFixed(2)} / ${competitor.comp_prd_norm_price_uom ? competitor.comp_prd_norm_price_uom : competitor.comp_prd_uom}`}</span></h6>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sx={{ display: 'flex', gap: 2, my: 2, pl: 1 }} >
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 8px' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '3px' }} >Match Rate: <span style={{ fontWeight: 'bold' }} >{competitor.comp_prd_score ? `${Math.round(competitor.comp_prd_score)}%` : '-'}</span>
                                        {!qa_user && <ModeEditOutlineIcon sx={{ fontSize: '13px', cursor: 'pointer' }} onClick={(event) => handlePopupOpen('openMatchIdPopup', event, competitor.comp_prd_id)} />} </h6>
                                    </Box>
                                    <Popover
                                      sx={{
                                        mt: '15px', ml: '-116px',
                                        '& .MuiPaper-root': {
                                          p: '10px', border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A', borderRadius: '12px', boxShadow: 'none', width: '200px'
                                        },
                                      }}
                                      id={matchId} open={matchIdPopup} anchorEl={popUp.openMatchIdPopup}
                                      onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                                      <Grid item container xs={12} >
                                        <Typography mb={1} sx={{ fontSize: '13px' }} >Match Rate</Typography>
                                        <FormControl fullWidth>
                                          <Select
                                            sx={{ border: themeMode === 'dark' ? '1px solid #858584' : '1px solid #5A5A5A', '& .MuiOutlinedInput-input': { p: '6px', fontSize: '11px' } }}
                                            id='matchRate' label='matchRate' value={selectedMatchRate} onChange={handleChangeMatchrate}
                                            inputProps={{ name: 'matchRate' }}
                                            MenuProps={{
                                              PaperProps: {
                                                className: `custom-scrollbar-${themeMode}`,
                                              },
                                              MenuListProps: {
                                                sx: {
                                                  backgroundColor: themeMode === 'dark' ? '#232d3b' : 'white',
                                                  color: themeMode === 'dark' ? '#e4e3e3' : 'black',
                                                  pt: '0px',
                                                  pb: '0px',
                                                },
                                              },
                                            }}
                                          >
                                            {percentageValues?.map((value, index) => (
                                              <MenuItem sx={{ fontSize: '13px' }} id={`menu-item-${index}`} key={value} value={value}>{value}%</MenuItem>
                                            ))}
                                          </Select>
                                          <Button disableRipple onClick={updateCompScore} sx={{
                                            backgroundColor: '#f4d35e', textTransform: 'capitalize', fontSize: '13px', color: 'black', mt: '10px', '&:hover': {
                                              backgroundColor: '#f4d35e', fontSize: '13px'
                                            }
                                          }}
                                            variant='contained'>Update</Button>
                                        </FormControl>
                                      </Grid>
                                    </Popover>
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 8px' }}>
                                      <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >CPI: <span style={{ fontWeight: 'bold' }} >{competitor.comp_prd_index ? Number(competitor.comp_prd_index).toFixed(2) : '-'}</span></h6>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sx={{ display: 'flex', gap: '10px', alignItems: 'center', pl: 1 }} >
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', alignItems: 'center', padding: '5px 8px 5px 8px', width: 'fit-content' }}>
                                    {
                                      competitor?.seller?.seller_name?.length > 18 ? 
                                        <Tooltip title={<span style={{ textTransform: 'capitalize' }}>{ competitor?.seller?.seller_name}</span>} placement='top'>
                                          <h6 style={{ fontWeight: 'normal', fontSize: '11px', textTransform: 'capitalize' }}>
                                            Seller: <span style={{ fontWeight: 'bold' }}>{`${competitor?.seller?.seller_name.slice(0, 18)}...`}</span>
                                          </h6>
                                        </Tooltip>
                                        :
                                        <h6 style={{ fontWeight: 'normal', fontSize: '11px', textTransform: 'capitalize' }}>
                                          Seller: <span style={{ fontWeight: 'bold' }}>{`${competitor?.seller?.seller_name? competitor?.seller?.seller_name : '-'}`}</span>
                                        </h6>
                                    }
                                    </Box>
                                    <Box style={{ p: 1, background: themeMode === 'light' ? '#ebebeb' : '#232d3b', color: themeMode === 'light' ? 'black' : 'white', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 8px 5px 8px' }}>
                                      {
                                      competitor?.comp_prd_id?.toString().length > 10 ? 
                                        <Tooltip title={competitor?.comp_prd_id} placement='top'>
                                          <h6 style={{ fontWeight: 'normal', fontSize: '11px', textTransform: 'capitalize' }}>
                                          Product-ID: <span style={{ fontWeight: 'bold' }}>{`${competitor?.comp_prd_id?.toString().slice(0, 10)}...`}</span>
                                          </h6>
                                        </Tooltip>
                                        :
                                        <h6 style={{ fontWeight: 'normal', fontSize: '11px' }} >Product-ID: <span style={{ fontWeight: 'bold' }} >{competitor.comp_prd_id ? competitor.comp_prd_id : ''}</span></h6>
                                      }
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography sx={{ fontSize: '10px', color: themeMode === 'light' ? '#868685' : '#E4E3E3', m: 1, fontStyle: 'italic' }}>
                                      Last updated on: {moment(competitor.modified_date).format('MM-DD-YYYY, h:mm A')}</Typography>
                                    <VerifiedIcon sx={{ color: themeMode === 'light' ? 'green' : '#1ac93a', fontSize: '20px', pr: 1 }} />
                                  </Grid></>}
                              </Card>
                            </Grid>))}
                        </Grid>))}</Carousel>
                }
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ height: '10px', background: themeMode === 'light' ? '#ffff' : '#232d3b', width: '100%', display: scrollPos > 0 ? 'block' : 'none' }} ></Grid>
            </Grid>
            {qa_user && <Grid mt={2} px={5} item xs={12} >
              <Accordion style={accStyle}
                expanded={accordionsExpanded.qaKeyword} onClick={() => handleAccordionClick('qaKeyword')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconStyle} />} aria-controls='panel1a-content' id='panel1a-header' sx={accSummaryStyle} >
                  <Typography sx={{ fontSize: '13px' }}>Keywords</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, position: 'relative', background: themeMode === 'light' ? '#ffff' : '#232d3b', color: themeMode === 'light' ? '#3f526b' : '#E4E3E3' }} >
                  {/* {keywordArray?.length === 0 ? <Typography sx={{ fontSize: '11px', textAlign: 'center', my: 2 }}>There is no keywords added</Typography> :
                    keywordArray?.map((item, index) => <Grid key={index} sx={{ display: 'flex', width: '100%', borderTop: '1px solid #DCDCDC' }}>
                      <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1, fontSize: '11px', textTransform: 'capitalize' }} >{Object.keys(item)[0]}</Grid>
                      <Grid sx={{ fontSize: '11px', p: 1 }}>{Object.values(item)[0]}</Grid>
                    </Grid>)} */}
                    {Object.keys(keywordArray).length === 0 ? (
                      <Typography sx={{ fontSize: '11px', textAlign: 'center', my: 2 }}>
                        There are no keywords added
                      </Typography>
                    ) : (
                      Object.entries(keywordArray).map(([key, value], index) => (
                        <Grid key={index} sx={{ display: 'flex', width: '100%', borderTop: '1px solid #DCDCDC' }}>
                          <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1, fontSize: '11px', textTransform: 'capitalize' }}>
                            {key}
                          </Grid>
                          <Grid sx={{ fontSize: '11px', p: 1 }}>{value.join(", ")}</Grid>
                        </Grid>
                      ))
                    )}
                </AccordionDetails>
              </Accordion>
            </Grid>}
            <Grid mt={2} sx={{ display: 'flex', alignItems: 'center' }} container item xs={12} >
              <Grid item xs={3} sx={{ display: 'flex', gap: '15px' }} >
                <Typography onClick={() => handleTabClick('marketdata')} sx={{ fontSize: '15px', ml: '55px', cursor: 'pointer', color: activeTab === 'marketdata' ? '#eb7900' : '#868685', borderBottom: activeTab === 'marketdata' ? '1px solid #eb7900' : 'none' }} >Market Insights</Typography>
                {/* <Tooltip title='Coming Soon...' placement='top' >
                  <Typography sx={{ fontSize: '15px', cursor: 'pointer', color: activeTab === 'insight' ? '#eb7900' : '#868685', borderBottom: activeTab === 'insight' ? '1px solid #eb7900' : 'none' }} >Ottix Insight</Typography>
                </Tooltip> */}
              </Grid>
              <Grid item xs={7}>
                <Typography sx={{ fontSize: '12px', pr: 4, textAlign: 'right' }}>
                  {competitorsData && competitorsData?.length > 0 ? `Total ${competitorsData?.length} Products` : 'No Products Found.'}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', }} style={{ position: 'relative', zIndex: 3 }}>
                <IconButton size='small' sx={{
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                  opacity: currentIndex <= 0 ? 0.5 : undefined, mr: 3
                }}
                  disabled={currentIndex <= 0} onClick={handlePrev}>
                  <ArrowBackIosOutlinedIcon sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                </IconButton>
                <IconButton size='small' sx={{
                  border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                  opacity: currentIndex >= totalPages - 1 ? 0.5 : undefined
                }}
                  disabled={currentIndex >= totalPages - 1}
                  onClick={handleNext}>
                  <ArrowForwardIosIcon sx={{ color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }} />
                </IconButton>
              </Grid>
            </Grid>
            {activeTab === 'marketdata' && (
              <>
                <Grid mt={2} px={5} item xs={12} >
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.product} >
                    <AccordionSummary onClick={() => handleAccordionClick('product')} expandIcon={<ExpandMoreIcon sx={expandIconStyle} />} aria-controls='panel1a-content' id='panel1a-header' sx={accSummaryStyle} >
                      <Typography sx={{ fontSize: '13px' }}>Product Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: '0px', minHeight: '270px', position: 'relative', maxHeight: productSeeMore ? 'none' : '100px', overflow: 'hidden', background: themeMode === 'light' ? '#ffff' : '#232d3b', color: themeMode === 'light' ? '#3f526b' : '#E4E3E3' }} >
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1, }}>
                          <Typography sx={{ fontSize: '10px' }}>Department</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }}>{productDetails.data?.dept?.dept_name || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>Department</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }}>{competitor?.comp_dept_name || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>Category</Typography>
                          <Typography sx={{ fontSize: '13px', pt: '4px', textTransform: 'capitalize' }} >{productDetails.data?.category?.category_name || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>Category</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{competitor?.comp_category_name || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >Product Brand</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{productDetails.data?.prd_brand || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Brand</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{competitor?.comp_prd_brand || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>Product Availability</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }}>{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>Product Availability</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }}>{competitor?.comp_prd_availability_status === true ? 'Yes' : 'No'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>Product Specification</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }}>{productDetails.data?.prd_spec || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>Product Specification</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }}>{competitor?.comp_prd_spec || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }}>Variant</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }}>{productDetails.data?.prd_variants || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }}>Variant</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }}>{competitor?.comp_prd_variants || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >Product Size</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{productDetails.data?.prd_size || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Size</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{competitor?.comp_prd_size || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={{ display: 'flex', width: '100%', borderBottom: '1px solid #DCDCDC' }}>
                        <Grid sx={{ width: '25%', verticalAlign: 'top', borderRight: '1px solid #DCDCDC', p: 1 }}>
                          <Typography sx={{ fontSize: '10px' }} >Product ASIN/SKU/UPC</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{productDetails.data?.prd_asin || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product ASIN/SKU/UPC</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{competitor?.comp_prd_asin || '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      {productSeeMore && (
                        <div style={{ background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreProduct}>View Less</Typography>
                        </div>
                      )}
                      {!productSeeMore && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreProduct}>View More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid mt={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.description} >
                    <AccordionSummary onClick={() => handleAccordionClick('description')} expandIcon={<ExpandMoreIcon sx={expandIconStyle} />} aria-controls='panel1a-content' id='panel1a-header' sx={accSummaryStyle} >
                      <Typography sx={{ fontSize: '13px' }}>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, minHeight: '300px', position: 'relative', maxHeight: descSeeMore ? 'none' : '100px', overflow: 'hidden', background: themeMode === 'light' ? '#ffff' : '#232d3b', color: themeMode === 'light' ? '#3f526b' : '#E4E3E3' }} >
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Description</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >
                            {productDetails.data?.prd_desc ? productDetails.data?.prd_desc : 'No description is available'}
                          </Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Description</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >
                              {competitor.comp_prd_desc ? competitor.comp_prd_desc : 'No description is available'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Word Count</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{productDetails?.data?.prd_desc ? productDetails?.data?.prd_desc?.split(/\s+/).filter(word => word?.length > 0).length : '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Word Count</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{competitor?.comp_prd_desc ? competitor?.comp_prd_desc?.split(/\s+/).filter(word => word?.length > 0).length : '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Keywords</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Keywords</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      {/* <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Important Keywords</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Important Keywords</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid> */}
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Keyword Score</Typography>
                          <div style={{ display: 'flex' }}>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                          </div>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Keyword Score</Typography>
                            <div style={{ display: 'flex' }}>
                              <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      {descSeeMore && (
                        <div style={{ background: themeMode === 'light' ? 'white' : '#3f526b' }} >
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreDesc}>View Less</Typography>
                        </div>
                      )}
                      {!descSeeMore && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreDesc}>View More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid mt={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.promotion} >
                    <AccordionSummary onClick={() => handleAccordionClick('promotion')} expandIcon={<ExpandMoreIcon sx={expandIconStyle} />} aria-controls='panel1a-content' id='panel1a-header' sx={accSummaryStyle} >
                      <Typography sx={{ fontSize: '13px' }}>Promotions & Sales Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: '0px', minHeight: '270px', position: 'relative', maxHeight: promotionSeeMore ? 'none' : '100px', overflow: 'hidden', background: themeMode === 'light' ? '#ffff' : '#232d3b', color: themeMode === 'light' ? '#3f526b' : '#E4E3E3' }} >
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Description</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Description</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Product Tags</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Tags</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Product Stock Availability</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{productDetails.data?.prd_is_active ? 'Available' : '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Stock Availability</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{competitor?.comp_prd_stock_available ? 'Available' : '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Product Delivery Time</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Delivery Time</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Product Average Delivery Charges</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Average Delivery Charges</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Product Sales</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Product Sales</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px', textTransform: 'capitalize' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      {promotionSeeMore && (
                        <div style={{ background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMorePromotion}>View Less</Typography>
                        </div>
                      )}
                      {!promotionSeeMore && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMorePromotion}>View More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid mt={2} my={2} px={5} item xs={12}>
                  <Accordion style={accStyle}
                    expanded={accordionsExpanded.rating} >
                    <AccordionSummary onClick={() => handleAccordionClick('rating')} expandIcon={<ExpandMoreIcon sx={expandIconStyle} />} aria-controls='panel1a-content' id='panel1a-header' sx={accSummaryStyle} >
                      <Typography sx={{ fontSize: '13px' }} > Ratings & Feedback</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, minHeight: '250px', position: 'relative', maxHeight: rating ? 'none' : '100px', overflow: 'hidden', background: themeMode === 'light' ? '#ffff' : '#232d3b', color: themeMode === 'light' ? '#3f526b' : '#E4E3E3' }} >
                      <Grid sx={accMainGrid} >
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >Rating</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{productDetails.data?.prd_rating || '--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >Rating</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{competitor.comp_prd_rating ? Math.round(competitor.comp_prd_rating) : '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >#Positive Feedback</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >#Positive Feedback</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <Typography sx={{ fontSize: '10px' }} >#Negative Feedback</Typography>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <Typography sx={{ fontSize: '10px' }} >#Negative Feedback</Typography>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <Typography sx={{ fontSize: '10px' }} >Positive Rating</Typography>
                            <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >Latest</Typography>
                          </div>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                              <Typography sx={{ fontSize: '10px' }} >Positive Rating</Typography>
                              <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >Latest</Typography>
                            </div>
                            <Typography sx={{ fontSize: '11px', pt: '4px' }} >{ competitor.comp_prd_positive_ratings ? competitor?.comp_prd_positive_ratings : '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid sx={accMainGrid}>
                        <Grid sx={accPdtSubGrid}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <Typography sx={{ fontSize: '10px' }} >Negative Rating</Typography>
                            <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >Latest</Typography>
                          </div>
                          <Typography sx={{ fontSize: '11px', pt: '4px' }} >{'--'}</Typography>
                        </Grid>
                        {carasoulArray[currentIndex]?.map((competitor, index) => (
                          <Grid key={index} sx={accCompSubGrid}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                              <Typography sx={{ fontSize: '10px' }} >Negative Rating</Typography>
                              <Typography sx={{ fontSize: '10px', color: '#fdaf5c' }} >Latest</Typography>
                            </div>
                            <Typography sx={{ fontSize: '11px', p: '4px' }} >{competitor.comp_prd_negative_ratings ? competitor?.comp_prd_negative_ratings : '--'}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      {rating && (
                        <div style={{ background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ p: '5px', color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSeeMoreRating}>View Less</Typography>
                        </div>
                      )}
                      {!rating && (
                        <div style={{ position: 'absolute', bottom: '0px', width: '100%', borderRadius: '0px', background: themeMode === 'light' ? 'white' : '#3f526b' }}>
                          <Typography sx={{ color: '#559eff', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer', p: '5px' }} onClick={handleSeeMoreRating}>View More</Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            )}
            {activeTab === 'insight' && (
              <Grid py={5}>
                <Typography sx={{ p: 8, textTransform: 'capitalize' }} >No data available.</Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default ProductDetails;