import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './signUp.css'
import ChangeClientDialog from '../../components/ChangeClientDialog';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUpUser } from '../../redux/actions/singUp.actions';

const SignUp = () => {

  const navigate = useNavigate()

  const [dialogState, setDialogState] = useState(false);
  const dispatch = useDispatch()
  const signUpDetails = useSelector(state=>state.signUpDetails)

  const [error, setError] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().min(3, 'Too short - should be 6 character minimum!').email('Invalid email').required('Required'),
    firstName: Yup.string().trim().min(3, 'Too short - should be 3 characters minimum!')
    .max(50, 'Too long - should be 50 characters maximum!').matches(/^[A-Za-z]+$/, 'Only allowed alphabetic').required('Required'),
    lastName: Yup.string().trim()
    .min(1, 'Too short - should be 1 character minimum!')
    .max(50, 'Too long - should be 50 characters maximum!')
    .matches(/^[A-Za-z]+$/, 'Only allowed alphabetic')
    .required('Required'),
    compName: Yup.string().trim()
    .max(100, 'Too long - should be 100 characters maximum!')
    .matches(/^[A-Za-z0-9 ]+$/, 'Not allowed special symbols')
    .required('Required'),
    phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(/^\+?[1-9]\d{1,14}$/, 'must be a valid format (e.g., +12345678901)'),
    password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords must match'),
    noOfmarketplaces: Yup.number()
    .typeError('Must be a number')
    .required('Marketplaces are required')
    .min(1, 'Number of Marketplaces must be at least 1'),
    marketplaces: Yup.string()
      .required('Marketplaces are required')
      .trim()
      .matches(/^\S.*$/, 'Cannot contain only whitespace'),
    products: Yup.number()
      .typeError('Must be a number')
      .required('Number of Products is required')
      .min(1, 'Number of Products must be at least 1')
  })

  const handleFormSubmit = (values) => {
    if(values){
      let newValues = {
        company_name: values.compName,
        admin_approved: false,
        mp_domains: values.marketplaces,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber,
        password: values.password,
        no_of_produts_allowed: values.products,
        no_of_mp_allowed: values.noOfmarketplaces
      }
      dispatch(signUpUser(newValues))
    }
  }

  const handleNext = async (validateForm) => {
    const errors = await validateForm();
    if (
      !errors.email &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.compName &&
      !errors.phoneNumber &&
      !errors.password &&
      !errors.confirmPassword
    ) {
      setCurrentStep(currentStep + 1);
    }
  };
  
  const handlePrevious = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  useEffect(() => {
    if(signUpDetails?.data?.status === 'success'){
      setSuccessMsg(true)
    } else if(signUpDetails?.error){
      setError(true)
    }
  }, [signUpDetails]) //eslint-disable-line
  

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      color: '#E4E3E3',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
    },
  }));

  return (
    <>
      {dialogState && <ChangeClientDialog title='Select Client' dialogState={dialogState} setDialogState={setDialogState} />}
      <Grid container className='loginBackground'>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={12} md={7} sx={{ display: 'flex' }}>
          </Grid>
          <Grid item xs={12} sm={12} md={5}
            sx={{ backgroundColor: 'transparent', flexDirection: 'column', justifyContent: 'center', minHeight: 'calc(100vh)', margin: '0px auto', padding: '0px 80px', display: 'flex', alignItems: 'center' }}>
            <Box>
              <Paper style={{ background: 'none', boxShadow: 'none' }}>
                <Grid item sx={{ display: 'flex', mb: 1.5, justifyContent: 'center' }}>
                  <img src='/assets/Logo-removebg-preview.png' style={{ width: '100%', mb: 1, marginLeft: '-1em', height: '5em', objectFit: 'contain' }} alt='logo' />
                </Grid>

                {
                  !successMsg && (
                    <>
                    <Typography variant='h4' component='h4' sx={{ color: '#E4E3E3', fontSize: '24px', textAlign: 'center' }}> Sign-Up</Typography>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', marginTop: '-15px'}}>
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={{ 
                      email: '', firstName: '', lastName: '', compName: '', phoneNumber: '', password: '', confirmPassword: '',
                      noOfmarketplaces:'', marketplaces: '', products: ''
                    }}
                    validationSchema={formSchema}>
                    {({ errors, setFieldValue, values, handleSubmit, validateForm }) => (
                      <Form className='loginform' onSubmit={handleSubmit}>
                        {currentStep === 1 && (
                          <>
                            <Grid sx={{ display: 'flex', gap: '5px', marginBottom: '5px' }}>
                              <FormControl variant='standard' sx={{ width: '100%' }}>
                                <InputLabel shrink htmlFor='firstName' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Email*
                                </InputLabel>
                                <BootstrapInput
                                  id='email' autoComplete='off'
                                  value={values.email} placeholder='Enter your email'
                                  onChange={e => setFieldValue('email', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.email}</Typography>
                              </FormControl>
                            </Grid>
                            <Grid sx={{ display: 'flex', gap: '5px' }}>
                              <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='firstName' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  First Name*
                                </InputLabel>
                                <BootstrapInput
                                  id='firstName' autoComplete='off'
                                  value={values.firstName} placeholder='Enter your first name'
                                  onChange={e => setFieldValue('firstName', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.firstName}</Typography>
                              </FormControl>
                              <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='lastName' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Last Name*
                                </InputLabel>
                                <BootstrapInput
                                  id='lastName' autoComplete='off'
                                  value={values.lastName} placeholder='Enter your last name'
                                  onChange={e => setFieldValue('lastName', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.lastName}</Typography>
                              </FormControl>
                            </Grid>
                        
                            <Grid sx={{ display: 'flex', gap: '5px', mt: 1 }}>
                            <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='phoneNumber' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Phone Number*
                                </InputLabel>
                                <BootstrapInput
                                  id='phoneNumber' autoComplete='off'
                                  value={values.phoneNumber} placeholder='Enter your phone number'
                                  onChange={e => setFieldValue('phoneNumber', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.phoneNumber}</Typography>
                              </FormControl>
                              <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='compName' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Company Name
                                </InputLabel>
                                <BootstrapInput
                                  id='compName' autoComplete='off'
                                  value={values.compName} placeholder='Enter your company name'
                                  onChange={e => setFieldValue('compName', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.compName}</Typography>
                              </FormControl>
                            </Grid>
                        
                            <Grid sx={{ display: 'flex', gap: '5px', mt: 1 }}>
                              <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='password' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Password*
                                </InputLabel>
                                <BootstrapInput
                                  id='password' type={showPassword ? 'text' : 'password'} autoComplete='off'
                                  value={values.password} placeholder='Enter your password'
                                  onChange={e => setFieldValue('password', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                  endAdornment={
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={() => setShowPassword(prev => !prev)}
                                        edge='end' sx={{ marginLeft: '-40px' }}>
                                        {showPassword ? <VisibilityOff sx={{ color: "white", fontSize: "20px" }} /> : <Visibility sx={{ color: "white", fontSize: "20px" }} />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.password}</Typography>
                              </FormControl>
                              <FormControl variant='standard' sx={{ width: '50%' }}>
                                <InputLabel shrink htmlFor='confirmPassword' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Confirm Password*
                                </InputLabel>
                                <BootstrapInput
                                  id='confirmPassword' type={showConfirmPassword ? 'text' : 'password'} autoComplete='off'
                                  value={values.confirmPassword} placeholder='Enter password again'
                                  onChange={e => setFieldValue('confirmPassword', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                  endAdornment={
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={() => setShowConfirmPassword(prev => !prev)}
                                        edge='end' sx={{ marginLeft: '-40px' }}>
                                        {showConfirmPassword ? <VisibilityOff sx={{ color: "white", fontSize: "20px" }} /> : <Visibility sx={{ color: "white", fontSize: "20px" }} />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.confirmPassword}</Typography>
                              </FormControl>
                            </Grid>
                        
                            <Grid sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant='body2' component={'p'} sx={{ color: '#E4E3E3', fontSize: '11px', marginRight: '80px' }}>
                                Already have an account?<span style={{cursor:'pointer'}} onClick={()=>navigate('/')} > Login</span></Typography>
                                <Button size="small" variant='contained' sx={{ height: '30px', fontSize: '12px' }} onClick={()=>handleNext(validateForm)}
                                disabled={!!(errors.email || errors.firstName || errors.lastName || errors.compName || errors.phoneNumber || errors.password || errors.confirmPassword)}
                                >Next</Button>
                            </Grid>
                          </>
                        )}

                        {currentStep === 2 && (
                          <>
                          <FormControl variant='standard' sx={{ width: '100%' }}>
                                <InputLabel shrink htmlFor='marketplaces' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  Marketplace Names*
                                </InputLabel>
                                <Typography sx={{color: '#E4E3E3', fontSize: '9px', mt: '12px', mb: '4px'}}>(Please use commas for adding more marketplaces)</Typography>
                                <BootstrapInput
                                  id='marketplaces' autoComplete='off'
                                  value={values.marketplaces} placeholder='Enter the marketplaces'
                                onChange={(e) => {
                                    const marketplacesArray = e.target.value.split(',');
                                    const filteredMarketplaces = marketplacesArray.filter(marketplace => marketplace.trim()!== '');
                                    const marketplacesCount = filteredMarketplaces.length;
                                    setFieldValue('noOfmarketplaces', marketplacesCount);
                                    setFieldValue('marketplaces', e.target.value);
                                  }}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.marketplaces}</Typography>
                            </FormControl>
                              <FormControl variant='standard' sx={{ width: '100%', mt: 1 }}>
                                <InputLabel shrink htmlFor='noOfmarketplaces' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  No of Marketplaces*
                                </InputLabel>
                                <BootstrapInput
                                  id='noOfmarketplaces' autoComplete='off'
                                  value={values.noOfmarketplaces} placeholder='Enter the number of marketplaces'
                                onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                readOnly
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.noOfmarketplaces}</Typography>
                              </FormControl>
                              <FormControl variant='standard' sx={{ width: '100%', mt: 1 }}>
                                <InputLabel shrink htmlFor='products' sx={{ color: '#E4E3E3', fontSize: '13px' }}>
                                  No of Products*
                                </InputLabel>
                                <BootstrapInput
                                  id='products' autoComplete='off'
                                  value={values.products} placeholder='Enter the number of products'
                                  onChange={e => setFieldValue('products', e.target.value)}
                                  onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit() }}
                                />
                                <Typography color='red' variant='subtitle' sx={{ fontSize: '10px' }}>{errors.products}</Typography>
                              </FormControl>
                              <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', mt: 1 }}>
                                <Button size="small" variant='contained' sx={{ height: '30px', fontSize: '12px' }} onClick={handlePrevious}>Previous</Button>
                                <Button size="small" variant='contained' sx={{ height: '30px', fontSize: '12px' }} type='submit'>{signUpDetails?.loading ? 'Submitting...' : 'Submit'}</Button>
                              </Grid>
                                {error && (
                                  <Typography color="red" variant="subtitle" sx={{ fontSize: '12px', textAlign: 'center' }}>
                                    {typeof signUpDetails?.error?.response?.data?.detail === 'string' && signUpDetails.error.response.data.detail.includes('already exists')
                                      ? signUpDetails.error.response.data.detail
                                      : typeof signUpDetails?.error?.response?.data?.detail === 'object'
                                      ? 'Please check your entered values.'
                                      : ''}
                                  </Typography>
                                )}


                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                </Grid>   
                    </>
                  )
                }
                
                {
                  successMsg &&
                  <Grid item xs={12}>
                   <Typography 
                     variant="h4" 
                     component="h4" 
                     sx={{ color: '#E4E3E3', fontSize: '18px', textAlign: 'center' }}
                   >
                     You have successfully registered for the OttixHow application. We will verify your account shortly. You will receive an email after verification.
                   </Typography>
                   <Typography 
                     variant="h6" 
                     component="h6" 
                     sx={{ color: '#E4E3E3', fontSize: '12px', textAlign: 'center' }}
                   >
                     For more information about the product, visit our <a href="https://www.ottixhow.com" target="_blank" rel="noopener noreferrer" style={{ color: '#E4E3E3' }}>OttixHow</a>.
                   </Typography>
                 </Grid>
                }
                 

                


              </Paper>
            </Box>
            <Grid item xs={12} sx={{ display: 'flex', width: '40%', bottom: 20, pt: 0, pb: 1, position: 'fixed', justifyContent: 'center', ml: 0 }}>
              <img src='/assets/orbioLogo.png' alt='orbio logo' style={{ width: 30, marginRight: 8 }} />
              <Typography sx={{ color: '#E4E3E3', fontSize: '13px' }}> Powered By <a href='https://www.orbiosolutions.com/' target='_blank' rel='noreferrer' style={{ color: 'inherit' }}>Orbio Solutions</a></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SignUp;