import axios from "axios";
import { paths } from '../../Api/paths';
import { DELETE_ALL_PRODUCTS, DELETE_ALL_PRODUCTS_FAILED, DELETE_ALL_PRODUCTS_SUCCESSFULL, RESET_DELETE_ALL_PRODUCTS } from "../constants/deleteAllProducts.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const deleteAllProducts = () => ({
    type: DELETE_ALL_PRODUCTS
});

const deleteAllProductsSuccess = (data) => ({
    type: DELETE_ALL_PRODUCTS_SUCCESSFULL,
    payload: data
});

const deleteAllProductsFailure = (error) => ({
    type: DELETE_ALL_PRODUCTS_FAILED,
    payload: error
});

export const resetDeleteAllProducts = () => ({
    type: RESET_DELETE_ALL_PRODUCTS
})

export const removeAllProducts = (token, data) => async (dispatch) => {
    try {
      dispatch(deleteAllProducts());
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}${paths.deleteAllProducts}`,
      data, { headers: { Authorization: `bearer ${token}`} });
      dispatch(deleteAllProductsSuccess(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
      dispatch(deleteAllProductsFailure(error));
          }
    }
};