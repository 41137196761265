import { Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function PrivateNationalCount() {


  const themeMode = useSelector(state => state.theme.mode);

  const tableData = [
    { id: '1', dept: 'Baby & Kids Toys', pvtcategory: [{ title: 'Baby & Kids', value: 1 }, { title: 'Toys', value: 2 }, { title: 'Diapers', value: 3 }], natcategory: [{ title: 'Baby & Kids', value: 5 }, { title: 'Toys', value: 2 }, { title: 'Diapers', value: 1 }] },
    { id: '2', dept: 'Beauty', pvtcategory: [{ title: 'Body Wash', value: 4 }, { title: 'Shampoo', value: 1 }, { title: 'Conditioner', value: 6 }], natcategory: [{ title: 'Body Wash', value: 2 }, { title: 'Shampoo', value: 6 }, { title: 'Conditioner', value: 7 }] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
    { id: '3', dept: 'Grocery', pvtcategory: [{ title: 'Groceryitem', value: 8 }, { title: 'Chew & Gum', value: 2 }, { title: 'Chips', value: 3 }, ], natcategory: [{ title: 'Groceryitem', value: 2 }, { title: 'Chew & Gum', value: 3 }, { title: 'Chips', value: 6 }, ] },
  ]

  // Find the maximum value across all categories
  const maxPrivateValue = Math.max(...tableData.flatMap(item => item.pvtcategory.map(cat => cat.value)));
  const maxNatValue = Math.max(...tableData.flatMap(item => item.natcategory.map(cat => cat.value)));

  // Generate scale values from 0 to the maximum value
  const PvtscaleValues = Array.from({ length: maxPrivateValue + 1 }, (_, i) => i);
  const NatscaleValues = Array.from({ length: maxNatValue + 1 }, (_, i) => i);

  // Determine the Pvtinterval for displaying scale values
  const Pvtinterval = Math.ceil(maxPrivateValue / 10); // Adjust the divisor as needed for spacing
  const Natinterval = Math.ceil(maxNatValue / 10); // Adjust the divisor as needed for spacing

  return (
    <Grid container sx={{ border: "2px solid black" }}>
      <Grid item xs={12} sx={{ textAlign: "center", fontWeight:"bold", border:'1px solid black', padding:"8px", backgroundColor: "#070544", color: "white", fontSize:"14px" }}>
        Number of Private Labels vs National Products
      </Grid>
      <div style={{width:"100%",maxHeight:"300px", overflowY:'scroll'}} className={`custom-scrollbar-${themeMode}`} >

      {tableData.map((item, itemIndex) => (
        <Grid item container xs={12} key={item.id} sx={{border:'1px solid black', }}>
          {item.pvtcategory.map((categoryItem, index) => (
            <React.Fragment key={`${item.id}-${index}`}>
              <Grid item xs={1} sx={{padding: '8px', fontSize: '10px', background: 'white' }} rowSpan={item.pvtcategory.length}>{index === 0 ? item.dept : null}</Grid>
              <Grid item xs={1} sx={{padding: '8px', fontSize: '10px', borderRight:'1px solid black' ,background: (itemIndex * item.pvtcategory.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>{categoryItem.title}</Grid>
              <Grid item xs={5} sx={{display:'grid', padding: '8px', borderRight:'1px solid black' ,background: (itemIndex * item.pvtcategory.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <Tooltip title={
                    <>
                      <Typography sx={{fontSize: '10px'}} >Category Name: {categoryItem.title}</Typography>
                      <Typography sx={{fontSize: '10px'}} >Private Label Count: {categoryItem.value}</Typography>
                      <Typography sx={{fontSize: '10px'}} >Total Category Count: {Number(item.natcategory[index].value + categoryItem.value)}</Typography>
                    </>
                  }>
                    <div className="scale-in-hor-left" style={{
                      backgroundColor: '#666666',cursor: "pointer",height: '5px',
                      width: `${(categoryItem.value / maxPrivateValue) * 100}%`,position: 'relative',
                    }}>
                      <div style={{ position: 'absolute', width: '12px', height: '12px',
                        backgroundColor: '#b4b4b4', right: 0, top: '-4px', fontSize: "11px", display:"flex", justifyContent:"center", alignItems:"center"
                      }}>{categoryItem.value}</div>
                    </div>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={5} sx={{display:'grid', padding: '8px', background: (itemIndex * item.pvtcategory.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <Tooltip title={
                    <>
                      <Typography sx={{fontSize: '10px'}} >Category Name: {categoryItem.title}</Typography>
                      <Typography sx={{fontSize: '10px'}} >National Label Count: {item.natcategory[index].value}</Typography>
                      <Typography sx={{fontSize: '10px'}} >Total Category Count: {Number(item.natcategory[index].value + categoryItem.value)}</Typography>
                    </>
                  }>
                    <div className="scale-in-hor-left" style={{
                      backgroundColor: '#d17825',cursor: "pointer",
                      height: '5px',
                      width: `${(item.natcategory[index].value / maxNatValue) * 100}%`,
                      position: 'relative'
                    }}>
                      <div style={{
                        position: 'absolute',width: '12px',
                        height: '12px',backgroundColor: '#f9a655',
                        right: 0, top: '-4px', fontSize:"10px", display:"flex", justifyContent:"center", alignItems:"center"
                      }}>{item.natcategory[index].value}</div>
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ))}
      </div>
      <Grid item xs={12} container sx={{mr:"8px", borderTop:'1px solid black'}}>
      <Grid item xs={1} sx={{fontWeight:'bold', fontSize: '13px',padding:"8px", background: 'white'}}></Grid>
        <Grid item xs={1} sx={{fontWeight:'bold', fontSize: '13px',padding:"8px", background: 'white'}}></Grid>
        <Grid item xs={5} sx={{fontWeight:'bold', fontSize: '13px',padding:"8px", background: 'white'}}>
        <div style={{ position: 'relative', height:"10px", }}>
                  {PvtscaleValues.map((value, index) => (
                    index % Pvtinterval === 0 && (
                      <React.Fragment key={index}>
                        <span style={{ position: 'absolute', left: `${(value / maxPrivateValue) * 100}%`, top: '100%', transform: 'translate(-50%, -50%)', fontSize: '10px' }}>{value}</span>
                      </React.Fragment>
                    )
                  ))}
        </div>
        </Grid>
        <Grid item xs={5} sx={{fontWeight:'bold', fontSize: '13px', p:"8px", background: 'white' }}>
        <div style={{ position: 'relative', height:"10px", }}>
                  {NatscaleValues.map((value, index) => (
                    index % Natinterval === 0 && (
                      <React.Fragment key={index}>
                        <span style={{ position: 'absolute', left: `${(value / maxNatValue) * 100}%`, top: '100%', transform: 'translate(-50%, -50%)', fontSize: '10px' }}>{value}</span>
                      </React.Fragment>
                    )
            ))}
        </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PrivateNationalCount;