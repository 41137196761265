import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { fetchProductHistory } from '../../redux/actions/productHistory.actions';
import { useParams } from 'react-router-dom';


const PriceChartFilterDialog = ({ openDialog, setOpenDialog }) => {

  const dispatch = useDispatch();
  const { product_id } = useParams();
  const themeMode = useSelector((state) => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const productDetails = useSelector(state => state.productDetails);
  const competitors = productDetails?.data?.competitors || [];

  const [selectedTab, setSelectedTab] = useState('date');
  const [selectedCompetitor, setSelectedCompetitor] = useState(competitors?.[0]?.comp_prd_id);
  const [timeRange, setTimeRange] = useState(7);
  const [showCustomDates, setShowCustomDates] = useState(false);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); 

  // Filter competitors based on search term
  const filteredCompetitors = competitors?.filter((comp) =>
    comp?.comp_prd_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleTimeRangeChange = (event) => {
    const selectedRange = event.target.value;
    setTimeRange(selectedRange);

    if (selectedRange === "custom") {
      setShowCustomDates(true);
    } else {
      setShowCustomDates(false);
      setCustomStartDate("");
      setCustomEndDate("");
    }
  };

  const handleCompetitorChange = (event) => {
    setSelectedCompetitor(event.target.value);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleApplyFilters = () => {
    const start_date = timeRange === 'custom'
      ? moment(customStartDate).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      : moment().subtract(timeRange, 'days').format("YYYY-MM-DDTHH:mm:ss.SSSSSS");

    const end_date = timeRange === 'custom'
      ? moment(customEndDate).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      : moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS");

    dispatch(fetchProductHistory(token, product_id, start_date, end_date, Number(selectedCompetitor)));
    handleClose();
  };

  const clearFilter = ()=>{
    setTimeRange(7)
    setSelectedCompetitor(competitors?.[0]?.comp_prd_id)
    const start_date = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const end_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    dispatch(fetchProductHistory(token, product_id, start_date, end_date, Number(selectedCompetitor)));
  }

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' };
  const inpStyle = { border: "1px solid #ced4da", borderRadius: "4px", fontSize: "11px", height: "34px", padding: "0px 5px", color: fontColor, background: "none"};
  const optionTitle = { display: 'flex', alignItems: 'center', height: '30px' }
  const activeTab = { ...optionTitle, height: '30px', borderRadius: 1, pl: 1, cursor: 'pointer', mr: 1, color: '#0D82BC', backgroundColor: '#DBF3FF', fontSize: "13px" }
  const inactiveTab = { ...optionTitle, height: '30px', borderRadius: 1, pl: 1, cursor: 'pointer', fontSize: "13px" }


  return (
    <Dialog
      maxWidth='md'
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          ...fontColor, width: '60%',
          backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff'
        }
      }}
    >
      <DialogContent sx={fontColor}>
        <Grid container sx={{ ...fontColor, justifyContent: 'space-between', borderBottom: '0.5px solid #eaecf0', pb: '40px' }} >
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <FilterListIcon style={{ fontSize: '20' }} />
            <Typography sx={{ fontWeight: 'bold' }}>Filters</Typography>
          </div>
          <IconButton onClick={handleClose}><CloseIcon style={{ ...fontColor, fontSize: '20px' }} /></IconButton>
        </Grid>

        <Grid sx={{ display: 'flex', borderBottom: '0.5px solid #eaecf0' }}>
          <Grid sx={{ borderRight: '0.5px solid #eaecf0', height: '350px', width: '200px' }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }} mt={2}>
              <Typography onClick={() => handleTabClick('date')} sx={selectedTab === "date" ? activeTab : inactiveTab} >Date</Typography>
              <Typography onClick={() => handleTabClick('competitors')} sx={selectedTab === "competitors" ? activeTab : inactiveTab}>My Competitors</Typography>
            </Box>
          </Grid>

          <Grid className='custom-scrollbar-light' sx={{ maxHeight: '350px', overflowY: 'scroll', width: '100%' }}>
            {selectedTab === 'date' && (
              <div>
                <Box sx={{ display: 'flex', flexDirection: "column", p: 1 }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="time-range"
                      name="timeRange"
                      value={timeRange}
                      onChange={handleTimeRangeChange}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <FormControlLabel
                        value={7}
                        control={<Radio size="small"
                        sx={{
                            ...fontColor,
                            '& .MuiSvgIcon-root': { fontSize: '18px' },
                          }} />}
                        label={<Typography sx={{ fontSize: '11px' }}>Last Week</Typography>}
                      />
                      <FormControlLabel
                        value={30}
                        control={<Radio size="small"
                        sx={{
                            ...fontColor,
                            '& .MuiSvgIcon-root': { fontSize: '18px' },
                          }}
                        />}
                        label={<Typography sx={{ fontSize: '11px' }}>Last Month</Typography>}
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio size="small"
                        sx={{
                            ...fontColor,
                            '& .MuiSvgIcon-root': { fontSize: '18px' },
                          }} />}
                        label={<Typography sx={{ fontSize: '11px' }}>Select Period</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {showCustomDates && (
                  <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                    <input
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      style={inpStyle}
                      className={`${themeMode === 'dark' ? 'darkdateicon' : null}`}
                    />
                    <input
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      style={inpStyle}
                      className={`${themeMode === 'dark' ? 'darkdateicon' : null}`}
                    />
                  </Box>
                )}
              </div>
            )}

            {selectedTab === 'competitors' && (
              <div>
                <Box sx={{ display: 'flex', p: 1, justifyContent: 'flex-end', borderBottom: '0.5px solid #eaecf0' }}>
                  <TextField
                    type='search'
                    id='searchCompetitors'
                    name='searchCompetitors'
                    placeholder='Search Product...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Handle search input change
                    sx={{
                      '& input': { ...fontColor, fontSize: '13px' },
                      border: '1px solid #d0d5dd',
                      borderRadius: '5px',
                      '& input::placeholder': fontColor,
                      fontSize: '13px',
                      height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                    }}
                    variant='outlined'
                  />
                </Box>

                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', p: 1 }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="competitors"
                      name="competitor"
                      value={selectedCompetitor}
                      onChange={handleCompetitorChange}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {filteredCompetitors.map((comp) => (
                        <FormControlLabel
                          key={comp.comp_prd_id}
                          value={comp.comp_prd_id}
                          control={<Radio size="small"
                            sx={{
                              ...fontColor,
                              '& .MuiSvgIcon-root': { fontSize: '18px' },
                            }}
                          />}
                          label={<Typography sx={{ fontSize: '11px' }}>{comp.comp_prd_name}</Typography>}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid sx={{ display: 'flex', mt: 2 }}>
          <Button
          onClick={clearFilter}
          sx={{ ml: 'auto', mr: 2, textTransform: 'none', fontSize: '13px' }}>Clear Filters</Button>
          <Button
            variant='contained'
            sx={{ textTransform: 'none', fontSize: '13px' }}
            onClick={handleApplyFilters} // Apply filters on button click
          >
            Apply Filters
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PriceChartFilterDialog;
