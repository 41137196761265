import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, IconButton, Checkbox, Dialog, DialogContent, Chip, Radio, Button, Divider, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchCategories } from '../../redux/actions/categories.actions';
import { fetchDepartments } from '../../redux/actions/departments.actions';
import { fetchBrands } from '../../redux/actions/getBrands.actions';
import { applyFilterOptions } from '../../redux/actions/productString.actions';
import { fetchGroups } from '../../redux/actions/groups.actions';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { fetchCompetitorsLocations } from '../../redux/actions/compLocations.actions';

const ProductFilterDailog = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const company_id = useSelector(state => state.auth.company_id);
  const qa_user = useSelector(state => state.auth.qa_user);
  const groups = useSelector(state => state.groups);
  const brands = useSelector(state => state.brands);
  const categories = useSelector(state => state.categories);
  const departments = useSelector(state => state.departments);
  const locations = useSelector(state => state.competitorsLocations);
  const productString = useSelector(state => state.productString);
  const productLocations = useSelector(state => state.productLocations);

  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('group');
  const prdGroups = groups.data?.filter(grp => grp.product_count > 0)
  const initialState = { group: [], brand: [], category: [], department: [], prd_loc: '', comp_loc: '', price: '' };
  const [selectedItems, setSelectedItems] = useState(initialState);
  const filterLists = ['group', 'brand', 'category', 'department', 'location', 'price']
  const priceOptions = ['higher', 'equal', 'lower']

  const handleClose = () => {
    setDialogState({ ...dialogState, applyFilter: false })
  }

  const handleTabs = (tab) => {
    if (tab === 'brand' && !brands.data) {
      dispatch(fetchBrands(token, qa_user, company_id));
    } else if (tab === 'category' && !categories.data) {
      dispatch(fetchCategories(token, 'filter', qa_user, company_id));
    } else if (tab === 'department' && !departments.data) {
      dispatch(fetchDepartments(token, 'filter', qa_user, company_id));
    } else if (tab === 'location' && !locations.data) {
      dispatch(fetchCompetitorsLocations(token, qa_user, company_id));
    }
    setSelectedTab(tab);
  }

  const handleGroups = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.group.includes(item.group_id)) {
      const tempArray = tempItems.group.filter(ele => ele !== item.group_id)
      tempItems.group = tempArray;
    } else {
      tempItems.group.push(item.group_id)
    }
    setSelectedItems(tempItems);
  }

  const handleBrands = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.brand.includes(item)) {
      const tempArray = tempItems.brand.filter(ele => ele !== item)
      tempItems.brand = tempArray;
    } else {
      tempItems.brand.push(item)
    }
    setSelectedItems(tempItems);
  }

  const handleCategories = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.category.includes(item.category_id)) {
      const tempArray = tempItems.category.filter(ele => ele !== item.category_id)
      tempItems.category = tempArray;
    } else {
      tempItems.category.push(item.category_id)
    }
    setSelectedItems(tempItems);
  }

  const handleDepartments = (item) => {
    const tempItems = { ...selectedItems }
    if (selectedItems.department.includes(item.dept_id)) {
      const tempArray = tempItems.department.filter(ele => ele !== item.dept_id)
      tempItems.department = tempArray;
    } else {
      tempItems.department.push(item.dept_id)
    }
    setSelectedItems(tempItems);
  }

  const handlePrdLocation = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.prd_loc = item.loc_id
    setSelectedItems(tempItems);
  }

  const handleCompLocation = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.comp_loc = item.loc_id
    setSelectedItems(tempItems);
  }

  const handlePrice = (item) => {
    const tempItems = { ...selectedItems }
    tempItems.price = item
    setSelectedItems(tempItems);
  }

  const clearFilter = () => {
    const temLoc = productLocations.data?.find(loc => loc.loc_is_default)?.loc_id
    const tempState = {
      ...initialState,
      prd_loc: temLoc,
      comp_loc: temLoc
    }
    setSelectedItems(tempState);
    dispatch(applyFilterOptions(tempState));
  }

  const applyFilter = () => {
    handleClose();
    dispatch(applyFilterOptions(selectedItems));
  }

  useEffect(() => {
    dispatch(fetchGroups(token, qa_user, company_id));
    if (!productLocations.data) {
      dispatch(getAllProductLocations(token, qa_user, company_id));
    }
    setSelectedItems({
      group: productString.group_id__in?.includes(',') ? productString.group_id__in.split(',').map(Number) : productString.group_id__in !== '' ? [Number(productString.group_id__in)] : [],
      brand: productString.prd_brand__in?.includes(',') ? productString.prd_brand__in.split(',') : productString.prd_brand__in !== '' ? [productString.prd_brand__in] : [],
      category: productString.category_id__in?.includes(',') ? productString.category_id__in?.split(',').map(Number) : productString.category_id__in !== '' ? [Number(productString.category_id__in)] : [],
      department: productString.dept_id__in?.includes(',') ? productString.dept_id__in?.split(',').map(Number) : productString.dept_id__in !== '' ? [Number(productString.dept_id__in)] : [],
      prd_loc: Number(productString.prd_loc_id) || '',
      comp_loc: Number(productString.comp_loc_id) || '',
      price: productString.price || ''
    })
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const filterList = { maxHeight: '350px', overflowY: 'scroll', width: '100%' }
  const filterLocList = { maxHeight: '350px', overflowY: 'scroll', width: '50%' }
  const optionTitle = { display: 'flex', alignItems: 'center', height: '30px' }
  const activeTab = { ...optionTitle, height: '30px', borderRadius: 1, my: '5px', pl: 1, cursor: 'pointer', mr: 1, color: '#0D82BC', backgroundColor: '#DBF3FF' }
  const inactiveTab = { ...optionTitle, height: '30px', borderRadius: 1, my: '5px', pl: 1, cursor: 'pointer' }
  const [searchFilter, setSearchFilter] = useState({
    group: '',
    brand: '',
    category: '',
    department: ''
  });
  const filteredGroups = prdGroups?.filter(group => {
    const groupMatches = (!searchFilter.group || group?.group_name?.toLowerCase().includes(searchFilter?.group?.toLowerCase()))
    return groupMatches;
  });
  const filteredBrands = brands?.data?.brands?.filter(brand => {
    const brandMatches = (!searchFilter.brand || brand?.toLowerCase()?.includes(searchFilter.brand.toLowerCase()));
    return brandMatches;
  });
  const filteredCategories = categories.data?.filter(category => {
    const categoryMatches = !searchFilter.category || category.category_name.toLowerCase().includes(searchFilter.category.toLowerCase());
    return categoryMatches;
  });
  const filteredDepartments = departments.data?.filter(department => {
    const departmentMatches = !searchFilter.department || department.dept_name.toLowerCase().includes(searchFilter.department.toLowerCase());
    return departmentMatches;
  });
  const isAllCategoriesSelected = filteredCategories?.length > 0 && filteredCategories?.every(category => selectedItems?.category?.includes(category?.category_id));
  const isAllBrandsSelected = filteredBrands?.length > 0 && filteredBrands?.every(brand => selectedItems?.brand?.includes(brand));
  const isAllGroupsSelected = filteredGroups?.length > 0 && filteredGroups?.every(group => selectedItems?.group?.includes(group?.group_id));
  const isAllDepartmentsSelected = filteredDepartments?.length > 0 && filteredDepartments?.every(department => selectedItems?.department?.includes(department?.dept_id));

  const handleSelectAllGroup = () => {
    const filteredGroupIds = filteredGroups.map(group => group.group_id);
    const allSelected = filteredGroupIds.every(groupId => selectedItems.group.includes(groupId));
    if (allSelected) {
      setSelectedItems({ ...selectedItems, group: [] }); // Deselect all
    } else {
      setSelectedItems({ ...selectedItems, group: filteredGroupIds }); // Select all filtered groups
    }
  };

  const handleSelectAllBrands = () => {
    const filteredBrandNames = filteredBrands.map(brand => brand);
    const allSelected = filteredBrandNames.every(brand => selectedItems.brand.includes(brand));
    if (allSelected) {
      setSelectedItems({ ...selectedItems, brand: [] }); // Deselect all
    } else {
      setSelectedItems({ ...selectedItems, brand: filteredBrandNames }); // Select all filtered brands
    }
  };


  const handleSelectAllCategories = () => {
    const filteredCategoryIds = filteredCategories.map(category => category.category_id);
    const allSelected = filteredCategoryIds.every(categoryId => selectedItems.category.includes(categoryId));
    if (allSelected) {
      setSelectedItems({ ...selectedItems, category: [] }); // Deselect all
    } else {
      setSelectedItems({ ...selectedItems, category: filteredCategoryIds }); // Select all filtered categories
    }
  };

  const handleSelectAllDepartments = () => {
    const filteredDepartmentIds = filteredDepartments.map(department => department.dept_id);
    const allSelected = filteredDepartmentIds.every(departmentId => selectedItems.department.includes(departmentId));
    if (allSelected) {
      setSelectedItems({ ...selectedItems, department: [] }); // Deselect all
    } else {
      setSelectedItems({ ...selectedItems, department: filteredDepartmentIds }); // Select all filtered departments
    }
  };

  return (
    <div>
      <Dialog
        open={dialogState.applyFilter}
        maxWidth='md'
        onClose={handleClose}
        PaperProps={{
          sx: {
            ...fontColor, width: '100%',
            backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff'
          }
        }}
      >
        <DialogContent>
          <Grid container sx={{ ...optionTitle, justifyContent: 'space-between', borderBottom: '0.5px solid #eaecf0', pb: '40px' }} >
            <div style={{display: 'flex', alignItems: 'center', gap: '6px'}} >
            <FilterListIcon style={{ fontSize: '20' }} />
            <Typography sx={{ fontWeight: 'bold' }}>Filters</Typography>
            </div>
            <IconButton onClick={handleClose} ><CloseIcon style={{...fontColor, fontSize: '20px'}} /></IconButton>
          </Grid>
          <Grid sx={{ display: 'flex', borderBottom: '0.5px solid #eaecf0' }}>
            <Grid sx={{ borderRight: '0.5px solid #eaecf0', height: '350px', width: '200px' }} >
              {filterLists.map((filterName, index) => <Box key={index} onClick={() => handleTabs(filterName)} sx={selectedTab === filterName ? activeTab : inactiveTab}>
                <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{filterName}</Typography>
                {selectedTab === filterName && selectedTab !== 'location' && selectedTab !== 'price' &&
                  <Chip label={selectedItems[filterName]?.length || 0}
                    color='primary' sx={{ borderRadius: 1, ml: 1 }} size='small' />}
              </Box>)}
            </Grid>
            {selectedTab === 'group' ?
              <Grid className='custom-scrollbar-light' sx={filterList}>
                <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between' }} >
                  <div style={{ display: 'flex', alignItems: 'center' }} >
                    <Checkbox size='small' name='allGroup' sx={{ ...fontColor, pl: 0,
                      '& .MuiSvgIcon-root': { 
                       fontSize: '18px'
                      }, }}
                      checked={isAllGroupsSelected}
                      onChange={handleSelectAllGroup}
                      disabled={filteredGroups?.length === 0}
                    />
                    <Typography sx={{fontSize: '13px'}} >Select All</Typography>
                  </div>
                  <div>
                    <TextField type='search' id='allGroup' name='allGroup' placeholder='Search Group...'
                      sx={{
                        '& input': {...fontColor, fontSize: '13px'},
                        border: '1px solid #d0d5dd',
                        borderRadius: '5px',
                        '& input::placeholder': fontColor,
                        fontSize: '13px', 
                        height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                      }}
                      variant='outlined'
                      value={searchFilter.group}
                      onChange={e => setSearchFilter({ ...searchFilter, group: e.target.value })}
                    />
                  </div>
                </Box>
                <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                {groups.loading ?
                  <Typography sx={{ mt: 12, ml: 8, textAlign: 'center', fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                  : filteredGroups ? (
                    filteredGroups?.length > 0 ? (
                      filteredGroups?.map((group, index) => (
                        <Box key={index} sx={optionTitle}>
                          <Checkbox name='group' size='small' sx={{...fontColor,
                        '& .MuiSvgIcon-root': { 
                          fontSize: '18px'
                         },  
                        }}
                            checked={selectedItems?.group?.includes(group?.group_id)}
                            onChange={() => handleGroups(group)}
                          />
                          <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }} >{group.group_name}</Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>No matching results found.</Typography>
                    )
                  ) : (
                    groups.error ? (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>Failed to load the data</Typography>
                    ) : (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>There is no data</Typography>
                    )
                  )
                }
              </Grid>
              : selectedTab === 'brand' ?
                <Grid className='custom-scrollbar-light' sx={filterList}>
                  <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between' }} >
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                      <Checkbox size='small' name='allBrand' sx={{ ...fontColor, pl: 0,
                      '& .MuiSvgIcon-root': { 
                        fontSize: '18px'
                       },  
                      }}
                        checked={isAllBrandsSelected}
                        onChange={handleSelectAllBrands}
                      />
                      <Typography sx={{fontSize: '13px'}} >Select All</Typography>
                    </div>
                    <div>
                      <TextField type='search' placeholder='Search Brand...' id='allBrand' name='allBrand'
                        sx={{
                          '& input': {...fontColor, fontSize: '13px'},
                          border: '1px solid #d0d5dd',
                          borderRadius: '5px', 
                          '& input::placeholder': fontColor,
                          fontSize: '13px', 
                          height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                        }}
                        variant='outlined'
                        value={searchFilter.brand}
                        onChange={e => setSearchFilter({ ...searchFilter, brand: e.target.value })}
                      />
                    </div>
                  </Box>
                  <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                  {brands.loading ? (
                    <Typography sx={{ mt: 12, ml: 8, textAlign: 'center', fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                  ) : filteredBrands ? (
                    filteredBrands?.length > 0 ? (
                      filteredBrands?.map((brand, index) => (
                        <Box key={index} sx={optionTitle}>
                          <Checkbox
                            name='brand'
                            size='small'
                            sx={{...fontColor,
                              '& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                               },
                            }}
                            checked={selectedItems?.brand?.includes(brand)}
                            onChange={() => handleBrands(brand)}
                          />
                          <Typography sx={{ textTransform: 'capitalize', fontSize: '13px', }}>{brand}</Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>No matching results found.</Typography>
                    )
                  ) : brands.error ? (
                    <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>Failed to load the data</Typography>
                  ) : (
                    <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>There is no data</Typography>
                  )}

                </Grid>
                : selectedTab === 'category' ?
                  <Grid className='custom-scrollbar-light' sx={filterList}>
                    <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between' }} >
                      <div style={{ display: 'flex', alignItems: 'center' }} >
                        <Checkbox size='small' name='allCategory' sx={{ ...fontColor, pl: 0,
                        '& .MuiSvgIcon-root': { 
                          fontSize: '18px'
                         },  
                        }}
                          checked={isAllCategoriesSelected}
                          onChange={handleSelectAllCategories}
                          disabled={filteredCategories?.length === 0}
                        />
                        <Typography sx={{fontSize: '13px'}}>Select All</Typography>
                      </div>
                      <div>
                        <TextField type='search' placeholder='Search Category...' id='allCategory' name='allCategory'
                          sx={{
                            '& input': {...fontColor, fontSize: '13px'},
                            border: '1px solid #d0d5dd',
                            borderRadius: '5px', 
                            '& input::placeholder': fontColor,
                            fontSize: '13px',
                            height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                          }}
                          variant='outlined'
                          value={searchFilter.category}
                          onChange={e => setSearchFilter({ ...searchFilter, category: e.target.value })}
                        />
                      </div>
                    </Box>
                    <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                    {categories.loading ? (
                      <Typography sx={{ mt: 12, ml: 8, textAlign: 'center', fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                    ) : filteredCategories ? (
                      filteredCategories?.length > 0 ? (
                        filteredCategories?.map((category, index) => (
                          <Box key={index} sx={optionTitle}>
                            <Checkbox
                              name='category'
                              size='small'
                              sx={{...fontColor,
                                '& .MuiSvgIcon-root': { 
                                  fontSize: '18px'
                                 },
                              }}
                              checked={selectedItems?.category?.includes(category?.category_id)}
                              onChange={() => handleCategories(category)}
                            />
                            <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{category.category_name}</Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>No matching results found.</Typography>
                      )
                    ) : categories.error ? (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>Failed to load the data</Typography>
                    ) : (
                      <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>There is no data</Typography>
                    )}

                  </Grid>
                  : selectedTab === 'department' ?
                    <Grid className='custom-scrollbar-light' sx={filterList}>
                      <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between' }} >
                        <div style={{ display: 'flex', alignItems: 'center' }} >
                          <Checkbox size='small' name='allDepartment' sx={{ ...fontColor, pl: 0,
                        '& .MuiSvgIcon-root': { 
                          fontSize: '18px'
                         },  
                        }}
                            onChange={handleSelectAllDepartments}
                            checked={isAllDepartmentsSelected}
                            disabled={filteredDepartments?.length === 0}
                          />
                          <Typography sx={{fontSize: '13px'}}>Select All</Typography>
                        </div>
                        <div>
                          <TextField type='search' id='allDepartment' name='allDepartment' placeholder='Search Department...'
                            sx={{
                              '& input': {...fontColor, fontSize: '13px'},
                              border: '1px solid #d0d5dd',
                              borderRadius: '5px', 
                              '& input::placeholder': fontColor,
                              fontSize: '13px',
                              height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                            }}
                            variant='outlined'
                            value={searchFilter.department}
                            onChange={e => setSearchFilter({ ...searchFilter, department: e.target.value })}
                          />
                        </div>
                      </Box>
                      <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                      {departments.loading ? (
                        <Typography sx={{ mt: 12, ml: 8, textAlign: 'center', fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                      ) : filteredDepartments ? (
                        filteredDepartments?.length > 0 ? (
                          filteredDepartments?.map(department => (
                            <Box key={department?.dept_id} sx={optionTitle}>
                              <Checkbox
                                name='department'
                                size='small'
                                sx={{...fontColor,
                                  '& .MuiSvgIcon-root': { 
                                    fontSize: '18px'
                                   },
                                }}
                                checked={selectedItems?.department?.includes(department?.dept_id)}
                                onChange={() => handleDepartments(department)}
                              />
                              <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }} >{department.dept_name}</Typography>
                            </Box>
                          ))
                        ) : (
                          <Typography sx={{ mt: 12, ml: 8, textAlign: 'center', fontSize: '13px' }}>No matching results found.</Typography>
                        )
                      ) : departments.error ? (
                        <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>Failed to load the data</Typography>
                      ) : (
                        <Typography sx={{ m: 12, textAlign: 'center', fontSize: '13px' }}>There is no data</Typography>
                      )}

                    </Grid>
                    : selectedTab === 'location' ?
                      <Grid sx={{ display: 'flex', width: '100%' }}>
                        <Grid className='custom-scrollbar-light' sx={filterLocList}>
                          <Typography sx={{ ml: 4, my: 1, fontSize: '13px' }}>Product Location</Typography>
                          <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                          {productLocations.loading ?
                            <Typography sx={{ mt: 12, ml: 8, fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                            : productLocations.data ? productLocations.data?.map(location =>
                              <Box key={location.loc_id} sx={optionTitle}>
                                <Radio name='location' size='small' sx={{...fontColor,
                              '& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                               },  
                              }}
                                  checked={selectedItems.prd_loc === location.loc_id}
                                  onChange={() => handlePrdLocation(location)} />
                                <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{location.loc_name} {location.loc_zip && ` (${location.loc_zip})`}</Typography>
                              </Box>)
                              : locations.error ? <Typography sx={{ m: 12, textAlign: 'center' }}>Failed to load the data</Typography>
                                : <Typography sx={{ m: 12, textAlign: 'center' }}>There is no data</Typography>}
                        </Grid>
                        <Grid className='custom-scrollbar-light' sx={filterLocList}>
                          <Typography sx={{ ml: 4, my: 1, fontSize: '13px' }}>Competitors Location</Typography>
                          <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                          {locations.loading ?
                            <Typography sx={{ mt: 12, ml: 8, fontSize: '13px' }}>Fetching the data, please wait...</Typography>
                            : locations.data ? locations.data?.map(location =>
                              <Box key={location.loc_id} sx={optionTitle}>
                                <Radio name='location' size='small' sx={{...fontColor,
                              '& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                               },  
                              }}
                                  checked={selectedItems.comp_loc === location.loc_id}
                                  onChange={() => handleCompLocation(location)} />
                                <Typography sx={{fontSize: '13px'}} >{location.loc_name} {location.loc_zip && ` (${location.loc_zip})`}</Typography>
                              </Box>)
                              : locations.error ? <Typography sx={{ m: 12 }}>Failed to load the data</Typography>
                                : <Typography sx={{ m: 12 }}>There is no data</Typography>}
                        </Grid>
                      </Grid>
                      : selectedTab === 'price' ?
                        <Grid sx={{ maxHeight: '350px', width: '100%' }}>
                          <Typography sx={{ ml: 4, my: 1, fontSize: '13px' }}>This filter operates in relation to your product. For instance, selecting 'higher' will display competitors with lower prices than yours.</Typography>
                          <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />
                          {priceOptions.map((val, index) =>
                            <Box key={index} sx={optionTitle}>
                              <Radio name='price' size='small' sx={{...fontColor,
                            '& .MuiSvgIcon-root': { 
                              fontSize: '18px'
                             },  
                            }}
                                checked={selectedItems.price === val}
                                onClick={() => handlePrice(val)} />
                              <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{val}</Typography>
                            </Box>)}
                        </Grid> : null}
          </Grid>
          <Grid sx={{ display: 'flex', mt: 2 }}>
            <Button onClick={clearFilter} sx={{ ml: 'auto', mr: 2, textTransform: 'none', fontSize: '13px' }}>Clear Filters</Button>
            <Button onClick={applyFilter} variant='contained' sx={{ textTransform: 'none', fontSize: '13px' }}>Apply Filters</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ProductFilterDailog;