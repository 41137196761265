import axios from "axios";
import { paths } from "../../Api/paths";
import { ENABLE_NOTIFICATION,ENABLE_NOTIFICATION_FAILED,ENABLE_NOTIFICATION_SUCCESSFULL,RESET_ENABLE_NOTIFICATION } from "../constants/enableNotification.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const enableNotification = () => ({
  type: ENABLE_NOTIFICATION,
});

const enableNotificationSuccess = (data) => ({
  type: ENABLE_NOTIFICATION_SUCCESSFULL,
  payload: data,
});

const enableNotificationFailure = (error) => ({
  type: ENABLE_NOTIFICATION_FAILED,
  payload: error,
});

export const resetEnableNotification = () => ({
  type: RESET_ENABLE_NOTIFICATION,
});

export const handleEnableNotification = (token,data) => async (dispatch) => {
  try {
    dispatch(enableNotification());
    const response = await axios.put(
      `${process.env.REACT_APP_API_LINK}${paths.enableNotification}${data.prd_id}`,
      {},
      { headers: { Authorization: `bearer ${token}` } }
    );
    dispatch(enableNotificationSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(handleTokenExpiration());
    } else {
    dispatch(enableNotificationFailure(error));
    }
  }
};
