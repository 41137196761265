import axios from "axios";
import { paths } from '../../Api/paths';
import { SET_DEFAULT_LOC, SET_DEFAULT_LOC_FAILED, SET_DEFAULT_LOC_SUCCESSFULL } from "../constants/setDefaultLocation.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";


const setDefultLocation = () => ({
    type: SET_DEFAULT_LOC
})

const setDefaultLocationSuccessfull = (data) => ({
    type: SET_DEFAULT_LOC_SUCCESSFULL,
    payload: data
})

const setDefaultLocationFailure = (error) => ({
    type: SET_DEFAULT_LOC_FAILED,
    payload: error
})

export const changeDefaultLocation = (token, locId) => async (dispatch) => {
    try{
        dispatch(setDefultLocation());
        const response = await axios.put(`${process.env.REACT_APP_API_LINK}${paths.setDefaultLocation}${locId}`,
        {}, { headers: { Authorization: `bearer ${token}` } })
        dispatch(setDefaultLocationSuccessfull(response.data));
    } catch(error) {
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(setDefaultLocationFailure(error));
          }
    }
}