import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_MARKETPLACES, FETCH_MARKETPLACES_FAILED, FETCH_MARKETPLACES_SUCCESSFULL } from "../constants/marketPlaces.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getMarketplaces = () => ({
    type: FETCH_MARKETPLACES
});

const getMarketplacesSuccess = (data) => ({
    type: FETCH_MARKETPLACES_SUCCESSFULL,
    payload: data
});

const getMarketplacesFailure = (error) => ({
    type: FETCH_MARKETPLACES_FAILED,
    payload: error
});

export const fetchMarketplaces = (token, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getMarketplaces());
      const endpoint = qa_user ? `${paths.qaMarketPlaces}?company_id=${company_id}` : paths.marketPlaces;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}});
      dispatch(getMarketplacesSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
      dispatch(getMarketplacesFailure(error));
      }
    }
};