import axios from "axios";
import { paths } from '../../Api/paths';
import { FETCH_PRODUCT_DETAILS, FETCH_PRODUCT_DETAILS_FAILED, FETCH_PRODUCT_DETAILS_SUCCESSFULL } from "../constants/productDetails.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

export const getProductDetails = () => ({
    type: FETCH_PRODUCT_DETAILS
});

export const getProductDetailsSuccess = (data) => ({
    type: FETCH_PRODUCT_DETAILS_SUCCESSFULL,
    payload: data
});

export const getProductDetailsFailure = (error) => ({
    type: FETCH_PRODUCT_DETAILS_FAILED,
    payload: error
});

export const fetchProductDetails = (token, prdId, qa_user, company_id) => async (dispatch) => {
    try {
      dispatch(getProductDetails());
      const endpoint = qa_user ? `${paths.qaMyProducts}${prdId}?company_id=${company_id}` : `${paths.myProducts}${prdId}`;
      const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
      { headers: { Authorization: `bearer ${token}`}})
      dispatch(getProductDetailsSuccess(response.data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(handleTokenExpiration());
      } else {
        dispatch(getProductDetailsFailure(error));
      }
    }
};