import React, { useEffect, useMemo, useState } from 'react'
import Navbar from '../../components/Navbar'
import { Box, Button, Card, Checkbox, Chip, Divider, FormControl, Grid, IconButton, InputAdornment, MenuItem, Pagination, Paper, Popover, Rating, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/Footer';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { changePageNumber, changePageSize, changePrdRemarks, changeSearchInput, clearSpecificFilter, resetFilter } from '../../redux/actions/productString.actions';
import FilterListIcon from '@mui/icons-material/FilterList';
import ProductFilterDailog from '../../components/ProductFilterDailog';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { modifyGroupProducts, resetModifyGroupProducts } from '../../redux/actions/modifyGroupProducts.actions';
import { fetchProducts } from '../../redux/actions/getProducts.actions';
import Loading from '../../components/Loading';
import { TableVirtuoso } from 'react-virtuoso';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { fetchMarketplaces } from '../../redux/actions/marketPlaces.actions';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toggleKeyValue } from '../../redux/actions/toggleKeyValue.actions';
import EditProductDialog from '../../components/EditProductDialog';
import DeleteDialog from '../../components/DeleteDialog';
import ApproveProductsDialog from '../../components/ApproveProductsDialog';
import CompetitorDetailsDialog from '../../components/CompetitorDetailsDialog';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import './myProducts.css';
import { fetchGroups } from '../../redux/actions/groups.actions';
import { clearFilters } from '../../redux/actions/detailsPageFilters.actions';
import RefreshCompetitorListing from '../../components/RefreshCompetitorListing';
import { getRefreshBtnStatus } from '../../redux/actions/refreshBtnStatis.actions';
import { refreshAllItems, resetRefreshAllnStatus } from '../../redux/actions/refreshAll.actions';

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
}

const MyProducts = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const productString = useSelector(state => state.productString);
  const locations = useSelector(state => state.productLocations);
  const productLocations = useSelector(state => state.productLocations);
  const qa_user = useSelector(state => state.auth.qa_user);
  const groupActions = useSelector(state => state.groupActions);
  const groups = useSelector(state => state.groups);
  const token = useSelector(state => state.auth.token);
  const UI_design = useSelector(state => state.auth.UI_design);
  const user_id = useSelector(state => state.auth.user_id);
  const prd_remarks_value = useSelector(state => state.productString.prd_remarks);
  const products = useSelector(state => state.products);
  const company_id = useSelector(state => state.auth.company_id);
  const deleteSingleProduct = useSelector(state => state.deleteSingleProduct);
  const editProduct = useSelector(state => state.editProduct);
  const keyValue = useSelector(state => state.keyValue);
  const approvePrdState = useSelector(state => state.approveProducts);
  const marketPlaces = useSelector(state => state.marketPlaces);
  const currSymb = useSelector(state => state.auth?.currency?.length !== 0 ? state.auth?.currency[0]?.currency_symbol : '');
  const refreshStatus = useSelector(state => state.refreshBtnStatusDetails)
  const refreshAllData = useSelector(state => state.refreshAllDetails)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dialogState, setDialogState] = useState({ applyFilter: false, approveAll: false, approveSelected: false, refresh: false, data: null });
  const [searchInput, setSearchInput] = useState('');
  const [selProducts, setSelProducts] = useState([]);
  const [popUp, setPopup] = useState({ openActionPopup: null, openGroupPopup: null });
  const [actionRow, setActionRow] = useState(null);
  const [grpActnMsg, setGrpActnMsg] = useState('');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openCompDetailsDialog, setCompDetailsDialog] = useState({ openDialog: false, data: null });
  const [dialogActionState, setDialogActionState] = useState({ deleteSingleProduct: false, editProduct: false, data: null });
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [marketPlace, setMarketPlace] = useState(prd_remarks_value || "");
  const [showRefresAllBtn, setRefreshAllBtn] = useState(false)
  const totalPages = Math.ceil(products.data?.count / limit);
  const actionPopup = Boolean(popUp.openActionPopup);
  const groupPopup = Boolean(popUp.openGroupPopup);
  const actionId = actionPopup ? 'product_actions' : undefined;
  const groupId = groupPopup ? 'group_modify' : undefined;

  const handleRemoveFilter = (filter) => {
    dispatch(clearSpecificFilter(filter));
  }

  const openDialog = (type) => {
    const tempState = { ...dialogState, [type]: true }
    setDialogState(tempState);
  }

  const filterSearch = () => {
    dispatch(changeSearchInput(searchInput));
  }

  const handleChangeSearch = (e) => {
    setSearchInput(e.target.value);
  }

  const handleReset = () => {
    const temLoc = productLocations.data?.find(loc => loc.loc_is_default)?.loc_id
    dispatch(resetFilter(temLoc, prd_remarks_value))
  }

  const openApproveDialog = (type) => {
    const tempState = { ...dialogState, [type]: true, data: selProducts }
    setDialogState(tempState);
  }

  const handlePopupOpen = (popupType, event, prd) => {
    setPopup({ ...popUp, [popupType]: event.currentTarget });
    setActionRow(prd)
  };

  const handlePopupClose = () => {
    setPopup({ openActionPopup: null, openGroupPopup: null });
    if (groupActions.data || groupActions.error) {
      dispatch(resetModifyGroupProducts());
    }
  }

  const addProductsToGroup = (grp) => {
    const payload = {
      action: 'create',
      group_id: grp,
      prd_id: selProducts
    }
    dispatch(modifyGroupProducts(token, payload));
    setGrpActnMsg('Adding products to the group...')
  }

  const removeProductsFromGroup = (grp) => {
    const payload = {
      action: 'delete',
      group_id: grp,
      prd_id: selProducts
    }
    dispatch(modifyGroupProducts(token, payload));
    setGrpActnMsg('Removing products from the group...')
  }

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
    dispatch(changePageSize(event.target.value));
  };

  const handleSelectAll = () => {
    let tempState = [];
    if (selProducts.length < products.data?.results?.length) {
      products.data?.results?.forEach(item => tempState.push(item.prd_id));
    }
    setSelProducts(tempState);
  }

  const handleCheckedPrds = (prdId) => {
    if (selProducts.includes(prdId)) {
      const tempState = selProducts.filter(item => item !== prdId);
      setSelProducts(tempState);
    } else {
      const tempState = [...selProducts, prdId];
      setSelProducts(tempState);
    }
  }

  const goToProductPage = (e, link) => {
    e.stopPropagation();
    window.open(link, '_blank')
  }

  const goToDetailsPage = (prd) => {
    if (UI_design !== "UI_2") {
      navigate(`/myproduct/${prd.prd_id}/${productString.prd_loc_id}/${productString.comp_loc_id}`)
    }
  }

  const handleImageError = (event) => {
    event.target.src = '/assets/not_loaded.png';
  };

  const handleOpenCompDialog = (prd, marketPlaceId) => {
    setCompDetailsDialog(prev => ({
      ...prev,
      openDialog: true,
      data: {
        prd,
        marketPlaceId
      }
    }));
  }
  const openRefreshDialog = (type,prd)=>{
    const tempState = { ...dialogState, [type]: true, data: prd }
    setDialogState(tempState);
  }
  const handleKeyValue = () => {
    dispatch(toggleKeyValue(token, actionRow.prd_id));
    handlePopupClose();
  }

  const openActionDialog = (type) => {
    const tempState = { ...dialogActionState, [type]: true, data: actionRow }
    setDialogActionState(tempState);
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    dispatch(changePageNumber(value));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      dispatch(changePageNumber(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      dispatch(changePageNumber(currentPage + 1))
    }
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const [copiedProduct, setCopiedProduct] = useState(null);

  const handleCopy = (textToCopy, productId) => {
    navigator.clipboard.writeText(textToCopy);
    setCopiedProduct(productId);
    setTimeout(() => {
      setCopiedProduct(null);
    }, 1000);
  };

  const handleMarketPlaceChange = (e) =>{
    const selectedMarket = e.target.value;
    setMarketPlace(selectedMarket);
    dispatch(changePrdRemarks(selectedMarket));
  };

  const handleRefreshAll = ()=>{
    let queryString = `?loc_id=${productString.prd_loc_id}&marketplace_id=${prd_remarks_value}&cuttoff_score=100&user_id=${user_id}`
    if(queryString){
      dispatch(refreshAllItems(token, queryString))
    }
  }

  const sortedData = useMemo(() => {
    if (!products.data?.results) return [];
    let sortedData = [...products.data.results];

    if (sortBy === 'lastUpdated') {
      sortedData.sort((a, b) => {
        const aLastUpdated = new Date(a.modified_date).getTime();
        const bLastUpdated = new Date(b.modified_date).getTime();
        
        if (aLastUpdated < bLastUpdated) {
          return sortOrder === 'asc' ? -1 : 1;
        } else if (aLastUpdated > bLastUpdated) {
          return sortOrder === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    if (sortBy === 'buyBox') {
      sortedData.sort((a, b) => {
        const aHasBuyBox = (a.competitors?.[0]?.seller?.seller_name || '')?.toLowerCase() || ((a.locations?.[0]?.prd_sales_price !== 0 && a.locations?.[0]?.seller?.seller_name) || '')?.toLowerCase();
        const bHasBuyBox = (b.competitors?.[0]?.seller?.seller_name || '')?.toLowerCase() || ((b.locations?.[0]?.prd_sales_price !== 0 && b.locations?.[0]?.seller?.seller_name) || '')?.toLowerCase();
        
        if (aHasBuyBox < bHasBuyBox) {
          return sortOrder === 'asc' ? -1 : 1;
        } else if (aHasBuyBox > bHasBuyBox) {
          return sortOrder === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    
    if (sortBy === 'productName') {
      sortedData.sort((a, b) => {
        const nameA = a.prd_name.toUpperCase();
        const nameB = b.prd_name.toUpperCase();
        if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
        if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    } else if (sortBy === 'yourPrice') {
      sortedData.sort((a, b) => {
        const priceA = a.locations[0]?.prd_sales_price || 0;
        const priceB = b.locations[0]?.prd_sales_price || 0;
        return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      });
    }
    else {
      sortedData = sortedData.map((prd) => {
        const matchedCompetitors = prd.competitors.filter((comp) => comp.market_places?.market_place_id === sortBy);
        let sortedCompetitors;
        if (matchedCompetitors.length > 0) {
          const maxScore = Math.max(...matchedCompetitors.map((competitor) => competitor.comp_prd_score));
          const highScoreComp = matchedCompetitors.filter((competitor) => competitor.comp_prd_score === maxScore);
          sortedCompetitors = highScoreComp.sort((a, b) => a.comp_prd_sales_price - b.comp_prd_sales_price);
        } else {
          sortedCompetitors = [{ comp_prd_sales_price: sortOrder === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY }];
        }
        return { ...prd, sortedCompetitors };
      });

      sortedData.sort((a, b) => {
        const aPrice = a.sortedCompetitors[0].comp_prd_sales_price;
        const bPrice = b.sortedCompetitors[0].comp_prd_sales_price;
        return sortOrder === 'asc' ? aPrice - bPrice : bPrice - aPrice;
      });
    }
    return sortedData;
  }, [products.data?.results, sortBy, sortOrder]);

  useEffect(() => {
    setSelProducts([]);
    setLimit(productString.page_size);
    setCurrentPage(productString.page);
    setSearchInput(productString.search);
    sessionStorage.setItem('productString', JSON.stringify(productString));

    let filterString = '';
    let prdBrandInValue = '';
    let prdSearchValueIn = '';

    for (let key in productString) {
      if (key === 'page') {
          filterString = '?' + key + '=' + productString[key];
      } else if (productString[key]) {
          filterString = filterString + '&' + key + '=' + productString[key];
          if (key === 'prd_brand__in') {
              prdBrandInValue = productString[key];
          } else if (key === 'search') {
              prdSearchValueIn = productString[key];
          }
      }
  }
  const encodedPrdBrandInValue = encodeURIComponent(prdBrandInValue);
  const encodedPrdSearchValue = encodeURIComponent(prdSearchValueIn);
  if (filterString.includes('prd_brand__in')) {
      filterString = filterString.replace(prdBrandInValue, encodedPrdBrandInValue);
  }
  if (filterString.includes('search')) {
      filterString = filterString.replace(prdSearchValueIn, encodedPrdSearchValue);
  }

    if (prd_remarks_value?.length>0) {
      filterString = filterString + `&prd_remarks=${prd_remarks_value}`;
    }

    if (qa_user) {
      filterString = filterString + `&company_id=${company_id}`
    }
    dispatch(fetchProducts(token, filterString, qa_user))

  }, [productString, deleteSingleProduct, editProduct, keyValue, company_id, approvePrdState, marketPlace, prd_remarks_value]); //eslint-disable-line

  useEffect(() => {
    if (groupActions.error) {
      setGrpActnMsg('Failed to modify group')
    } else if (groupActions.success) {
      if (groupActions?.data?.message === 'Removed Successfully') {
        handlePopupClose();
        setCurrentPage(1);
        dispatch(changePageNumber(1));
      } else {
        setGrpActnMsg('Products added successfully')
      }
    } else {
      setGrpActnMsg('');
    }
  }, [groupActions]) //eslint-disable-line

  useEffect(() => {
    if (!marketPlaces.data) {
      dispatch(fetchMarketplaces(token, qa_user, company_id));
    }
    if (!groups.data && !qa_user) {
      dispatch(fetchGroups(token, false));
    }
    if (!productLocations.data) {
      dispatch(getAllProductLocations(token, qa_user, company_id));
    }
  }, [company_id]) //eslint-disable-line

  useEffect(() => {
    if (UI_design === "UI_2" && marketPlaces.data?.length > 0) {
      setMarketPlace(marketPlaces.data?.[0].market_place_id);
      if (!marketPlace) {
        setMarketPlace(marketPlaces.data?.[0].market_place_id)
        dispatch(changePrdRemarks(marketPlaces.data?.[0].market_place_id));
      } else {
        setMarketPlace(prd_remarks_value);
        // const isValueValid = prd_remarks_value && !marketPlaces.data.some(item => item.market_place_id === prd_remarks_value);
        // if (isValueValid) {
        //   console.log("2")
        //   dispatch(changePrdRemarks(""));
        // } else {
        //   console.log("3")
        //   setMarketPlace(prd_remarks_value);
        // }
      }
    }
  }, [marketPlaces, dispatch]); //eslint-disable-line

  useEffect(() => {
    if(refreshAllData.success){
      setRefreshAllBtn(false)
      dispatch(resetRefreshAllnStatus())
    }
  }, [refreshAllData]) //eslint-disable-line
  
  useEffect(() => {
    if(refreshStatus.success){
      setRefreshAllBtn(refreshStatus.data?.show_refresh)
    }
  }, [refreshStatus]) //eslint-disable-line
  
  useEffect(() => {
    dispatch(clearFilters());
    if(UI_design === "UI_2"){
      dispatch(getRefreshBtnStatus(token))
    }
  }, []) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const chipStyle = { ...fontColor, bgcolor: themeMode === 'light' ? '#FDF8E5' : '#3F526B', borderRadius: 1 }
  const popupStyle = { ...fontColor, backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B', width: '200px', py: 1 }
  const prdActnStyle = { cursor: 'pointer', '&:hover': { backgroundColor: themeMode === 'light' ? '#f4d35e' : '#3F526B' } }
  const selectStyle = { ...fontColor, bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D' }
  const errorBox = { ...fontColor, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }
  const tableHeader = { ...fontColor, background: themeMode === 'light' ? '#FDF8E5' : '#3F526B', padding: '0px 0px 0px 16px', fontSize: '13px' }
  const pageChangeStyle = { ...fontColor, borderColor: '#d0d5dd', textTransform: 'none', fontWeight: '400', height: '32px' }

  const ResultsPerPageControl = () => {
    
    const totalProducts = products.data?.count || 0;
    const startIndex = (currentPage - 1) * limit + 1;
    const endIndex = Math.min(currentPage * limit, totalProducts)
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} >
        <Typography sx={{ ...fontColor, fontSize: '11px', paddingRight: '3px' }} >{`(${startIndex} - ${endIndex} Products of ${totalProducts} SKUs)`}</Typography>
        <Select
          inputProps={{ name: 'limitSelect' }} id='limitSelect' size='small'
          MenuProps={{
            PaperProps: {
              sx: selectStyle
            }
          }}
          value={limit} onChange={handleChangeLimit}
          displayEmpty
          sx={{
            ...fontColor, border: '1px solid #ced4da', fontSize: 12,
            '& .MuiSelect-icon': { fill: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' },
            height: 22
          }}>
          <MenuItem sx={{ fontSize: 13 }} value={10}>10</MenuItem>
          <MenuItem sx={{ fontSize: 13 }} value={25}>25</MenuItem>
          <MenuItem sx={{ fontSize: 13 }} value={100}>100</MenuItem>
        </Select>
      </div>
    )
  }

  const renderSortArrow = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <ArrowDownwardIcon style={{ fontSize: '20px' }} /> : <ArrowUpwardIcon style={{ fontSize: '20px' }} />;
    }
    return null;
  };

  return (
    <>
      {dialogState.applyFilter && <ProductFilterDailog dialogState={dialogState} setDialogState={setDialogState} />}
      {dialogState.refresh && <RefreshCompetitorListing dialogState={dialogState} setDialogState={setDialogState} marketPlace={marketPlace} marketPlaces={marketPlaces} />}
      {dialogActionState.editProduct && <EditProductDialog dialogState={dialogActionState} setDialogState={setDialogActionState} />}
      {dialogActionState.deleteSingleProduct && <DeleteDialog dialogState={dialogActionState} setDialogState={setDialogActionState} dialogFor={'deleteSingleProduct'} />}
      {(dialogState.approveAll || dialogState.approveSelected) && <ApproveProductsDialog dialogState={dialogState} setDialogState={setDialogState} />}
      {openCompDetailsDialog.openDialog && <CompetitorDetailsDialog openCompDetailsDialog={openCompDetailsDialog} setCompDetailsDialog={setCompDetailsDialog} />}
      <Navbar selected='myProducts' />
      <Grid container sx={{ p: '12px' }} className={`navPage ${themeMode === 'light' ? 'lightPage' : 'darkPage'}`}>
        <Card sx={{ width: '100%', borderRadius: '10px', backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff' }} >
          <Grid container sx={{ p: '9px' }} >
            <Grid xs={9} item sx={{ display: 'flex', alignItems: 'center', gap: '12px' }} >
              {productString.prd_loc_id && <Box sx={{
                display: 'flex', alignItems: 'center', backgroundColor: '#f4d35e',
                border: '0.5px solid #808080', px: 1, borderRadius: 5, height: '90%'
              }}>
                <FmdGoodOutlinedIcon style={{ color: '#5A5A5A', fontSize: 16 }} />
                <Typography sx={{ color: '#5A5A5A', fontSize: '11px' }} >
                  {locations.data?.find(loc => loc.loc_id === Number(productString?.prd_loc_id))?.loc_name || ''}
                </Typography>
              </Box>}
              {productString.group_id__in && <Chip sx={{ ...chipStyle, fontSize: '11px' }} label={`Groups: ${productString.group_id__in.split(',').length}`}
                deleteIcon={<CloseIcon style={{ ...fontColor, fontSize: '18px' }} />} onDelete={() => handleRemoveFilter('group_id__in')} />}
              {productString.prd_brand__in && <Chip sx={{ ...chipStyle, fontSize: '11px' }} label={`Brands: ${productString.prd_brand__in.split(',').length}`}
                deleteIcon={<CloseIcon style={{ ...fontColor, fontSize: '18px' }} />} onDelete={() => handleRemoveFilter('prd_brand__in')} />}
              {productString.category_id__in && <Chip sx={{ ...chipStyle, fontSize: '11px' }} label={`Categories: ${productString.category_id__in.split(',').length}`}
                deleteIcon={<CloseIcon style={{ ...fontColor, fontSize: '18px' }} />} onDelete={() => handleRemoveFilter('category_id__in')} />}
              {productString.dept_id__in && <Chip sx={{ ...chipStyle, fontSize: '11px' }} label={`Departments: ${productString.dept_id__in.split(',').length}`}
                deleteIcon={<CloseIcon style={{ ...fontColor, fontSize: '18px' }} />} onDelete={() => handleRemoveFilter('dept_id__in')} />}
              {productString.price && <Chip sx={{ ...chipStyle, fontSize: '11px', textTransform: 'capitalize' }} label={productString.price}
                deleteIcon={<CloseIcon style={{ ...fontColor, fontSize: '18px' }} />} onDelete={() => handleRemoveFilter('price')} />}
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', }} >
              <Button size='small' variant='outlined' sx={{
                ...fontColor, textTransform: 'none', fontSize: '11px',
                height: '32px', borderColor: '#d0d5dd', '&:hover': { borderColor: '#d0d5dd' }
              }}
              > <img src={'assets/excel-icon.png'} alt='excel icon'
                style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> 
                Download XLS
              </Button>
              <Button size='small' variant='outlined' startIcon={<FilterListIcon style={{ fontSize: '17' }} />} pr={1} sx={{
                ...fontColor, textTransform: 'capitalize', height: '32px', borderColor: '#d0d5dd', fontSize: '11px',
                '&:hover': { borderColor: '#d0d5dd' }
              }} onClick={() => openDialog('applyFilter')} >Filters</Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} sx={{pl: '10px'}} >
            <Grid item xs={9} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }} >
              <TextField size='small' placeholder='Search' sx={{
                '& input': {...fontColor, fontSize: '14px'},
                border: '1px solid #d0d5dd',
                borderRadius: '5px', '& input::placeholder': fontColor,
                height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
              }}
                variant='outlined' InputProps={{
                  startAdornment: (<InputAdornment position='start'><SearchIcon sx={fontColor} />
                  </InputAdornment>)
                }}
                onKeyUp={(e) => { if (e.key === 'Enter') filterSearch() }}
                value={searchInput} onChange={handleChangeSearch} />
              <Button onClick={filterSearch} variant='contained'
                sx={{ backgroundColor: '#0086c9', borderRadius: '5px', textTransform: 'none', height: '33px' }}
              >Search</Button>
              <Button onClick={handleReset} variant='contained'
                sx={{ backgroundColor: '#0086c9', borderRadius: '5px', textTransform: 'none', height: '33px' }}
              >Reset All</Button>
              {qa_user && selProducts.length > 0 ? <Button size='small' variant='contained' sx={{ textTransform: 'none', backgroundColor: '#0086c9' }} onClick={() => openApproveDialog('approveSelected')}>
                <CheckCircleIcon style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> Approve selected
              </Button> :
                qa_user && <Button size='small' variant='contained' sx={{ textTransform: 'none', backgroundColor: '#0086c9' }} onClick={() => openApproveDialog('approveAll')}>
                  <ChecklistIcon style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> Approve all
                </Button>}
              {selProducts.length > 0 && !qa_user &&
                <><Button size='small' variant='outlined' sx={{
                  ...fontColor, textTransform: 'none', borderColor: '#d0d5dd',
                  '&:hover': { borderColor: '#d0d5dd' }
                }} onClick={(e) => handlePopupOpen('openGroupPopup', e)}>
                  <DriveFileMoveIcon style={{ marginRight: '8px', height: '20px', objectFit: 'contain' }} /> Groups
                </Button>
                  <Popover
                    sx={{
                      '& .MuiPaper-root': {
                        border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                        borderRadius: 1, boxShadow: 'none'
                      }, mt: '15px', ml: '-50px'
                    }}
                    id={groupId} open={groupPopup} anchorEl={popUp.openGroupPopup}
                    onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Grid sx={popupStyle}>
                      {grpActnMsg ? <Typography sx={{ fontSize: '11px', m: 1 }}>{grpActnMsg}</Typography> : <>
                        <Typography sx={{ fontSize: '11px', px: 1 }}>Add to: </Typography>
                        {groups.data?.filter(grp => !productString.group_id__in.includes(grp.group_id)).map(item =>
                          <Typography key={item.group_id} sx={{ ...prdActnStyle, px: 1, py: 1, fontSize: '13px', textTransform: 'capitalize' }}
                            onClick={() => addProductsToGroup(item.group_id)}>{item.group_name}</Typography>)}
                        {productString.group_id__in && !productString.group_id__in.includes(',') && <>
                          <Divider color='gray' sx={{ my: 1 }} />
                          <Typography sx={{ fontSize: '11px', px: 1 }}>Remove from</Typography>
                          <Typography sx={{ ...prdActnStyle, px: 1, py: 1, fontSize: '13px' }}
                            onClick={() => removeProductsFromGroup(productString.group_id__in)}>
                            {groups.data?.find(grp => Number(productString.group_id__in) === grp.group_id)?.group_name}</Typography>
                        </>}
                      </>}
                    </Grid>
                  </Popover>
                </>}
              {selProducts.length > 0 && <Grid sx={{ display: 'flex', alignItems: 'center',}}>
                <Typography sx={{ ...fontColor, fontSize: '13px' }} >
                  {`${selProducts.length} product${selProducts.length > 1 ? 's' : ''} selected`}
                </Typography></Grid>}
                {
                  (UI_design === "UI_2") && 
                  <FormControl sx={{ m: 0, width: '8em' }}>
                    <Select sx={{
                      padding: "0px", fontSize:"12px",
                      ...fontColor, border: '1px solid #ced4da',
                      '& .MuiSelect-icon': { fill: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
                      }} size='small' variant='outlined' labelId="demo-select-small-label" id="demo-select-small" 
                      onChange={handleMarketPlaceChange} value={marketPlace}
                      MenuProps={{PaperProps: {sx: selectStyle}}}>
                        {marketPlaces.data && marketPlaces.data.map((item, index) => {
                        const { market_place_id, market_place_name } = item;
                        return <MenuItem sx={{fontSize:"11px"}} key={`market${index}`} value={market_place_id}>{market_place_name}</MenuItem>
                        })
                        }
                    </Select>
                  </FormControl>
                }
                {
                  (UI_design === "UI_2") && 
                  <Button variant='contained' disabled={!showRefresAllBtn}
                  onClick={handleRefreshAll}
                  sx={{ backgroundColor: '#0086c9', borderRadius: '5px', textTransform: 'none', height: '33px' }}>Refresh All</Button>
                }
            </Grid>
            <Grid xs={3} item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '5px', pr: '3px' }} >
              <ResultsPerPageControl />
              <div>
                <IconButton size='small' onClick={handlePreviousPage} disabled={currentPage === 1} ><ArrowBackIosNewIcon style={{fontSize: '15px', 
                color: currentPage === 1 ? (themeMode === 'dark' ? '#A9A9A9' : '#D3D3D3') : (themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A') }} /> </IconButton>
                <IconButton size='small' onClick={handleNextPage} disabled={currentPage === totalPages} ><ArrowForwardIosIcon style={{fontSize: '15px', 
                color: currentPage === totalPages ? (themeMode === 'dark' ? '#A9A9A9' : '#D3D3D3') : (themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A') }} /> </IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid sx={{ width: '100%', overflow: 'hidden', mt: 1 }}>
            {(products.loading || marketPlaces.loading) ? <Loading height='50vh' /> :
              products.error ? <Box sx={errorBox}><Typography sx={{fontSize: '13px'}} >Failed to fetch products</Typography></Box> :
                marketPlaces.error ? <Box sx={errorBox}><Typography sx={{fontSize: '13px'}} >Failed to fetch market places</Typography></Box> :
                  products.data?.message ? <Box sx={errorBox}><Typography sx={{fontSize: '13px'}} >{products.data.message}</Typography></Box> :
                    <Paper>
                      <TableVirtuoso
                        style={{ height: '75vh', backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff' }}
                        className={`custom-scrollbar-${themeMode}`}
                        data={sortedData}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                          <TableRow>
                            <TableCell style={tableHeader}>
                              <Checkbox id='selectallcheckbox' 
                              sx={{...fontColor,'& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                              },}} size='small' checked={selProducts.length === products.data?.results?.length}
                                onChange={handleSelectAll} /></TableCell>
                            <TableCell style={tableHeader} >
                              <Box className='sort-header' onClick={() => handleSort('productName')} >
                                <Typography style={{ fontSize: 'small', color: sortBy === 'productName' ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Product Name</Typography>
                                {renderSortArrow('productName') ? renderSortArrow('productName') : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                              </Box>
                            </TableCell>
                            <TableCell style={tableHeader}>
                              <Box className='sort-header' onClick={() => handleSort('yourPrice')} >
                                <Typography style={{ fontSize: 'small', color: sortBy === 'yourPrice' ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Your Price</Typography>
                                {renderSortArrow('yourPrice') ? renderSortArrow('yourPrice') : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                              </Box>
                            </TableCell>
                                {
                                  UI_design === "UI_2" ? 
                                  marketPlaces.data?.map((mkt) => {
                                    if (mkt.market_place_id === prd_remarks_value) {
                                        return (
                                            <TableCell key={mkt.market_place_id} sx={{ textAlign: 'center', ...tableHeader }}>
                                                <Box className='sort-header' onClick={() => handleSort(mkt.market_place_id)} >
                                                    <Typography style={{ fontSize: 'small', color: sortBy === mkt.market_place_id ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{mkt.market_place_name}</Typography>
                                                    {renderSortArrow(mkt.market_place_id) ? renderSortArrow(mkt.market_place_id) : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                                                </Box>
                                            </TableCell>
                                        );
                                    }
                                    return null;
                                })
                                  :
                                    marketPlaces.data?.map((mkt) => (
                                      <TableCell key={mkt.market_place_id} sx={{ textAlign: 'center', ...tableHeader }}>
                                        <Box className='sort-header' onClick={() => handleSort(mkt.market_place_id)} >
                                          <Typography style={{ fontSize: 'small', color: sortBy === mkt.market_place_id ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>{mkt.market_place_name}</Typography>
                                          {renderSortArrow(mkt.market_place_id) ? renderSortArrow(mkt.market_place_id) : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                                        </Box></TableCell>))
                                }
                                {UI_design === "UI_2" && (
                                  <TableCell sx={{ textAlign: 'center', ...tableHeader }}>
                                    <Box className='sort-header' onClick={() => handleSort('buyBox')}>
                                      <Typography style={{ fontSize: 'small', color: sortBy === 'buyBox' ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Buy Box</Typography>
                                      {renderSortArrow('buyBox') ? renderSortArrow('buyBox') : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                                    </Box>
                                  </TableCell>
                                )}
                                {UI_design === "UI_2" && (
                                  <TableCell sx={{ textAlign: 'center', ...tableHeader }}>
                                    <Box className='sort-header' onClick={() => handleSort('lastUpdated')}>
                                      <Typography style={{ fontSize: 'small', color: sortBy === 'lastUpdated' ? '#0094ff' : themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Last Updated</Typography>
                                      {renderSortArrow('lastUpdated') ? renderSortArrow('lastUpdated') : <ArrowUpwardIcon style={{ color: themeMode === 'light' ? '#FDF8E5' : '#3F526B', fontSize: '20px' }} />}
                                    </Box>
                                  </TableCell>
                                )}

                            <TableCell sx={{ textAlign: 'center', ...tableHeader }}>Actions </TableCell>
                          </TableRow>
                        )}
                        itemContent={(index, prd) => (
                          <>
                            <TableCell sx={{ padding: '5px 6px 5px 16px', width: '2vw' }}>
                              <Checkbox id={`select_${prd.prd_id}`} 
                              sx={{...fontColor,'& .MuiSvgIcon-root': { 
                                fontSize: '18px'
                              },}} size='small' checked={selProducts.includes(prd.prd_id)} onChange={() => handleCheckedPrds(prd.prd_id)} />
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top', cursor: UI_design !== "UI_2" ? 'pointer' : 'auto', padding: '5px 6px 5px 6px', width: '48vw' }}>
                              <Grid item xs={12} sx={fontColor} container onClick={() => goToDetailsPage(prd)}>
                                <Grid item xs={2} sx={{ mr: 1 }} >
                                  <img src={prd.prd_medium_image ? prd.prd_medium_image : '/assets/no_image.png'} style={{ width: 100, height: 70, objectFit: 'contain' }} onError={handleImageError} alt={prd.prd_name} />
                                </Grid>
                                <Grid item xs={9}>
                                  <Typography sx={{ fontSize: '13px', textTransform: 'capitalize' }} >{prd.prd_name}</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', mt: 1 }} >
                                    <Rating name='read-only' sx={{ '& .MuiRating-iconEmpty': fontColor }} precision={0.5} size='small' value={prd.prd_rating} readOnly />
                                    <Typography sx={{ fontSize: '11px', ml: '5px' }} >{prd.prd_tot_reviews}</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', gap: '5px', mt: 1 }} >
                                    <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }} ><span style={{ fontStyle: 'italic' }} >Brand:</span> {prd.prd_brand ? prd.prd_brand : '--'}</Typography>
                                    <Divider orientation='vertical' flexItem color='gray' />
                                    <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }} ><span style={{ fontStyle: 'italic' }} >Category:</span> {prd.category?.category_name ? prd.category.category_name : '--'}</Typography>
                                    {prd.prd_url && <><Divider orientation='vertical' flexItem color='gray' />
                                      <Typography onClick={(e) => goToProductPage(e, prd.prd_url)}
                                        sx={{ fontSize: '12px', color: '#0094ff', textDecoration: 'underline', cursor: 'pointer' }}
                                      >Visit Website</Typography></>}
                                    {qa_user && prd.prd_qa_approved ? <VerifiedIcon sx={{ color: themeMode === 'light' ? 'green' : '#1ac93a', fontSize: '20px', pl: 1 }} /> :
                                      qa_user && <NewReleasesIcon sx={{ color: 'red', fontSize: '20px', pl: 1 }} />}
                                  </Box>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top', padding: '5px 6px 5px 16px' }}>
                              <Grid item xs={12} sx={{ ...fontColor, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                {prd.locations[0] ? <Chip sx={{ color: '#1976d2', backgroundColor: '#E4F6FF', borderRadius: 1 }}
                                  variant='contained' label={`${currSymb}${prd.locations[0].prd_sales_price?.toFixed(2)}`} /> : '-'}
                                {prd.locations[0] ? <Typography sx={{ ...fontColor, fontSize: '11px', pt: '4px' }} >
                                  {currSymb}{(prd.locations[0].prd_norm_price)?.toFixed(2)} / {prd.prd_uom}</Typography> : '-'}
                              </Grid>
                            </TableCell>
                            {
                              UI_design === "UI_2" ? 
                              marketPlaces.data?.filter(item => item.market_place_id === prd_remarks_value ).map(mkt => {
                                const marketCompData = prd.competitors.filter(comp => comp.market_places?.market_place_id === mkt.market_place_id);
                                const maxScore = Math.max(...marketCompData.map((competitor) => competitor.comp_prd_score));
                                const highScoreComp = marketCompData.filter((competitor) => competitor.comp_prd_score === maxScore);
                                const sortedHighScoreComp = highScoreComp.sort((a, b) => a.comp_prd_sales_price - b.comp_prd_sales_price);
                                return (
                                  <TableCell style={{ verticalAlign: 'top', whiteSpace: 'nowrap', padding: '5px 6px 5px 16px' }} key={mkt.market_place_id}>
                                    <Grid item xs={12} container sx={{ ...fontColor, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0px' }} >
                                      {sortedHighScoreComp.length > 0 ? <Chip sx={{
                                        borderRadius: 1, color:
                                          sortedHighScoreComp[0].comp_prd_sales_price > prd.locations[0]?.prd_sales_price ? '#0c9c59' :
                                            sortedHighScoreComp[0].comp_prd_sales_price < prd.locations[0]?.prd_sales_price ? '#c11515' : '#fc8800',
                                        backgroundColor:
                                          sortedHighScoreComp[0].comp_prd_sales_price > prd.locations[0]?.prd_sales_price ? '#e6fef0' :
                                            sortedHighScoreComp[0].comp_prd_sales_price < prd.locations[0]?.prd_sales_price ? '#fdf2f2' : '#fff1e0',
                                        cursor: sortedHighScoreComp?.length > 0 && 'pointer'
                                      }}
                                        onClick={() => handleOpenCompDialog(prd, mkt.market_place_id)}
                                        variant='contained' label={`${currSymb}${sortedHighScoreComp[0].comp_prd_sales_price?.toFixed(2)}`} /> : '--'}
                                      {sortedHighScoreComp.length > 0 && <Typography sx={{ ...fontColor, fontSize: '11px', pt: '4px' }} >
                                        {currSymb}{Number(sortedHighScoreComp[0]?.comp_prd_norm_price)?.toFixed(2)} / {sortedHighScoreComp[0]?.comp_prd_norm_price_uom}</Typography>}
                                      <div style={{ minHeight: '3px' }}>
                                        {sortedHighScoreComp.length > 1 &&
                                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', paddingTop: '0px', whiteSpace: 'nowrap' }}>
                                            <Typography sx={{ fontSize: '11px', }}>
                                              {currSymb}{sortedHighScoreComp[0].comp_prd_sales_price} - {currSymb} {sortedHighScoreComp[sortedHighScoreComp.length - 1].comp_prd_sales_price}</Typography>
                                            <Divider orientation='vertical' sx={{ height: '15px' }} />
                                            <StoreOutlinedIcon sx={{ fontSize: 15 }} />
                                            <Typography sx={{ fontSize: '11px', ml: '4px' }}>{sortedHighScoreComp.length}</Typography>
                                          </Box>}
                                      </div>
                                      {sortedHighScoreComp.length > 0 && <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'none' }}>
                                        <RefreshOutlinedIcon sx={{ fontSize: '11px', mr: '5px' }} />
                                        <Box sx={{ textAlign: 'center' }} >
                                          <Typography sx={{ fontSize: '8px' }}>{moment(sortedHighScoreComp[0].modified_date).format('DD/MM/YYYY')} {moment(sortedHighScoreComp[0].modified_date).format('h:mm A')}</Typography>
                                        </Box>
                                      </div>}
                                    </Grid>
                                  </TableCell>
                                );
                              }
                              )
                              : 
                              marketPlaces.data?.map(mkt => {
                                const marketCompData = prd.competitors.filter(comp => comp.market_places?.market_place_id === mkt.market_place_id);
                                const maxScore = Math.max(...marketCompData.map((competitor) => competitor.comp_prd_score));
                                const highScoreComp = marketCompData.filter((competitor) => competitor.comp_prd_score === maxScore);
                                const sortedHighScoreComp = highScoreComp.sort((a, b) => a.comp_prd_sales_price - b.comp_prd_sales_price);
                                return (
                                  <TableCell style={{ verticalAlign: 'top', whiteSpace: 'nowrap', padding: '5px 6px 5px 16px' }} key={mkt.market_place_id}>
                                    <Grid item xs={12} container sx={{ ...fontColor, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0px' }} >
                                      {sortedHighScoreComp.length > 0 ? <Chip sx={{
                                        borderRadius: 1, color:
                                          sortedHighScoreComp[0].comp_prd_sales_price > prd.locations[0]?.prd_sales_price ? '#0c9c59' :
                                            sortedHighScoreComp[0].comp_prd_sales_price < prd.locations[0]?.prd_sales_price ? '#c11515' : '#fc8800',
                                        backgroundColor:
                                          sortedHighScoreComp[0].comp_prd_sales_price > prd.locations[0]?.prd_sales_price ? '#e6fef0' :
                                            sortedHighScoreComp[0].comp_prd_sales_price < prd.locations[0]?.prd_sales_price ? '#fdf2f2' : '#fff1e0',
                                        cursor: sortedHighScoreComp?.length > 0 && 'pointer'
                                      }}
                                        onClick={() => handleOpenCompDialog(prd, mkt.market_place_id)}
                                        variant='contained' label={`${currSymb}${sortedHighScoreComp[0].comp_prd_sales_price?.toFixed(2)}`} /> : '--'}
                                      {sortedHighScoreComp.length > 0 && <Typography sx={{ ...fontColor, fontSize: '11px', pt: '4px' }} >
                                        {currSymb}{Number(sortedHighScoreComp[0]?.comp_prd_norm_price)?.toFixed(2)} / {sortedHighScoreComp[0]?.comp_prd_norm_price_uom ? sortedHighScoreComp[0]?.comp_prd_norm_price_uom : sortedHighScoreComp[0]?.comp_prd_uom }</Typography>}
                                      <div style={{ minHeight: '3px' }}>
                                        {sortedHighScoreComp.length > 1 &&
                                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', paddingTop: '0px', whiteSpace: 'nowrap' }}>
                                            <Typography sx={{ fontSize: '11px', }}>
                                              {currSymb}{sortedHighScoreComp[0].comp_prd_sales_price} - {currSymb} {sortedHighScoreComp[sortedHighScoreComp.length - 1].comp_prd_sales_price}</Typography>
                                            <Divider orientation='vertical' sx={{ height: '15px' }} />
                                            <StoreOutlinedIcon sx={{ fontSize: 15 }} />
                                            <Typography sx={{ fontSize: '11px', ml: '4px' }}>{sortedHighScoreComp.length}</Typography>
                                          </Box>}
                                      </div>
                                      {sortedHighScoreComp.length > 0 && <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'none' }}>
                                        <RefreshOutlinedIcon sx={{ fontSize: '11px', mr: '5px' }} />
                                        <Box sx={{ textAlign: 'center' }} >
                                          <Typography sx={{ fontSize: '8px' }}>{moment(sortedHighScoreComp[0].modified_date).format('DD/MM/YYYY')} {moment(sortedHighScoreComp[0].modified_date).format('h:mm A')}</Typography>
                                        </Box>
                                      </div>}
                                    </Grid>
                                  </TableCell>
                                );
                              })
                            }
                            {
                              UI_design === "UI_2" && 
                            <TableCell>
                              <Typography sx={{...fontColor, textAlign: 'center', fontSize: '11px'}} >{prd?.competitors?.[0]?.seller?.seller_name ? prd?.competitors?.[0]?.seller?.seller_name?.replace(/[\p{Emoji_Presentation}\p{Emoji}]/gu, '')?.trim() : 
                              prd?.locations?.[0]?.prd_sales_price !== 0 ? prd?.locations?.[0]?.seller?.seller_name?.replace(/[\p{Emoji_Presentation}\p{Emoji}]/gu, '')?.trim() : '--'
                              }</Typography>
                            </TableCell>
                            }
                            {
                              UI_design === "UI_2" && 
                            <TableCell>
                              <Typography sx={{...fontColor, textAlign: 'center', fontSize: '11px'}} >
                                {prd?.modified_date ? moment(prd?.modified_date).format('MM DD YYYY') : "--"}
                              </Typography>
                              <Typography sx={{...fontColor, textAlign: 'center', fontSize: '11px'}} >
                                {prd?.modified_date ? moment(prd?.modified_date).format('h:mm:ss A') : "--"}
                              </Typography>
                            </TableCell>
                            }
                            <TableCell sx={{ p: '5px 6px 5px 16px', width: '2vw' }}>
                              <div style={{display:"flex", flexDirection:"row"}}>
                              <IconButton onClick={(e) => handlePopupOpen('openActionPopup', e, prd)}>
                                <MoreVertIcon sx={{ ...fontColor, cursor: 'pointer', fontSize: '20px' }} />
                              </IconButton>
                              {
                                UI_design === "UI_2" && 
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <IconButton onClick={() => handleCopy(prd.prd_name, prd.prd_id)}>
                                    <ContentCopyIcon sx={{ ...fontColor, cursor: 'pointer', fontSize: '18px' }} />
                                  </IconButton>
                                  {copiedProduct === prd?.prd_id && (
                                    <Typography
                                      sx={{ position: 'absolute', bottom: '30px', left: '50%', transform: 'translateX(-50%)',
                                        backgroundColor: themeMode === 'dark' ? '#fff' : '#232D3B', borderRadius: '4px',
                                        color: themeMode === 'light' ? '#E4E3E3' : '#5A5A5A', padding: '2px 6px', fontSize: '11px'
                                      }}>Copied!</Typography>
                                  )}
                                </div>
                              }
                              {
                                UI_design === "UI_2" && 
                              <IconButton onClick={()=>openRefreshDialog('refresh', prd)} >
                                <RefreshIcon sx={{ ...fontColor, cursor: 'pointer', fontSize: '18px' }} />
                              </IconButton>
                              }
                              </div>
                              <Box>
                                <Popover
                                  id={actionId} open={actionPopup} anchorEl={popUp.openActionPopup}
                                  onClose={handlePopupClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                  sx={{
                                    mt: '15px', ml: '-50px', '& .MuiPopover-paper': {
                                      boxShadow: 'none', width: '230px',
                                      border: themeMode === 'dark' ? '1px solid #E4E3E3' : '1px solid #5A5A5A',
                                      backgroundColor: themeMode === 'light' ? '#fff' : '#232D3B',
                                      color: themeMode === 'light' ? '#5A5A5A' : '#E4E3E3'
                                    }
                                  }}>
                                  <Grid item xs={12} container >
                                    <Grid item container sx={{...prdActnStyle, padding: '5px', display: 'flex', alignItems: 'center'}}
                                      onClick={() => {
                                        openActionDialog('editProduct');
                                        handlePopupClose();
                                      }} >
                                      <Grid item xs={2} ><EditOutlinedIcon sx={{ ml: 1, fontSize: 20 }} /></Grid>
                                      <Grid item xs={10} ><Typography sx={{ fontSize: '11px' }} >Edit</Typography> </Grid>
                                    </Grid>
                                    {!qa_user && <Grid item container sx={{...prdActnStyle, padding: '5px', display: 'flex', alignItems: 'center'}}
                                      onClick={() => {
                                        openActionDialog('deleteSingleProduct');
                                        handlePopupClose();
                                      }} >
                                      <Grid item xs={2} ><DeleteOutlineOutlinedIcon sx={{ ml: 1, fontSize: 20 }} /> </Grid>
                                      <Grid item xs={10} ><Typography sx={{ fontSize: '11px' }} >Delete</Typography> </Grid>
                                    </Grid>}
                                    <Grid item container sx={{ display: 'flex', alignItems: 'center', padding: '5px' }} >
                                      <Grid item xs={2} ><NotificationsNoneOutlinedIcon sx={{ ml: 1, fontSize: 20 }} /> </Grid>
                                      <Grid item xs={7} ><Typography sx={{ fontSize: '11px' }} >Notifications</Typography> </Grid>
                                      <Grid item xs={3} ><Switch disabled={qa_user} id={`notifications_${prd.prd_id}`} size='small' sx={{ fontSize: '12px', transform: 'scale(0.9)', }} /> </Grid>
                                    </Grid>
                                    <Grid item container sx={{ display: 'flex', alignItems: 'center', padding: '5px' }} >
                                      <Grid item xs={2} ><VpnKeyOutlinedIcon sx={{ ml: 1, fontSize: 20 }} /> </Grid>
                                      <Grid item xs={7} ><Typography sx={{ fontSize: '11px' }} >Key value</Typography> </Grid>
                                      <Grid item xs={3} >
                                        <Switch id={`key_value_${prd.prd_id}`}
                                          size='small' sx={{ fontSize: '12px', transform: 'scale(0.9)' }} disabled={qa_user} checked={actionRow ? actionRow.prd_is_key_value : false} onChange={handleKeyValue} />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Popover>
                              </Box>
                            </TableCell>
                          </>
                        )}
                      />
                    </Paper>}
            <Grid item xs={12} container p={2} style={{ borderTop: '0.5px solid rgba(224, 224, 224, 1)' }} >
              <Grid item xs={4} sx={{ display: 'flex', gap: '5px', justifyContent: 'flex-start', alignItems: 'center' }} >
                <ResultsPerPageControl />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }} >
                <Pagination count={totalPages || 0}
                  shape='rounded'
                  hideNextButton hidePrevButton
                  onChange={handlePageChange}
                  page={currentPage}
                  sx={{
                    '& .MuiPaginationItem-root': fontColor,
                    '& .MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#e7f7ff',
                      color: '#0086c9',
                      fontWeight: '600'
                    },
                    '& .MuiPaginationItem-root:hover': {
                      color: '#0086c9',
                      backgroundColor: '#e7f7ff',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} >
                <Button variant='outlined' sx={pageChangeStyle} size='small'
                  onClick={handlePreviousPage} disabled={currentPage === 1}
                >Previous</Button>
                <Button variant='outlined' sx={pageChangeStyle} size='small'
                  onClick={handleNextPage} disabled={currentPage === totalPages}
                >Next</Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Footer />
    </>
  )
}

export default MyProducts;