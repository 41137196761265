import axios from "axios";
import { paths } from "../../Api/paths";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";
import { REFRESH_BTN,REFRESH_BTN_FAILED,REFRESH_BTN_SUCCESSFULL,RESET_REFRESH_BTN } from "../constants/refreshBtnStatus.constants";

const refreshBtnStatus = () => ({
  type: REFRESH_BTN,
});

const refreshBtnSuccess = (data) => ({
  type: REFRESH_BTN_SUCCESSFULL,
  payload: data,
});

const refreshBtnFailure = (error) => ({
  type: REFRESH_BTN_FAILED,
  payload: error,
});

export const resetRefreshBtnStatus = () => ({
  type: RESET_REFRESH_BTN,
});

export const getRefreshBtnStatus = (token) => async (dispatch) => {
  try {
    dispatch(refreshBtnStatus());
    const response = await axios.post(
      `${process.env.REACT_APP_API_LINK}${paths.refreshButton}`,
      {},
      { headers: { Authorization: `bearer ${token}` } }
    );
    if (response.data?.Message) {
      dispatch(refreshBtnFailure(response.data.Message));
    } else {
      dispatch(refreshBtnSuccess(response.data));
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(handleTokenExpiration());
    } else {
    dispatch(refreshBtnFailure(error));
    }
  }
};
