import {
  EDIT_GROUP_SETTINGS,
  EDIT_GROUP_SETTINGS_FAILED,
  EDIT_GROUP_SETTINGS_SUCCESSFULL,
  RESET_EDIT_GROUP_SETTINGS,
} from "../constants/editGroupSettings.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const editGroupSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_GROUP_SETTINGS:
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };

    case EDIT_GROUP_SETTINGS_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };

    case EDIT_GROUP_SETTINGS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: action.payload,
      };

    case RESET_EDIT_GROUP_SETTINGS:
      return {
        ...initialState,
      };

      case RESET_ALL_DATA:
                return{
                    ...initialState
                }

    default:
      return state;
  }
};

export default editGroupSettingsReducer;
