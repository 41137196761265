import axios from "axios";
import { paths } from "../../Api/paths";
import { ADD_COMPETITOR, ADD_COMPETITOR_SUCCESSFULL, ADD_COMPETITOR_FAILED, RESET_ADD_COMPETITOR } from "../constants/addCompetitor.constants";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const addCompetitor = () => ({
  type: ADD_COMPETITOR
});

const addCompetitorSuccess = (data) => ({
  type: ADD_COMPETITOR_SUCCESSFULL,
  payload: data,
});

const addCompetitorFailure = (error) => ({
  type: ADD_COMPETITOR_FAILED,
  payload: error,
});

export const resetAddCompetitor = () => ({
  type: RESET_ADD_COMPETITOR,
});

export const addNewCompetitor = (token, data, qa_user, company_id) => async (dispatch) => {
  try {
    dispatch(addCompetitor());
    const endpoint = qa_user ? `${paths.qaCompetitors}?company_id=${company_id}` : paths.competitors;
    const response = await axios.post(
      `${process.env.REACT_APP_API_LINK}${endpoint}`, data,
      { headers: { Authorization: `bearer ${token}` } }
    );
    dispatch(addCompetitorSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(handleTokenExpiration());
    } else {
    dispatch(addCompetitorFailure(error));
    }
  }
};
